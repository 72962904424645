"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkArray = void 0;
function chunkArray(list, chunkSize) {
    var result = [[]];
    var i = 0;
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var item = list_1[_i];
        if (result[i].length === chunkSize) {
            i++;
            result.push([]);
        }
        result[i].push(item);
    }
    return result;
}
exports.chunkArray = chunkArray;
