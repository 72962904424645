"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultPlatform = exports.shortcuts = exports.platform = exports.initializePlatform = void 0;
var ipcSendApiDefinition = {
    'centered:ipc': function (_event) { },
    'discord:clearActivity': function () { },
    'discord:setActivity': function (_activity) { },
    'notification:machine:event': function (_event) { },
    'playerWindow:downloadFile': function (_url) { },
};
var ipcInvokeApiDefinition = {
    'breathe:show': function () { },
    'discord:login': function () { },
    'dnd:query': function () { return true; },
    'dnd:toggle': function (_enabled) { },
    'globalState:getState': function (_key) { return ({}); },
    'globalState:setState': function (_key, _value) { },
    'main:getAppVersion': function () { return 'string'; },
    'launchOnStartup:toggle': function (_enabled) { },
    'main:getSharableItems': function () { return ({}); },
    'main:hasNewMessages': function (_unreadCount) { },
    'main:maximize': function () { },
    'main:shareImage': function (_image) { },
    'main:userLoggedOut': function () { },
    'main:checkForUpdates': function (_options) { },
    'monitor:checkAccess': function (_askForPrompt) { return true; },
    'monitor:checkScreenAccess': function (_askForPrompt) { return true; },
    'monitor:getMacBrowserUrl': function (_bundleIdOrUrl) { return 'string'; },
    'monitor:fetchApplication': function () {
        return {};
    },
    'monitor:fetchIcon': function (_url) { return 'string'; },
    'monitor:getIdle': function () { return 0; },
    'monitor:startMacMonitor': function () { },
    'monitor:stop': function () { },
    'settings:getState': function (_key) { return ({}); },
    'settings:setState': function (_key, _value) { },
    signOut: function () { },
    'togglePlayer:enabled': function (_enabled) { },
    'togglePlayer:shortcut': function (_shortcut) { },
    'window:moving': function (_event) { },
    'window:moved': function () { },
};
var lazyPlatform;
var initializePlatform = function (platform) {
    lazyPlatform = platform;
};
exports.initializePlatform = initializePlatform;
exports.platform = {
    get getOperatingSystem() {
        if (!lazyPlatform)
            throw 'Platform not initialize (getOperatingSystem)';
        return lazyPlatform.getOperatingSystem;
    },
    get getElectronState() {
        if (!lazyPlatform)
            throw 'Platform not initialized (getElectronState)';
        return lazyPlatform.getElectronState;
    },
    get getShortcuts() {
        if (!lazyPlatform)
            throw 'Platform not initialized (getShortcuts)';
        return lazyPlatform.getShortcuts;
    },
    get ipcInvoke() {
        if (!lazyPlatform)
            throw 'Platform not initialized (ipcInvoke)';
        return lazyPlatform.ipcInvoke;
    },
    get ipcSend() {
        if (!lazyPlatform)
            throw 'Platform not initialized (ipcSend)';
        return lazyPlatform.ipcSend;
    },
    get isDnd() {
        if (!lazyPlatform)
            throw 'Platform not initialized (isDnd)';
        return lazyPlatform.isDnd;
    },
    get isDndSupported() {
        if (!lazyPlatform)
            throw 'Platform not initialized (isDndSupported)';
        return lazyPlatform.isDndSupported;
    },
    get listenToElectronState() {
        if (!lazyPlatform)
            throw 'Platform not initialized (listenToElectronState)';
        return lazyPlatform.listenToElectronState;
    },
    get listenToIpc() {
        if (!lazyPlatform)
            throw 'Platform not initialized (listenToIpc)';
        return lazyPlatform.listenToIpc;
    },
    get onTogglePlayer() {
        if (!lazyPlatform)
            throw 'Platform not initialized (onTogglePlayer)';
        return lazyPlatform.onTogglePlayer;
    },
    get openShortcut() {
        if (!lazyPlatform)
            throw 'Platform not initialized (openShortcut)';
        return lazyPlatform.openShortcut;
    },
    get openUrl() {
        if (!lazyPlatform)
            throw 'Platform not initialized (openUrl)';
        return lazyPlatform.openUrl;
    },
    get setDnd() {
        if (!lazyPlatform)
            throw 'Platform not initialized (setDnd)';
        return lazyPlatform.setDnd;
    },
    get setElectronState() {
        if (!lazyPlatform)
            throw 'Platform not initialized (setElectronState)';
        return lazyPlatform.setElectronState;
    },
    get type() {
        if (!lazyPlatform)
            throw 'Platform not initialized (type)';
        return lazyPlatform.type;
    },
    get getImageUrl() {
        if (!lazyPlatform)
            throw 'Platform not initialized (getImageUrl)';
        return lazyPlatform.getImageUrl;
    },
    get showGroupContextMenu() {
        if (!lazyPlatform)
            throw 'Platform not initialized (showGroupContextMenu)';
        return lazyPlatform.showGroupContextMenu;
    },
    get showMiniplayerMenu() {
        if (!lazyPlatform)
            throw 'Platform not initialized (showMiniplayerMenu)';
        return lazyPlatform.showMiniplayerMenu;
    },
    get setStateForChannel() {
        if (!lazyPlatform)
            throw 'Platform not initialized (setStateForChannel)';
        return lazyPlatform.setStateForChannel;
    },
    get subscribeToKey() {
        if (!lazyPlatform)
            throw 'Platform not initialized (subscribeToKey)';
        return lazyPlatform.subscribeToKey;
    },
    get getStateForChannel() {
        if (!lazyPlatform)
            throw 'Platform not initialized (getStateForChannel)';
        return lazyPlatform.getStateForChannel;
    },
};
/**
 * These icloudIds are necessarily linked to Erik's iCloud account. If Erik
 * were to delete these shortcuts on his local system, they would be deleted
 * from iCloud. There seems to be no way around this.
 */
exports.shortcuts = {
    start: {
        name: 'Centered: Start Flowing',
        icloudId: 'c9706e63d7e54b1ba9bbb4eb20762b07',
    },
    stop: {
        name: 'Centered: Stop Flowing',
        icloudId: '50b90f47f79246f0a8f115db3e7b10c6',
    },
};
exports.defaultPlatform = {
    getOperatingSystem: function () {
        throw new Error('Not implemented');
    },
    getImageUrl: function (_image) {
        throw new Error('Not implemented');
    },
    getShortcuts: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Not implemented');
            });
        });
    },
    isDnd: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Not implemented');
            });
        });
    },
    isDndSupported: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Not implemented');
            });
        });
    },
    openShortcut: function () {
        throw new Error('Not implemented');
    },
    openUrl: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Not implemented');
            });
        });
    },
    setDnd: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Not implemented');
            });
        });
    },
    setElectronState: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Not implemented');
            });
        });
    },
    showMiniplayerMenu: function () {
        throw new Error('Not implemented');
    },
    showGroupContextMenu: function () {
        throw new Error('Not implemented');
    },
    subscribeToKey: function () {
        throw new Error('Not implemented');
    },
    getStateForChannel: function () {
        throw new Error('Not implemented');
    },
    listenToElectronState: function () {
        throw new Error('Not implemented');
    },
    listenToIpc: function () {
        throw new Error('Not implemented');
    },
    onTogglePlayer: function () {
        throw new Error('Not implemented');
    },
    ipcSend: function () {
        throw new Error('Not implemented');
    },
    ipcInvoke: function () {
        throw new Error('Not implemented');
    },
    getElectronState: function () {
        throw new Error('Not implemented');
    },
    setStateForChannel: function () {
        throw new Error('Not implemented');
    },
    type: 'electron',
};
