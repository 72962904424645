"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessionMachineEventSchema = void 0;
var zod_1 = require("zod");
exports.sessionMachineEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Break'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('End Session'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Flow'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Resume'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Suspend'),
    }),
]);
