"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchSupportedGroups = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var useFetchSupportedGroups = function (userId) {
    var _a = (0, react_1.useState)([]), groups = _a[0], setGroups = _a[1];
    var _b = (0, react_1.useState)(true), loading = _b[0], setLoading = _b[1];
    (0, react_1.useEffect)(function () {
        if (!userId)
            return;
        var unsubscribe = config_1.db
            .collection("private-users/".concat(userId, "/user-group-memberships"))
            .where('supporterSince', '!=', null)
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            setGroups(snapshot.docs.map(function (d) { return d.data(); }));
            setLoading(false);
        }, function (err) {
            console.error('useFetchSupportedGroups subscription error', err);
        });
        return function () {
            unsubscribe();
        };
    }, [userId]);
    return { groups: groups, loading: loading };
};
exports.useFetchSupportedGroups = useFetchSupportedGroups;
