"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchNotifications = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var FirebaseContext_1 = require("../FirebaseContext");
var manageNotifications_1 = require("../helpers/manageNotifications");
function useFetchNotifications(isOpen) {
    if (isOpen === void 0) { isOpen = false; }
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    var _a = (0, react_1.useState)([]), notifications = _a[0], setNotifications = _a[1];
    var _b = (0, react_1.useState)(true), notificationLoading = _b[0], setNotificationLoading = _b[1];
    var notificationQuery = (0, react_1.useRef)(null);
    var lastNotificationRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        if (!isOpen)
            return;
        var isMounted = true;
        setNotificationLoading(true);
        var AMOUNT_OF_NOTIFICATIONS_TO_LOAD = 10;
        notificationQuery.current = config_1.db
            .collection("/users/".concat(currentUser.id, "/notifications"))
            .orderBy('createdAt', 'desc')
            .withConverter((0, config_1.converter)())
            .limit(AMOUNT_OF_NOTIFICATIONS_TO_LOAD);
        notificationQuery.current
            .get()
            .then(function (snapshot) {
            if (isMounted) {
                lastNotificationRef.current = snapshot.docs[snapshot.docs.length - 1];
                var notifications_1 = snapshot.docs
                    .map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); })
                    .filter(function (t) {
                    return !(t.type === 'NEW_COMMENT' || t.type === 'NEW_REACTION');
                });
                setNotifications(notifications_1);
                setNotificationLoading(false);
                if (notifications_1.length < AMOUNT_OF_NOTIFICATIONS_TO_LOAD) {
                    setAllNotificationsLoaded(true);
                }
            }
        })
            .catch(function (err) {
            console.error('Error fetching notifications for user for the first time', err);
            setAllNotificationsLoaded(true);
        })
            .finally(function () {
            setNotificationLoading(false);
        });
        return function () {
            isMounted = false;
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, isOpen]);
    var _c = (0, react_1.useState)(false), allNotificationsLoaded = _c[0], setAllNotificationsLoaded = _c[1];
    var loadMoreNotifications = (0, react_1.useCallback)(function () {
        var _a;
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        if (allNotificationsLoaded)
            return;
        (_a = notificationQuery.current) === null || _a === void 0 ? void 0 : _a.startAfter(lastNotificationRef.current).get().then(function (snapshot) {
            var lastNotificationCursor = snapshot.docs[snapshot.docs.length - 1];
            if (snapshot.empty) {
                setAllNotificationsLoaded(true);
                return;
            }
            if (!lastNotificationCursor) {
                setAllNotificationsLoaded(true);
            }
            else {
                lastNotificationRef.current = lastNotificationCursor;
                setNotifications(function (curr) { return __spreadArray(__spreadArray([], curr, true), snapshot.docs
                    .map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); })
                    .filter(function (t) {
                    return !(t.type === 'NEW_COMMENT' || t.type === 'NEW_REACTION');
                }), true); });
            }
        }).catch(function (error) {
            console.error('Error fetching more notifications during pagination', error);
        });
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, allNotificationsLoaded]);
    var markAllNotificationsAsRead = (0, react_1.useCallback)(function () {
        (0, manageNotifications_1.markAllNotificationsAsViewed)();
        setNotifications(function (curr) {
            return curr.map(function (notification) { return (__assign(__assign({}, notification), { viewedAt: Date.now() })); });
        });
    }, []);
    var markNotifAsViewed = (0, react_1.useCallback)(function (notificationId) {
        (0, manageNotifications_1.markNotificationAsViewed)(notificationId);
        setNotifications(function (curr) {
            return curr.map(function (notif) {
                if (notif.id === notificationId) {
                    return __assign(__assign({}, notif), { viewedAt: Date.now() });
                }
                return notif;
            });
        });
    }, []);
    return {
        notifications: notifications,
        allNotificationsLoaded: allNotificationsLoaded,
        loadMoreNotifications: loadMoreNotifications,
        loading: notificationLoading,
        markNotificationAsViewed: markNotifAsViewed,
        markAllNotificationsAsRead: markAllNotificationsAsRead,
    };
}
exports.useFetchNotifications = useFetchNotifications;
