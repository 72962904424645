"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToTasks = exports.undeleteTask = exports.deleteTask = exports.updateTask = exports.updateAllTasks = exports.createTask = exports.createTaskAt = exports.firebaseTaskDocToTask = void 0;
var helpers_1 = require("@centered/helpers");
var lexorank_1 = require("lexorank");
var luxon_1 = require("luxon");
var uuid = require("uuid");
var config_1 = require("../config");
function firebaseTaskDocToTask(taskDoc) {
    return firebaseTaskToTask(__assign(__assign({}, taskDoc.data()), { 
        // Override an eventual ID in data with the _actual_ one
        id: taskDoc.id }));
}
exports.firebaseTaskDocToTask = firebaseTaskDocToTask;
// Duplicated in backend firebase functions' tasks.ts
function firebaseTaskToTask(firebaseTask) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var task = __assign(__assign({}, firebaseTask), { id: (_a = firebaseTask.id) !== null && _a !== void 0 ? _a : uuid.v4(), timeWorked: (_b = firebaseTask.timeWorked) !== null && _b !== void 0 ? _b : 0, state: (_c = firebaseTask.state) !== null && _c !== void 0 ? _c : 'new', lastWorkStartedAt: (_e = (_d = firebaseTask.lastWorkStartedAt) === null || _d === void 0 ? void 0 : _d.toDate()) !== null && _e !== void 0 ? _e : null, startAt: (_g = (_f = firebaseTask.startAt) === null || _f === void 0 ? void 0 : _f.toDate()) !== null && _g !== void 0 ? _g : null, completedAt: (_j = (_h = firebaseTask.completedAt) === null || _h === void 0 ? void 0 : _h.toDate()) !== null && _j !== void 0 ? _j : null, createdAt: (_l = (_k = firebaseTask.createdAt) === null || _k === void 0 ? void 0 : _k.toDate()) !== null && _l !== void 0 ? _l : new Date(), deletedAt: (_o = (_m = firebaseTask.deletedAt) === null || _m === void 0 ? void 0 : _m.toDate()) !== null && _o !== void 0 ? _o : null });
    if (firebaseTask.source === 'linear') {
        if (!firebaseTask.integrationId) {
            throw new Error('Linear task must have integrationId');
        }
        if (!firebaseTask.linear) {
            throw new Error('Linear task must have `linear` attribute');
        }
        var defaultBucketId = firebaseTask.integrationId;
        return __assign(__assign({}, task), { source: 'linear', integrationId: firebaseTask.integrationId, linear: firebaseTask.linear, bucketId: (_p = firebaseTask.bucketId) !== null && _p !== void 0 ? _p : defaultBucketId, isInIntegrationBucket: !firebaseTask.bucketId || firebaseTask.bucketId === defaultBucketId });
    }
    if (firebaseTask.source === 'todoist') {
        if (!firebaseTask.integrationId) {
            throw new Error('Todoist task must have integrationId');
        }
        if (!firebaseTask.todoist) {
            throw new Error('Todoist task must have `todoist` attribute');
        }
        var defaultBucketId = firebaseTask.integrationId;
        return __assign(__assign({}, task), { source: 'todoist', integrationId: firebaseTask.integrationId, todoist: firebaseTask.todoist, bucketId: (_q = firebaseTask.bucketId) !== null && _q !== void 0 ? _q : defaultBucketId, isInIntegrationBucket: !firebaseTask.bucketId || firebaseTask.bucketId === defaultBucketId });
    }
    if (firebaseTask.source === 'asana') {
        if (!firebaseTask.integrationId) {
            throw new Error('Asana task must have integrationId');
        }
        if (!firebaseTask.asana) {
            throw new Error('Asana task must have `asana` attribute');
        }
        var defaultBucketId = firebaseTask.integrationId;
        return __assign(__assign({}, task), { source: 'asana', integrationId: firebaseTask.integrationId, asana: firebaseTask.asana, bucketId: (_r = firebaseTask.bucketId) !== null && _r !== void 0 ? _r : defaultBucketId, isInIntegrationBucket: !firebaseTask.bucketId || firebaseTask.bucketId === defaultBucketId });
    }
    return __assign(__assign({}, task), { source: 'centered', bucketId: (_s = firebaseTask.bucketId) !== null && _s !== void 0 ? _s : 'my-tasks', isInIntegrationBucket: false });
}
function pathToTasks(userId) {
    return "users/".concat(userId, "/tasks");
}
var calculateRank = function (allTasks, position) {
    var defaultRank = lexorank_1.LexoRank.middle().toString();
    switch (position) {
        case 'bottom': {
            var allRanks = allTasks
                .map(function (t) { return t.rank; })
                .sort(function (a, b) { return a.localeCompare(b); });
            var lastRank = allRanks[allRanks.length - 1];
            return lastRank ? nextRank(lastRank) : defaultRank;
        }
        case 'top': {
            var allRanks = allTasks
                .map(function (t) { return t.rank; })
                .sort(function (a, b) { return a.localeCompare(b); });
            var firstRank = allRanks[0];
            return firstRank ? previousRank(firstRank) : defaultRank;
        }
        case 'second': {
            var allRanks = allTasks
                .map(function (t) { return t.rank; })
                .sort(function (a, b) { return a.localeCompare(b); });
            if (allRanks.length === 0)
                return defaultRank;
            // So we have some tasks…
            var uncompletedTasksRanks = allTasks
                .filter(function (t) { return !t.completedAt; })
                .map(function (t) { return t.rank; })
                .sort(function (a, b) { return a.localeCompare(b); });
            var firstRank_1 = uncompletedTasksRanks[0];
            if (!firstRank_1) {
                // But all of them completed? Put at bottom.
                var lastRank = allRanks[allRanks.length - 1];
                return nextRank(lastRank);
            }
            // Take the currently second rank, completed or not, and put in-between.
            var index = allRanks.findIndex(function (r) { return r === firstRank_1; });
            var secondRank = allRanks[index + 1];
            return secondRank
                ? inBetweenRank(firstRank_1, secondRank)
                : nextRank(firstRank_1);
        }
    }
};
function nextRank(rank) {
    return lexorank_1.LexoRank.parse(rank).genNext().toString();
}
function previousRank(rank) {
    return lexorank_1.LexoRank.parse(rank).genPrev().toString();
}
function inBetweenRank(rankA, rankB) {
    return lexorank_1.LexoRank.parse(rankA).between(lexorank_1.LexoRank.parse(rankB)).toString();
}
function createTaskAt(position, task) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var allTasksQuery, allTasksSnapshot, allTasks, rank, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid))
                        return [2 /*return*/];
                    allTasksQuery = task.state === 'session'
                        ? config_1.db
                            .collection(pathToTasks(config_1.auth.currentUser.uid))
                            .where('state', '==', 'session')
                        : config_1.db
                            .collection(pathToTasks(config_1.auth.currentUser.uid))
                            .where('bucketId', '==', task.bucketId)
                            .where('completedAt', '==', null);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, allTasksQuery
                            .where('deletedAt', '==', null)
                            .withConverter((0, config_1.converter)())
                            .get()];
                case 2:
                    allTasksSnapshot = _b.sent();
                    allTasks = allTasksSnapshot.docs.map(function (doc) { return doc.data(); });
                    rank = calculateRank(allTasks, position);
                    return [2 /*return*/, createTask(__assign(__assign({}, task), { rank: rank }))];
                case 3:
                    e_1 = _b.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
exports.createTaskAt = createTaskAt;
function createTask(task) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var taskName, doc, taskToCreate;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid))
                        return [2 /*return*/];
                    taskName = task.name.substr(0, helpers_1.TASK_NAME_MAX_LENGTH);
                    if (taskName.trim() === '') {
                        throw new Error("Task name can't be empty");
                    }
                    doc = config_1.db
                        .collection(pathToTasks((_b = config_1.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid))
                        .doc()
                        .withConverter((0, config_1.converter)());
                    taskToCreate = __assign(__assign({}, task), { name: taskName, source: 'centered', createdAt: new Date(), timeWorked: 0, isPrivate: true, startAt: task.templateId ? null : task.startAt, completedAt: null, modifiedBy: 'User', deletedAt: null, lastWorkStartedAt: null, completedInFlowsessionId: null, id: doc.id });
                    return [4 /*yield*/, doc.set(taskToCreate)];
                case 1:
                    _c.sent();
                    return [2 /*return*/, doc.id];
            }
        });
    });
}
exports.createTask = createTask;
function updateAllTasks(tasks) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var taskRefs, batch, operations, _loop_1, _i, tasks_1, task;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid))
                        return [2 /*return*/];
                    return [4 /*yield*/, config_1.db.collection(pathToTasks((_b = config_1.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid)).get()];
                case 1:
                    taskRefs = _d.sent();
                    batch = config_1.firebase.firestore().batch();
                    operations = 0;
                    _loop_1 = function (task) {
                        var bucketId, name_1, rank, state, timeEstimated, timeWorked, lastWorkStartedAt, _e, completedAt, completedInFlowsessionId, activeTaskSince, timeWorkedLastSession, lastActiveSessionId, isPrivate, startAt, updatedAttributes, seconds, ref;
                        return __generator(this, function (_f) {
                            switch (_f.label) {
                                case 0:
                                    bucketId = task.bucketId, name_1 = task.name, rank = task.rank, state = task.state, timeEstimated = task.timeEstimated, timeWorked = task.timeWorked, lastWorkStartedAt = task.lastWorkStartedAt, _e = task.completedAt, completedAt = _e === void 0 ? null : _e, completedInFlowsessionId = task.completedInFlowsessionId, activeTaskSince = task.activeTaskSince, timeWorkedLastSession = task.timeWorkedLastSession, lastActiveSessionId = task.lastActiveSessionId, isPrivate = task.isPrivate, startAt = task.startAt;
                                    updatedAttributes = {
                                        // Take an explicit list to prevent client from storing anything else
                                        bucketId: bucketId,
                                        rank: rank,
                                        state: state,
                                        timeEstimated: timeEstimated,
                                        timeWorked: timeWorked,
                                        lastWorkStartedAt: lastWorkStartedAt,
                                        completedAt: completedAt,
                                        modifiedBy: 'User',
                                        completedInFlowsessionId: completedInFlowsessionId,
                                        activeTaskSince: activeTaskSince,
                                        timeWorkedLastSession: timeWorkedLastSession,
                                        lastActiveSessionId: lastActiveSessionId,
                                        isPrivate: isPrivate,
                                        startAt: startAt,
                                    };
                                    // Don't accept the new name if it's not valid
                                    if (name_1 && name_1.trim() !== '') {
                                        updatedAttributes.name = name_1;
                                    }
                                    // If active task is moved out of session, deactivate it
                                    if (activeTaskSince && state !== 'session') {
                                        seconds = Math.floor((Date.now() - activeTaskSince) / 1000);
                                        updatedAttributes.timeWorkedLastSession = Math.floor((timeWorkedLastSession !== null && timeWorkedLastSession !== void 0 ? timeWorkedLastSession : 0) + seconds);
                                        updatedAttributes.timeWorked = Math.floor((timeWorked !== null && timeWorked !== void 0 ? timeWorked : 0) + seconds);
                                        updatedAttributes.activeTaskSince = null;
                                        updatedAttributes.lastWorkStartedAt = null;
                                    }
                                    ref = (_c = taskRefs.docs.find(function (doc) { return doc.id === task.id; })) === null || _c === void 0 ? void 0 : _c.ref;
                                    if (!ref) {
                                        console.error("[updateAllTasks] Task with id ".concat(task.id, " not found"));
                                        return [2 /*return*/, "continue"];
                                    }
                                    operations++;
                                    if (!(operations >= 499)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, batch.commit()];
                                case 1:
                                    _f.sent();
                                    batch = config_1.firebase.firestore().batch();
                                    operations = 0;
                                    _f.label = 2;
                                case 2:
                                    batch.update(ref, updatedAttributes);
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _i = 0, tasks_1 = tasks;
                    _d.label = 2;
                case 2:
                    if (!(_i < tasks_1.length)) return [3 /*break*/, 5];
                    task = tasks_1[_i];
                    return [5 /*yield**/, _loop_1(task)];
                case 3:
                    _d.sent();
                    _d.label = 4;
                case 4:
                    _i++;
                    return [3 /*break*/, 2];
                case 5: return [4 /*yield*/, batch.commit()];
                case 6:
                    _d.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateAllTasks = updateAllTasks;
function updateTask(taskId, _a) {
    var _b, _c;
    var bucketId = _a.bucketId, name = _a.name, rank = _a.rank, state = _a.state, timeEstimated = _a.timeEstimated, timeWorked = _a.timeWorked, lastWorkStartedAt = _a.lastWorkStartedAt, _d = _a.completedAt, completedAt = _d === void 0 ? null : _d, completedInFlowsessionId = _a.completedInFlowsessionId, activeTaskSince = _a.activeTaskSince, timeWorkedLastSession = _a.timeWorkedLastSession, lastActiveSessionId = _a.lastActiveSessionId, isPrivate = _a.isPrivate, startAt = _a.startAt;
    return __awaiter(this, void 0, void 0, function () {
        var updatedAttributes, seconds;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!((_b = config_1.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid))
                        return [2 /*return*/];
                    updatedAttributes = {
                        // Take an explicit list to prevent client from storing anything else
                        bucketId: bucketId,
                        rank: rank,
                        state: state,
                        timeEstimated: timeEstimated,
                        timeWorked: timeWorked,
                        lastWorkStartedAt: lastWorkStartedAt,
                        completedAt: completedAt,
                        modifiedBy: 'User',
                        completedInFlowsessionId: completedInFlowsessionId,
                        activeTaskSince: activeTaskSince,
                        timeWorkedLastSession: timeWorkedLastSession,
                        lastActiveSessionId: lastActiveSessionId,
                        isPrivate: isPrivate,
                        startAt: startAt,
                    };
                    // Don't accept the new name if it's not valid
                    if (name && name.trim() !== '') {
                        updatedAttributes.name = name;
                    }
                    // If active task is moved out of session, deactivate it
                    if (activeTaskSince && state !== 'session') {
                        seconds = Math.floor((Date.now() - activeTaskSince) / 1000);
                        updatedAttributes.timeWorkedLastSession = Math.floor((timeWorkedLastSession !== null && timeWorkedLastSession !== void 0 ? timeWorkedLastSession : 0) + seconds);
                        updatedAttributes.timeWorked = Math.floor((timeWorked !== null && timeWorked !== void 0 ? timeWorked : 0) + seconds);
                        updatedAttributes.activeTaskSince = null;
                        updatedAttributes.lastWorkStartedAt = null;
                    }
                    return [4 /*yield*/, config_1.db
                            .collection(pathToTasks((_c = config_1.auth.currentUser) === null || _c === void 0 ? void 0 : _c.uid))
                            .doc(taskId)
                            .withConverter((0, config_1.converter)())
                            .update(updatedAttributes)];
                case 1:
                    _e.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateTask = updateTask;
function deleteTask(taskId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid))
                        return [2 /*return*/];
                    return [4 /*yield*/, config_1.db
                            .collection(pathToTasks((_b = config_1.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid))
                            .doc(taskId)
                            .withConverter((0, config_1.converter)())
                            .update({
                            deletedAt: new Date(),
                        })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteTask = deleteTask;
function undeleteTask(taskId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid))
                        return [2 /*return*/];
                    return [4 /*yield*/, config_1.db
                            .collection(pathToTasks((_b = config_1.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid))
                            .doc(taskId)
                            .withConverter((0, config_1.converter)())
                            .update({
                            deletedAt: null,
                        })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.undeleteTask = undeleteTask;
function subscribeToTasks(onChange, _a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, _d = _c.completedSince, completedSince = _d === void 0 ? luxon_1.DateTime.now().minus({ day: 7 }).toJSDate() : _d;
    var userId = (_b = config_1.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid;
    if (!userId) {
        throw new Error("Can't subscribe to task changes without an userID");
    }
    return config_1.db
        .collection(pathToTasks(userId))
        .where('deletedAt', '==', null)
        .withConverter((0, config_1.converter)())
        .onSnapshot(function (snapshot) {
        onChange(snapshot
            .docChanges()
            .map(function (_a) {
            var type = _a.type, doc = _a.doc;
            return ({
                type: type,
                task: firebaseTaskDocToTask(doc),
            });
        })
            .filter(function (_a) {
            var task = _a.task;
            return task.completedAt === null ||
                task.completedAt.getTime() >= completedSince.getTime();
        }));
    }, function (error) {
        console.error('Error in subscribeToTasks', error);
    });
}
exports.subscribeToTasks = subscribeToTasks;
