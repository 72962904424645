"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHasUnreadNotifications = void 0;
var react_1 = require("react");
var __1 = require("..");
function useHasUnreadNotifications() {
    var _a;
    var currentUser = (0, __1.useCurrentUser)().currentUser;
    var _b = (0, react_1.useState)(((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.unreadNotificationCount) !== null && _a !== void 0 ? _a : 0) > 0), hasUnreadNotifications = _b[0], setHasUnreadNotifications = _b[1];
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        var unsubscribe = __1.db
            .collection("/users/".concat(currentUser.id, "/notifications"))
            .where('viewedAt', '==', null)
            .where('type', 'in', [
            'NEW_FOLLOWER',
            'NEW_GROUP_MEMBERSHIP',
            'NEW_GROUP_ADMIN',
        ])
            .limit(1)
            .onSnapshot(function (snapshot) {
            setHasUnreadNotifications(!snapshot.empty);
        }, function (error) {
            console.error('useHasUnreadNotifications error: ', error);
        });
        return function () {
            unsubscribe();
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return {
        hasUnreadNotifications: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.premiumSettings.hideNewNotificationBadge)
            ? false
            : hasUnreadNotifications,
    };
}
exports.useHasUnreadNotifications = useHasUnreadNotifications;
