"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useManageFeedComments = void 0;
var react_1 = require("react");
var __1 = require("..");
var config_1 = require("../config");
function useManageFeedComments(_a) {
    var defaultComments = _a.defaultComments, userId = _a.userId, feedUpdateId = _a.feedUpdateId, isVisibleInViewport = _a.isVisibleInViewport;
    var currentUser = (0, __1.useCurrentUser)().currentUser;
    var _b = (0, react_1.useState)(defaultComments), comments = _b[0], setComments = _b[1];
    (0, react_1.useEffect)(function () {
        if (!isVisibleInViewport)
            return;
        var isMounted = true;
        var unsubscribe = config_1.db
            .collection("/users/".concat(userId, "/feed-updates/").concat(feedUpdateId, "/comments"))
            .orderBy('createdAt', 'asc')
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            if (isMounted) {
                var allComments_1 = snapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); });
                setComments(allComments_1);
                setVisibleComments(function (curr) {
                    if (curr.length === 0) {
                        return allComments_1.slice(-1);
                    }
                    return curr;
                });
            }
        }, function (error) {
            console.error('useManageFeedComments error: ', error);
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [userId, feedUpdateId, isVisibleInViewport]);
    var _c = (0, react_1.useState)(defaultComments), visibleComments = _c[0], setVisibleComments = _c[1];
    var addComment = (0, react_1.useCallback)(function (commentText, editorState, usersMentioned) {
        if (!currentUser)
            return;
        var newCommentRef = config_1.db
            .collection("/users/".concat(userId, "/feed-updates/").concat(feedUpdateId, "/comments"))
            .doc();
        var comment = {
            id: newCommentRef.id,
            commentText: commentText,
            createdAt: Date.now(),
            name: currentUser.name,
            userId: currentUser.id,
            avatarUrl: currentUser.avatarUrl,
            commentEditorState: editorState,
            mentions: usersMentioned,
        };
        setComments(function (curr) { return __spreadArray(__spreadArray([], curr, true), [comment], false); });
        setVisibleComments(function (curr) { return __spreadArray(__spreadArray([], curr, true), [comment], false); });
        newCommentRef.set(comment);
    }, [currentUser, feedUpdateId, userId]);
    var toggleCommentView = (0, react_1.useCallback)(function (amountToShow) {
        if (amountToShow === 'ALL') {
            setVisibleComments(Array.from(comments));
        }
        else if (amountToShow === 'LESS') {
            setVisibleComments(Array.from(comments).slice(-1));
        }
    }, [comments]);
    var deleteComment = (0, react_1.useCallback)(function (commentId) {
        var idx = comments.findIndex(function (comment) { return comment.id === commentId; });
        if (idx === -1)
            return;
        var newComments = __spreadArray([], comments, true);
        newComments.splice(idx, 1);
        setComments(newComments);
        var visibleIdx = visibleComments.findIndex(function (comment) { return comment.id === commentId; });
        if (visibleIdx !== -1) {
            var newVisibleComments = __spreadArray([], visibleComments, true);
            newVisibleComments.splice(visibleIdx, 1);
            if (newVisibleComments.length === 0) {
                newVisibleComments = newComments.slice(-1);
            }
            setVisibleComments(newVisibleComments);
        }
        config_1.db.doc("/users/".concat(userId, "/feed-updates/").concat(feedUpdateId, "/comments/").concat(commentId))
            .delete()
            .catch(function (error) {
            console.error('Error in deleting comment with id', commentId, error);
        });
    }, [userId, feedUpdateId, comments, visibleComments]);
    return {
        comments: comments,
        visibleComments: visibleComments,
        addComment: addComment,
        toggleCommentView: toggleCommentView,
        deleteComment: deleteComment,
    };
}
exports.useManageFeedComments = useManageFeedComments;
