"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickVideoToPlay = void 0;
var pickFrom_1 = require("./pickFrom");
function pickVideoToPlay(videos, watchHistory) {
    var unwatchedSequenceVideos = videos
        .filter(function (video) { return video.order && !(video.id in watchHistory); })
        .sort(function (a, z) { var _a, _b; return (((_a = a.order) !== null && _a !== void 0 ? _a : 0) > ((_b = z.order) !== null && _b !== void 0 ? _b : 0) ? 1 : -1); });
    var minimumTimeAnyVideoHasBeenWatched = Math.min.apply(Math, videos.map(function (video) { return watchHistory[video.id] || 0; }));
    var leastWatchedVideos = videos.filter(function (video) {
        if (video.order) {
            return false;
        }
        return (watchHistory[video.id] === minimumTimeAnyVideoHasBeenWatched ||
            minimumTimeAnyVideoHasBeenWatched === 0);
    });
    return __spreadArray(__spreadArray([], unwatchedSequenceVideos, true), [(0, pickFrom_1.pickFrom)(leastWatchedVideos)], false)[0];
}
exports.pickVideoToPlay = pickVideoToPlay;
