"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowWithGroup = exports.setNewPasswordUsingCode = exports.sendPasswordResetEmail = exports.updatePushTokens = exports.migrateAndDeleteAnonymousUser = exports.createUserForAnonymousSignIn = exports.updateUserPremiumSettings = exports.updateUserSettings = exports.updateUserLocation = exports.updateUserName = exports.updateUserBio = exports.updateVideoBackground = exports.updateGroupDetailBackground = exports.addGroupPlayerBackground = exports.updateGroupFeatureCover = exports.updateGroupPlayerCover = exports.updateGroupCover = exports.updateGroupIcon = exports.updateUserFooter = exports.updateUserCover = exports.updateUserAvatar = exports.MAX_AVATAR_FILE_SIZE_IN_MB = exports.addPhoneNumber = exports.submitLifetimeAccessCode = exports.updateUserProfile = exports.createUser = exports.updateCurrentUsersTimezone = exports.updateUserPassword = void 0;
var types_1 = require("@centered/types");
var uuid = require("uuid");
var __1 = require("..");
var config_1 = require("../config");
var currentUserMachine_1 = require("../machines/currentUserMachine");
function updateUserPassword(newPassword, currentPassword) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var currentUser, credentials;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    currentUser = config_1.auth.currentUser;
                    if (!currentUser)
                        return [2 /*return*/];
                    if (!currentUser.providerData.find(function (p) { return (p === null || p === void 0 ? void 0 : p.providerId) === 'password'; })) return [3 /*break*/, 3];
                    credentials = config_1.firebase.auth.EmailAuthProvider.credential((_a = currentUser.email) !== null && _a !== void 0 ? _a : '', currentPassword);
                    return [4 /*yield*/, currentUser.reauthenticateWithCredential(credentials)];
                case 1:
                    _b.sent();
                    return [4 /*yield*/, currentUser.updatePassword(newPassword)];
                case 2:
                    _b.sent();
                    return [2 /*return*/];
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.updateUserPassword = updateUserPassword;
function updateCurrentUsersTimezone() {
    return __awaiter(this, void 0, void 0, function () {
        var currentUser, timezone, timezoneOffset;
        return __generator(this, function (_a) {
            currentUser = config_1.auth.currentUser;
            if (!currentUser)
                return [2 /*return*/];
            try {
                timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                timezoneOffset = new Date().getTimezoneOffset();
                config_1.db.collection('private-users')
                    .doc(currentUser.uid)
                    .update({ timezone: timezone, timezoneOffset: timezoneOffset });
            }
            catch (err) {
                console.error('Error in updateUsersTimezone', err);
            }
            return [2 /*return*/];
        });
    });
}
exports.updateCurrentUsersTimezone = updateCurrentUsersTimezone;
function createUser(name, args) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return __awaiter(this, void 0, void 0, function () {
        var currentUser, invitedByUser, _j, found, updates, domain, privateGroup, finalUser;
        var _this = this;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    console.debug('[manageUser:createUser] creating user', name, args);
                    currentUser = config_1.auth.currentUser;
                    if (!currentUser)
                        throw new Error('No current user');
                    if (!(args === null || args === void 0 ? void 0 : args.invitedByUserId)) return [3 /*break*/, 4];
                    _k.label = 1;
                case 1:
                    _k.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, __1.getUser)(args.invitedByUserId)];
                case 2:
                    invitedByUser = _k.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _j = _k.sent();
                    console.log("Can't find user with id", args.invitedByUserId);
                    return [3 /*break*/, 4];
                case 4: return [4 /*yield*/, (0, __1.getUser)(currentUser.uid)];
                case 5:
                    found = _k.sent();
                    console.debug('[manageUser:createUser] found user', found);
                    return [4 /*yield*/, currentUser.updateProfile({ displayName: (_b = (_a = found === null || found === void 0 ? void 0 : found.name) !== null && _a !== void 0 ? _a : name) !== null && _b !== void 0 ? _b : '' })];
                case 6:
                    _k.sent();
                    updates = {
                        isAnonymous: false,
                        name: (_c = found === null || found === void 0 ? void 0 : found.name) !== null && _c !== void 0 ? _c : name,
                        avatarUrl: (_d = found === null || found === void 0 ? void 0 : found.avatarUrl) !== null && _d !== void 0 ? _d : args === null || args === void 0 ? void 0 : args.avatarUrl,
                    };
                    if (invitedByUser) {
                        updates.invitedBy = {
                            id: invitedByUser.id,
                            name: invitedByUser.name,
                            avatarUrl: invitedByUser.avatarUrl,
                        };
                    }
                    domain = (_f = (_e = currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) === null || _e === void 0 ? void 0 : _e.split('@')[1]) !== null && _f !== void 0 ? _f : '';
                    return [4 /*yield*/, config_1.db
                            .collection('groups')
                            .withConverter((0, config_1.converter)())
                            .where('domainName', '==', domain)
                            .where('isPrivate', '==', true)
                            .limit(1)
                            .get()];
                case 7:
                    privateGroup = (_k.sent()).docs[0];
                    if (!found) {
                        updates = __assign(__assign({}, updates), { createdDate: Date.now(), id: currentUser.uid, minutesFlowed: 0, sessionId: undefined, settings: __assign(__assign({}, types_1.defaultUserSettings), { defaultFlowhallId: (_g = privateGroup === null || privateGroup === void 0 ? void 0 : privateGroup.id) !== null && _g !== void 0 ? _g : types_1.defaultUserSettings.defaultFlowhallId }), isAnonymous: currentUser.isAnonymous, conversionTracking: args === null || args === void 0 ? void 0 : args.conversionTracking, invitedIntoGroupId: args === null || args === void 0 ? void 0 : args.invitedIntoGroupId });
                    }
                    return [4 /*yield*/, config_1.db.runTransaction(function (tx) { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                // upsert public user
                                tx.set(config_1.db.collection('users').doc(currentUser.uid), updates, {
                                    merge: true,
                                });
                                // upsert private user
                                tx.set(config_1.db.collection('private-users').doc(currentUser.uid), {
                                    id: currentUser.uid,
                                    email: (_a = currentUser.email) !== null && _a !== void 0 ? _a : null,
                                }, { merge: true });
                                return [2 /*return*/];
                            });
                        }); })];
                case 8:
                    _k.sent();
                    if (!invitedByUser) return [3 /*break*/, 11];
                    return [4 /*yield*/, (0, __1.getUser)(currentUser.uid)];
                case 9:
                    finalUser = _k.sent();
                    if (!finalUser) return [3 /*break*/, 11];
                    return [4 /*yield*/, (0, __1.followFriend)(finalUser, {
                            id: invitedByUser.id,
                            name: invitedByUser.name,
                            username: invitedByUser.username,
                            avatarUrl: invitedByUser.avatarUrl,
                            sessionId: invitedByUser.sessionId,
                            groupId: invitedByUser.currentFlowhallId,
                            flowState: (_h = invitedByUser.flowState) === null || _h === void 0 ? void 0 : _h.state,
                        })];
                case 10:
                    _k.sent();
                    _k.label = 11;
                case 11: return [2 /*return*/, !found];
            }
        });
    });
}
exports.createUser = createUser;
function updateUserProfile(name, email) {
    return __awaiter(this, void 0, void 0, function () {
        var currentUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentUser = config_1.auth.currentUser;
                    if (!currentUser)
                        return [2 /*return*/];
                    return [4 /*yield*/, currentUser.updateEmail(email)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, currentUser.updateProfile({ displayName: name })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, config_1.db.collection('users').doc(currentUser.uid).update({
                            name: name,
                        })];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, config_1.db.collection('private-users').doc(currentUser.uid).update({
                            email: email,
                        })];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateUserProfile = updateUserProfile;
function submitLifetimeAccessCode(code) {
    return __awaiter(this, void 0, void 0, function () {
        var currentUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentUser = config_1.auth.currentUser;
                    if (!currentUser)
                        return [2 /*return*/];
                    return [4 /*yield*/, config_1.db.collection('private-users').doc(currentUser.uid).update({
                            lifetimeAccessCode: code,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.submitLifetimeAccessCode = submitLifetimeAccessCode;
function addPhoneNumber(phoneNumber) {
    return __awaiter(this, void 0, void 0, function () {
        var currentUser;
        return __generator(this, function (_a) {
            currentUser = config_1.auth.currentUser;
            if (!currentUser)
                return [2 /*return*/];
            return [2 /*return*/, config_1.db.collection('private-users').doc(currentUser.uid).update({
                    phoneNumber: phoneNumber,
                })];
        });
    });
}
exports.addPhoneNumber = addPhoneNumber;
exports.MAX_AVATAR_FILE_SIZE_IN_MB = 10;
function updateUserAvatar(avatarImg) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId, extension, imageRef, snapshot, url, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
                    if (!avatarImg) {
                        return [2 /*return*/, config_1.db.collection('users').doc(userId).update({
                                avatarUrl: null,
                            })];
                    }
                    extension = avatarImg.name.split('.').pop();
                    imageRef = config_1.avatarStorage
                        .ref()
                        .child("".concat(userId, "-").concat(uuid.v4(), ".").concat(extension));
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, imageRef.put(avatarImg)];
                case 2:
                    snapshot = _b.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 3:
                    url = _b.sent();
                    return [2 /*return*/, config_1.db.collection('users').doc(userId).update({
                            avatarUrl: url,
                        })];
                case 4:
                    e_1 = _b.sent();
                    console.error('error in updateUserAvatar', e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.updateUserAvatar = updateUserAvatar;
function updateUserCover(coverImg) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
                    imageRef = config_1.avatarStorage.ref().child("".concat(userId, "-").concat(uuid.v4(), "-cover.jpg"));
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, imageRef.put(coverImg)];
                case 2:
                    snapshot = _b.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 3:
                    url = _b.sent();
                    return [2 /*return*/, config_1.db.collection('users').doc(userId).update({
                            coverUrl: url,
                        })];
                case 4:
                    e_2 = _b.sent();
                    console.error('error in updateUserCover', e_2);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.updateUserCover = updateUserCover;
function updateUserFooter(coverImg) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
                    imageRef = config_1.avatarStorage
                        .ref()
                        .child("".concat(userId, "-").concat(uuid.v4(), "-footer.jpg"));
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, imageRef.put(coverImg)];
                case 2:
                    snapshot = _b.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 3:
                    url = _b.sent();
                    return [2 /*return*/, config_1.db.collection('users').doc(userId).update({
                            footerUrl: url,
                        })];
                case 4:
                    e_3 = _b.sent();
                    console.error('error in updateUserFooter', e_3);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.updateUserFooter = updateUserFooter;
function updateGroupIcon(image, groupId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.groupStorage
                        .ref()
                        .child("".concat(userId, "-").concat(groupId, "-").concat(uuid.v4(), "-icon.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [2 /*return*/, config_1.db.doc("groups/".concat(groupId)).update({
                            iconUrl: url,
                        })];
            }
        });
    });
}
exports.updateGroupIcon = updateGroupIcon;
function updateGroupCover(image, groupId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.groupStorage
                        .ref()
                        .child("".concat(userId, "-").concat(groupId, "-").concat(uuid.v4(), "-cover.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [2 /*return*/, config_1.db.doc("groups/".concat(groupId)).update({
                            coverUrl: url,
                        })];
            }
        });
    });
}
exports.updateGroupCover = updateGroupCover;
function updateGroupPlayerCover(image, groupId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.groupStorage
                        .ref()
                        .child("".concat(userId, "-").concat(groupId, "-").concat(uuid.v4(), "-player.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [2 /*return*/, config_1.db.doc("groups/".concat(groupId)).update({
                            playerUrl: url,
                        })];
            }
        });
    });
}
exports.updateGroupPlayerCover = updateGroupPlayerCover;
function updateGroupFeatureCover(image, groupId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.groupStorage
                        .ref()
                        .child("".concat(userId, "-").concat(groupId, "-").concat(uuid.v4(), "-feature.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [2 /*return*/, config_1.db.doc("groups/".concat(groupId)).update({
                            featureUrl: url,
                        })];
            }
        });
    });
}
exports.updateGroupFeatureCover = updateGroupFeatureCover;
function addGroupPlayerBackground(image, groupId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.groupStorage
                        .ref()
                        .child("".concat(userId, "-").concat(groupId, "-").concat(uuid.v4(), "-player-bg.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [2 /*return*/, config_1.db.doc("groups/".concat(groupId)).update({
                            playerBackgroundImageUrls: config_1.firebase.firestore.FieldValue.arrayUnion(url),
                        })];
            }
        });
    });
}
exports.addGroupPlayerBackground = addGroupPlayerBackground;
function updateGroupDetailBackground(image, groupId) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.groupStorage
                        .ref()
                        .child("".concat(userId, "-").concat(groupId, "-").concat(uuid.v4(), "-detail-bg.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [2 /*return*/, config_1.db.doc("groups/".concat(groupId)).update({
                            detailBackgroundUrl: url,
                        })];
            }
        });
    });
}
exports.updateGroupDetailBackground = updateGroupDetailBackground;
function updateVideoBackground(image) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var user, userId, imageRef, snapshot, url;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    user = (0, currentUserMachine_1.getCurrentUser)();
                    if (!user)
                        return [2 /*return*/];
                    userId = (_b = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : 'unknown';
                    imageRef = config_1.avatarStorage
                        .ref()
                        .child("".concat(userId, "-").concat(uuid.v4(), "-video-bg.jpg"));
                    return [4 /*yield*/, imageRef.put(image)];
                case 1:
                    snapshot = _c.sent();
                    return [4 /*yield*/, snapshot.ref.getDownloadURL()];
                case 2:
                    url = _c.sent();
                    return [4 /*yield*/, updateUserPremiumSettings('videoBackgroundUrl', url)];
                case 3:
                    _c.sent();
                    return [4 /*yield*/, updateUserPremiumSettings('videoBackgroundUrls', __spreadArray(__spreadArray([], user.premiumSettings.videoBackgroundUrls, true), [
                            url,
                        ], false))];
                case 4:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateVideoBackground = updateVideoBackground;
function updateUserBio(bio) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId;
        return __generator(this, function (_b) {
            userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
            return [2 /*return*/, config_1.db.collection('users').doc(userId).update({
                    bio: bio,
                })];
        });
    });
}
exports.updateUserBio = updateUserBio;
function updateUserName(name) {
    return __awaiter(this, void 0, void 0, function () {
        var currentUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Don't save empty user names
                    if (name.length === 0) {
                        return [2 /*return*/];
                    }
                    currentUser = config_1.auth.currentUser;
                    if (!currentUser)
                        return [2 /*return*/];
                    return [4 /*yield*/, currentUser.updateProfile({ displayName: name })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, config_1.db.collection('users').doc(currentUser.uid).update({
                            name: name,
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateUserName = updateUserName;
function updateUserLocation(location) {
    return __awaiter(this, void 0, void 0, function () {
        var currentUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentUser = config_1.auth.currentUser;
                    if (!currentUser)
                        return [2 /*return*/];
                    return [4 /*yield*/, config_1.db.collection('users').doc(currentUser.uid).update({
                            location: location,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateUserLocation = updateUserLocation;
function updateUserSettings(key, value) {
    return __awaiter(this, void 0, void 0, function () {
        var user, e_4;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    user = (0, currentUserMachine_1.getCurrentUser)();
                    if (!user)
                        throw new Error('No user');
                    (0, currentUserMachine_1.optimisticallyUpdateLocalUser)(__assign(__assign({}, user), { settings: __assign(__assign({}, user.settings), (_a = {}, _a[key] = value, _a)) }));
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, config_1.db
                            .collection('users')
                            .doc(user.id)
                            .update((_b = {}, _b["settings.".concat(key)] = value, _b))];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _c.sent();
                    console.error('Error in updateUserSettings', e_4);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
exports.updateUserSettings = updateUserSettings;
function updateUserPremiumSettings(key, value) {
    return __awaiter(this, void 0, void 0, function () {
        var user, e_5;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    user = (0, currentUserMachine_1.getCurrentUser)();
                    if (!user)
                        throw new Error('No user');
                    (0, currentUserMachine_1.optimisticallyUpdateLocalUser)(__assign(__assign({}, user), { premiumSettings: __assign(__assign({}, user.premiumSettings), (_a = {}, _a[key] = value, _a)) }));
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, config_1.db
                            .collection('users')
                            .doc(user.id)
                            .update((_b = {}, _b["premiumSettings.".concat(key)] = value, _b))];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_5 = _c.sent();
                    console.error('Error in updateUserPremiumSettings', e_5);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
exports.updateUserPremiumSettings = updateUserPremiumSettings;
function createUserForAnonymousSignIn(id, name) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, config_1.db.collection('users').doc(id).set({
                    id: id,
                    name: name,
                    avatarUrl: null,
                })];
        });
    });
}
exports.createUserForAnonymousSignIn = createUserForAnonymousSignIn;
function migrateAndDeleteAnonymousUser(oldAnonymousUser) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var oldUserId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    oldUserId = oldAnonymousUser.id;
                    // Delete auth record
                    return [4 /*yield*/, ((_a = oldAnonymousUser === null || oldAnonymousUser === void 0 ? void 0 : oldAnonymousUser.firebaseAuthUser) === null || _a === void 0 ? void 0 : _a.delete())
                        // Delete users record
                    ];
                case 1:
                    // Delete auth record
                    _b.sent();
                    // Delete users record
                    return [4 /*yield*/, config_1.db.collection('users').doc(oldUserId).delete()];
                case 2:
                    // Delete users record
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.migrateAndDeleteAnonymousUser = migrateAndDeleteAnonymousUser;
function updatePushTokens(token) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId;
        return __generator(this, function (_b) {
            userId = (_a = config_1.auth === null || config_1.auth === void 0 ? void 0 : config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
            if (!userId) {
                throw new Error('No user');
            }
            return [2 /*return*/, config_1.db
                    .collection('users')
                    .doc(userId)
                    .update({
                    pushTokens: config_1.firebase.firestore.FieldValue.arrayUnion(token),
                })];
        });
    });
}
exports.updatePushTokens = updatePushTokens;
function sendPasswordResetEmail(email) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, config_1.auth.sendPasswordResetEmail(email)];
        });
    });
}
exports.sendPasswordResetEmail = sendPasswordResetEmail;
function setNewPasswordUsingCode(newPassword, code) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, config_1.auth.confirmPasswordReset(code, newPassword)];
        });
    });
}
exports.setNewPasswordUsingCode = setNewPasswordUsingCode;
function flowWithGroup(user, groupId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateUserSettings('defaultFlowhallId', groupId)
                    // join group
                ];
                case 1:
                    _a.sent();
                    // join group
                    return [4 /*yield*/, config_1.db
                            .doc("/groups/".concat(groupId, "/group-members/").concat(user.id))
                            .withConverter((0, config_1.converter)())
                            .set({
                            id: user.id,
                            name: user.name,
                            username: user.username,
                            avatarUrl: user.avatarUrl,
                            isAnonymous: user.isAnonymous,
                        }, {
                            merge: true,
                        })];
                case 2:
                    // join group
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.flowWithGroup = flowWithGroup;
