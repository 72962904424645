"use strict";
// Implemented from these ideas: https://javascript.info/js-animation
Object.defineProperty(exports, "__esModule", { value: true });
exports.animateWithTimeout = exports.animateWithRaf = exports.lerp = exports.makeEaseInOut = exports.makeEaseOut = exports.circ = exports.quad = exports.linear = void 0;
function linear(timeFraction) {
    return timeFraction;
}
exports.linear = linear;
function quad(timeFraction) {
    return Math.pow(timeFraction, 2);
}
exports.quad = quad;
function circ(timeFraction) {
    return 1 - Math.sin(Math.acos(timeFraction));
}
exports.circ = circ;
function makeEaseOut(timing) {
    return function (timeFraction) {
        return 1 - timing(1 - timeFraction);
    };
}
exports.makeEaseOut = makeEaseOut;
function makeEaseInOut(timing) {
    return function (timeFraction) {
        if (timeFraction < 0.5)
            return timing(2 * timeFraction) / 2;
        else
            return (2 - timing(2 * (1 - timeFraction))) / 2;
    };
}
exports.makeEaseInOut = makeEaseInOut;
function lerp(start, end, t) {
    return start * (1 - t) + end * t;
}
exports.lerp = lerp;
function animateWithRaf(startValues, endValues, duration, // in ms
easingFunction, onUpdate, onComplete) {
    var start = performance.now();
    var frame;
    frame = requestAnimationFrame(function loop(time) {
        var timeFraction = (time - start) / duration;
        if (timeFraction > 1)
            timeFraction = 1;
        var progress = easingFunction(timeFraction);
        onUpdate(startValues.map(function (startValue, i) {
            var endValue = endValues[i];
            return lerp(startValue, endValue, progress);
        }));
        if (timeFraction === 1) {
            if (onComplete)
                onComplete(false);
        }
        else {
            frame = requestAnimationFrame(loop);
        }
    });
    var cancel = function () {
        if (frame) {
            cancelAnimationFrame(frame);
            if (onComplete)
                onComplete(true);
        }
    };
    return {
        cancel: cancel,
    };
}
exports.animateWithRaf = animateWithRaf;
function animateWithTimeout(startValues, endValues, duration, // in ms
easingFunction, onUpdate, onComplete, clock) {
    var _a, _b;
    var mySetTimeout = (_a = clock === null || clock === void 0 ? void 0 : clock.setTimeout) !== null && _a !== void 0 ? _a : setTimeout;
    var myClearTimeout = (_b = clock === null || clock === void 0 ? void 0 : clock.clearTimeout) !== null && _b !== void 0 ? _b : clearTimeout;
    var start = performance.now();
    var frame = mySetTimeout(function loop() {
        var time = performance.now();
        var timeFraction = (time - start) / duration;
        if (timeFraction > 1)
            timeFraction = 1;
        var progress = easingFunction(timeFraction);
        onUpdate(startValues.map(function (startValue, i) {
            var endValue = endValues[i];
            return lerp(startValue, endValue, progress);
        }));
        if (timeFraction === 1) {
            if (onComplete)
                onComplete(false);
        }
        else {
            frame = mySetTimeout(loop, 1);
        }
    }, 1);
    var cancel = function () {
        if (frame) {
            myClearTimeout(frame);
            if (onComplete)
                onComplete(true);
        }
    };
    return {
        cancel: cancel,
    };
}
exports.animateWithTimeout = animateWithTimeout;
