"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRange = void 0;
var dateTime_1 = require("./dateTime");
var DateRange = /** @class */ (function () {
    function DateRange(start, end, 
    /** Name is used to distinct two DateRanges that would look identical */
    name) {
        if (name === void 0) { name = "".concat(start.toISO(), "-").concat(end.toISO(), "-").concat(Math.round(Math.random() * 100000)); }
        this.start = start;
        this.end = end;
        this.name = name;
    }
    DateRange.fromTimestamps = function (start, end) {
        return new DateRange((0, dateTime_1.createDateTime)(new Date(start)), (0, dateTime_1.createDateTime)(new Date(end)));
    };
    DateRange.fromDuration = function (start, minutes, _a) {
        var _b = _a === void 0 ? {} : _a, name = _b.name, _c = _b.minMinutes, minMinutes = _c === void 0 ? 0 : _c;
        return new DateRange(start, start.plus({ minutes: Math.max(minMinutes, minutes) }), name);
    };
    Object.defineProperty(DateRange.prototype, "minutes", {
        get: function () {
            return this.end.minutesSince(this.start);
        },
        enumerable: false,
        configurable: true
    });
    DateRange.prototype.extendsEndTo = function (newEnd) {
        return new DateRange(this.start, newEnd, this.name);
    };
    DateRange.prototype.capToDay = function (day) {
        var daysSinceToday = day.daysSince(this.start);
        var start = daysSinceToday > 0 ? day.startOf('day') : this.start;
        var minutes = this.end.diff(this.start, 'minute').minutes;
        return new DateRange(start, start.plus({
            minutes: this.start.remainingMinutesAfterNDays(minutes, daysSinceToday),
        }), this.name);
    };
    DateRange.prototype.overlapsWith = function (other) {
        /**
         * Events DON'T overlap for any of these cases:
         * |---- other ----|  |---- this -----|
         * |---- other ----|------- this -----|
         *
         * |---- this -----|  |---- other ----|
         * |---- this -----|------- other ----|
         */
        return !(this.start.isAfter(other.end) ||
            this.start.equals(other.end) ||
            this.end.isBefore(other.start) ||
            this.end.equals(other.start));
    };
    DateRange.prototype.excludeOverlapWithAll = function (others) {
        return others
            .reduce(function (remainingRanges, excludedRange) {
            return remainingRanges.flatMap(function (r) { return r.excludeOverlapWith(excludedRange); });
        }, [this])
            .filter(function (range) { return range.minutes > 0; });
    };
    DateRange.prototype.excludeOverlapWith = function (other) {
        if (!this.overlapsWith(other)) {
            return [this];
        }
        /**
         * Given:
         *      |------ this -------|
         * |-- other --|
         *
         * Returns:
         *             |-- result --|
         */
        if (other.startsBefore(this)) {
            return [new DateRange(other.end, this.end, this.name)];
        }
        /**
         * Given:
         * |------ this -------|
         *              |-- other --|
         *
         * Returns:
         * |-- result --|
         */
        if (other.endsAfter(this)) {
            return [new DateRange(this.start, other.start, this.name)];
        }
        /**
         * Given:
         * |--------------- this ----------------|
         *              |-- other --|
         *
         * Returns:
         * |-- result --|           |-- result --|
         */
        return [
            new DateRange(this.start, other.start, this.name),
            new DateRange(other.end, this.end, this.name),
        ];
    };
    DateRange.prototype.equals = function (other) {
        return (this.start.equals(other.start) &&
            this.end.equals(other.end) &&
            this.name === other.name);
    };
    DateRange.prototype.startsBefore = function (other) {
        if (!this.start.equals(other.start)) {
            return this.start.isBefore(other.start);
        }
        if (!this.end.equals(other.end)) {
            return this.end.isBefore(other.end);
        }
        return this.name.localeCompare(other.name) < 0;
    };
    DateRange.prototype.endsAfter = function (other) {
        if (!this.end.equals(other.end)) {
            return this.end.isAfter(other.end);
        }
        if (!this.start.equals(other.start)) {
            return this.start.isAfter(other.start);
        }
        return this.name.localeCompare(other.name) < 0;
    };
    DateRange.prototype.toHourString = function () {
        return "".concat(this.start.toFormat('am-pm-hour'), " - ").concat(this.end.toFormat('am-pm-hour'));
    };
    DateRange.prototype.toString = function () {
        return this.name;
    };
    return DateRange;
}());
exports.DateRange = DateRange;
