import { useRouter } from 'next/router'
import * as React from 'react'
import { Dispatch, SetStateAction, useMemo, useState, useEffect } from 'react'

interface TrackerContextProps {
  actions: {
    setUtmContent?: Dispatch<SetStateAction<string | undefined>>
    setUtmCampaign?: Dispatch<SetStateAction<string | undefined>>
    setUtmMedium?: Dispatch<SetStateAction<string | undefined>>
    setUtmSource?: Dispatch<SetStateAction<string | undefined>>
    setUtmTerm?: Dispatch<SetStateAction<string | undefined>>
    setCopyParam?: Dispatch<SetStateAction<string | undefined>>
  }
  values: {
    utmContent?: string
    utmCampaign?: string
    utmMedium?: string
    utmSource?: string
    utmTerm?: string
    copyParam?: string
  }
}

export const TrackerContext = React.createContext<TrackerContextProps>({
  actions: {},
  values: {},
})

const TrackerContextProvider: React.FunctionComponent = ({ children }) => {
  const [utmContent, setUtmContent] = useState<string | undefined>(undefined)
  const [utmCampaign, setUtmCampaign] = useState<string | undefined>(undefined)
  const [utmMedium, setUtmMedium] = useState<string | undefined>(undefined)
  const [utmSource, setUtmSource] = useState<string | undefined>(undefined)
  const [utmTerm, setUtmTerm] = useState<string | undefined>(undefined)
  const [copyParam, setCopyParam] = useState<string | undefined>(undefined)

  const router = useRouter()
  useEffect(() => {
    const params = router.query

    if (params['utm_content']) setUtmContent(params['utm_content'] as string)
    if (params['utm_campaign']) setUtmCampaign(params['utm_campaign'] as string)
    if (params['utm_medium']) setUtmMedium(params['utm_medium'] as string)
    if (params['utm_source']) setUtmSource(params['utm_source'] as string)
    if (params['utm_term']) setUtmTerm(params['utm_term'] as string)
    if (params['copy']) setCopyParam(params['copy'] as string)
  }, [router.query])

  const context: TrackerContextProps = useMemo(() => {
    return {
      values: {
        utmContent,
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        copyParam,
      },
      actions: {
        setUtmContent,
        setUtmCampaign,
        setUtmMedium,
        setUtmSource,
        setUtmTerm,
        setCopyParam,
      },
    }
  }, [
    utmContent,
    setUtmContent,
    utmCampaign,
    setUtmCampaign,
    utmMedium,
    setUtmMedium,
    utmSource,
    setUtmSource,
    utmTerm,
    setUtmTerm,
    copyParam,
    setCopyParam,
  ])

  return (
    <TrackerContext.Provider value={context}>
      {children}
    </TrackerContext.Provider>
  )
}

export default TrackerContextProvider
