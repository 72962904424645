"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchGroup = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var useFetchGroup = function (groupId, options) {
    var _a = (0, react_1.useState)(), group = _a[0], setGroup = _a[1];
    var _b = (0, react_1.useState)([]), members = _b[0], setMembers = _b[1];
    var _c = (0, react_1.useState)([]), admins = _c[0], setAdmins = _c[1];
    var _d = (0, react_1.useState)(false), notFound = _d[0], setNotFound = _d[1];
    (0, react_1.useEffect)(function () {
        if (!groupId)
            return;
        var isMounted = true;
        var unsubscribe = config_1.db
            .doc("groups/".concat(groupId))
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            if (!isMounted)
                return;
            var data = snapshot.data();
            if (data) {
                setGroup(__assign(__assign({}, data), { id: snapshot.id }));
            }
            setNotFound(!snapshot.exists);
        }, function (err) {
            console.error('useFetchGroup (group) subscription error', err);
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [groupId]);
    (0, react_1.useEffect)(function () {
        if (!groupId || !(options === null || options === void 0 ? void 0 : options.includeUsers))
            return;
        var isMounted = true;
        var unsubscribe = config_1.db
            .collection("groups/".concat(groupId, "/group-members"))
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            if (!isMounted)
                return;
            setMembers(snapshot.docs.map(function (d) { return d.data(); }));
        }, function (err) {
            console.error('useFetchGroup (group-members) subscription error', err);
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [groupId, options === null || options === void 0 ? void 0 : options.includeUsers]);
    (0, react_1.useEffect)(function () {
        if (!groupId || !(options === null || options === void 0 ? void 0 : options.includeAdmins))
            return;
        var isMounted = true;
        var unsubscribe = config_1.db
            .collection("groups/".concat(groupId, "/group-admins"))
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            if (!isMounted)
                return;
            setAdmins(snapshot.docs.map(function (d) { return d.data(); }));
        }, function (err) {
            console.error('useFetchGroup (group-admins) subscription error', err);
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [groupId, options === null || options === void 0 ? void 0 : options.includeAdmins]);
    var _e = (0, react_1.useState)([]), currentFlowing = _e[0], setCurrentFlowing = _e[1];
    (0, react_1.useEffect)(function () {
        if (!groupId)
            return;
        return config_1.db
            .collection('users')
            .where('currentFlowhallId', '==', groupId)
            .where('sessionId', '!=', null)
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            setCurrentFlowing(snapshot.docs.map(function (doc) { return doc.data(); }));
        });
    }, [groupId]);
    return { group: group, members: members, admins: admins, notFound: notFound, currentFlowing: currentFlowing };
};
exports.useFetchGroup = useFetchGroup;
