import * as React from 'react'
import Link from 'next/link'

const SharedLink: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = ({ href, ...props }) => {
  if (!href) {
    return <span {...props} />
  }

  if (!href.startsWith('http')) {
    return <Link href={href}>{props.children}</Link>
  }

  return <a href={href} {...props}></a>
}

export default SharedLink
