"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGroupDataSchema = exports.checkGroupIdDataSchema = exports.checkGroupDomainDataSchema = void 0;
var zod_1 = require("zod");
exports.checkGroupDomainDataSchema = zod_1.z.object({
    domainName: zod_1.z.string(),
});
exports.checkGroupIdDataSchema = zod_1.z.object({
    groupId: zod_1.z.string(),
});
exports.createGroupDataSchema = zod_1.z.object({
    groupName: zod_1.z.string(),
    groupId: zod_1.z.string(),
    groupDomainName: zod_1.z.string().optional(),
    coach: zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
        avatarUrl: zod_1.z.string().optional(),
    }),
    description: zod_1.z.string().optional(),
    iconUrl: zod_1.z.string().optional(),
    coverUrl: zod_1.z.string().optional(),
    playerUrl: zod_1.z.string().optional(),
    featureUrl: zod_1.z.string().optional(),
    isPrivate: zod_1.z.boolean().optional(),
});
