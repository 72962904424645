"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffleArray = void 0;
// Fisher-Yates Shuffle.
// From: https://stackoverflow.com/a/2450976/2041964
function shuffleArray(array) {
    var currentIndex = array.length - 1;
    var randomIndex = 0;
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        // And swap it with the current element.
        var temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
        currentIndex--;
    }
    return array;
}
exports.shuffleArray = shuffleArray;
