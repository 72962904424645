"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTaskServiceProviders = exports.getTaskServiceProviders = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var FirebaseContext_1 = require("../FirebaseContext");
// using a Record<> here to force additions later
var taskServiceProviders = {
    Asana: true,
    Linear: true,
    Todoist: true,
};
function getTaskServiceProviders(integrations) {
    return integrations.filter(function (integration) {
        return taskServiceProviders[integration.provider];
    });
}
exports.getTaskServiceProviders = getTaskServiceProviders;
function useTaskServiceProviders() {
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    var _a = (0, react_1.useState)([]), integrations = _a[0], setIntegrations = _a[1];
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        return config_1.db
            .collection("private-users/".concat(currentUser.id, "/integrations"))
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            setIntegrations(getTaskServiceProviders(snapshot.docs.map(function (doc) { return doc.data(); })));
        }, function (e) {
            console.error('Error in useTaskServiceProvidersIntegrations', e);
        });
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return { integrations: integrations };
}
exports.useTaskServiceProviders = useTaskServiceProviders;
