"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.longestStreak = exports.sessionReportToLongestStreak = exports.daysInStreak = exports.sessionReportToStreakDates = exports.MIN_MINUTES_WORKED_FOR_DAY_STREAK = exports.sessionReportToDateAndAmountMap = void 0;
var luxon_1 = require("luxon");
var julianDate_1 = require("./julianDate");
var DATE_SIZE = 4;
var AMOUNT_SIZE = 4;
// A session report is a string which encodes the date and amount flowed by a user on that date
// The date and amount flowed is concatenated together into a single string segment
// Eg. <JulianDate><MinutesWorked><JulianDate><MinutesWorked> ....
// JulianDate is a 4 digit hexadecimal number representing the number of days since unix epoch
// MinutesWorked is a 4 digit hexadecimal number representing the number of minutes worked on that day
// The maximum you can represent in a hexademical four digit number is 0xffff = 65535 in decimal
// The maximum number of minutes in a day is 24 * 60 = 1440, well below the maximum
// the maxium date we can represent is Fri June 06, 2149. Centered can fix this issue at the year 2100 :)
// Duplicated in the backend repo
function sessionReportToDateAndAmountMap(hexReport) {
    var amounts = {};
    var safeHexReport = hexReport !== null && hexReport !== void 0 ? hexReport : '';
    for (var day = 0; day < Math.floor(safeHexReport.length / (DATE_SIZE + AMOUNT_SIZE)); day++) {
        var offset = day * 8;
        // parse the text to an integer using base-16 (i.e. hexadecimal)
        var date = parseInt(safeHexReport.slice(offset, offset + DATE_SIZE), 16);
        var amount = parseInt(safeHexReport.slice(offset + DATE_SIZE, offset + DATE_SIZE + AMOUNT_SIZE), 16);
        amounts[date] = amount;
    }
    return amounts;
}
exports.sessionReportToDateAndAmountMap = sessionReportToDateAndAmountMap;
exports.MIN_MINUTES_WORKED_FOR_DAY_STREAK = 15;
function sessionReportToStreakDates(sessionReport, currentSession) {
    if (currentSession === void 0) { currentSession = {
        date: new Date(),
        minutesWorked: 0,
    }; }
    var result = new Set();
    var fullReport = sessionReportToDateAndAmountMap(sessionReport);
    // Add current session details to report
    var sessionDate = (0, julianDate_1.toJulianDate)(currentSession.date.getTime());
    if (!fullReport[sessionDate]) {
        fullReport[sessionDate] = 0;
    }
    fullReport[sessionDate] += currentSession.minutesWorked;
    // Filter out days that don't meet the minimum minutes worked requirement
    for (var date in fullReport) {
        if (fullReport[date] >= exports.MIN_MINUTES_WORKED_FOR_DAY_STREAK) {
            result.add(Number(date));
        }
    }
    return Array.from(result);
}
exports.sessionReportToStreakDates = sessionReportToStreakDates;
var DaysInStreak = /** @class */ (function () {
    function DaysInStreak(value) {
        this.value = value;
        if (value < 0) {
            console.error("Days in streak must be positive. Received: ".concat(value));
            this.value = 0;
        }
    }
    DaysInStreak.prototype.toString = function () {
        return "".concat(this.value, " ").concat(this.label);
    };
    Object.defineProperty(DaysInStreak.prototype, "label", {
        get: function () {
            return this.value > 1 ? 'days' : 'day';
        },
        enumerable: false,
        configurable: true
    });
    return DaysInStreak;
}());
function daysInStreak(streakDates, startingDate) {
    if (startingDate === void 0) { startingDate = (0, julianDate_1.toJulianDate)(Date.now()); }
    var daysInStreak = 0;
    var sortedDatesDesc = streakDates.sort(function (a, b) { return b - a; });
    var nextDateInStreak = startingDate;
    var startingIndex = sortedDatesDesc.indexOf(startingDate);
    for (var i = startingIndex; i < sortedDatesDesc.length; i++) {
        var date = sortedDatesDesc[i];
        if (isWeekEnd(date))
            continue;
        if (date !== nextDateInStreak)
            break;
        daysInStreak++;
        nextDateInStreak = nextDateToCheck(date);
    }
    return new DaysInStreak(daysInStreak);
}
exports.daysInStreak = daysInStreak;
function nextDateToCheck(date) {
    var result = date - 1;
    if (isWeekEnd(result))
        result--;
    if (isWeekEnd(result))
        result--;
    return result;
}
function isWeekEnd(date) {
    var weekday = luxon_1.DateTime.fromJSDate((0, julianDate_1.julianToJSDate)(date)).weekday;
    return weekday === 6 || weekday === 7;
}
function sessionReportToLongestStreak(sessionReport) {
    return longestStreak(sessionReportToStreakDates(sessionReport));
}
exports.sessionReportToLongestStreak = sessionReportToLongestStreak;
function longestStreak(streakDates) {
    var longestStreak = 0;
    var currentStreak = 0;
    var sortedDatesDesc = streakDates.sort(function (a, b) { return b - a; });
    var nextDateInStreak = sortedDatesDesc[0];
    for (var i = 0; i < sortedDatesDesc.length; i++) {
        var date = sortedDatesDesc[i];
        if (isWeekEnd(date))
            continue;
        if (date !== nextDateInStreak) {
            currentStreak = 1;
            nextDateInStreak = nextDateToCheck(date);
            continue;
        }
        currentStreak++;
        if (currentStreak > longestStreak) {
            longestStreak = currentStreak;
        }
        nextDateInStreak = nextDateToCheck(date);
    }
    return new DaysInStreak(longestStreak);
}
exports.longestStreak = longestStreak;
