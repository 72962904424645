"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.windowSizeEventSchema = void 0;
var zod_1 = require("zod");
exports.windowSizeEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Expand'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Toggle'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Set Always On Top'),
        alwaysOnTop: zod_1.z.boolean(),
    }),
]);
