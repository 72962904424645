"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIsBrowser = void 0;
var browserBundleIds = [
    'com.google.Chrome',
    'com.brave.Browser',
    'com.microsoft.edgemac',
    'com.vivaldi.Vivaldi',
    'com.pushplaylabs.sidekick',
    'company.thebrowser.Browser',
    'brave.exe',
    'chrome.exe',
    'msedge.exe',
    'vivaldi.exe',
    'sidekick.exe',
];
var browserBundleIdsWithSafari = __spreadArray(__spreadArray([], browserBundleIds, true), [
    'com.apple.Safari',
], false);
var checkIsBrowser = function (bundleId, includeSafari) {
    if (includeSafari === void 0) { includeSafari = true; }
    return Boolean(bundleId &&
        (includeSafari ? browserBundleIdsWithSafari : browserBundleIds).some(function (id) { return id === bundleId; }));
};
exports.checkIsBrowser = checkIsBrowser;
