import Cookies, { CookieAttributes } from 'js-cookie'

export interface Token {
  readonly refreshToken: string
  readonly accessToken: string
}

// 30 days: https://github.com/js-cookie/js-cookie#expires
export const cookieArguments: CookieAttributes = {
  expires: 30,
  secure: process.env.NODE_ENV !== 'development',
  sameSite: 'strict',
}

export function saveToken(token: Token | undefined): void {
  if (token === undefined) {
    Cookies.remove('CenteredRefreshToken')
    Cookies.remove('CenteredAccessToken')
  } else {
    Cookies.set('CenteredRefreshToken', token.refreshToken, cookieArguments)
    Cookies.set('CenteredAccessToken', token.accessToken, cookieArguments)
  }
}

export function loadToken(): Token | undefined {
  const refreshToken = Cookies.get('CenteredRefreshToken')
  const accessToken = Cookies.get('CenteredAccessToken')
  if (refreshToken === 'undefined' || accessToken === 'undefined') {
    saveToken(undefined)
    return undefined
  }
  if (refreshToken && accessToken) {
    return {
      refreshToken,
      accessToken,
    }
  }
  return undefined
}

export function isAuthenticated(): boolean {
  const token = loadToken()
  return !!token
}
