"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberToOrdinal = void 0;
function numberToOrdinal(n) {
    var enOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
    var suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th',
        many: 'th',
        zero: 'th',
    };
    var rule = enOrdinalRules.select(n);
    var suffix = suffixes[rule];
    return "".concat(n).concat(suffix);
}
exports.numberToOrdinal = numberToOrdinal;
