"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeScanner = exports.isValidTime = void 0;
var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
var secondsUnit = ['SEC', 'SECOND', 'SECONDS', 'SECS', 'S'];
var minutesUnit = ['MIN', 'MINUTE', 'MINUTES', 'MINS', 'M'];
var hoursUnit = ['HOURS', 'HOUR', 'H', 'HR'];
var colonFormat = /(\d+):(\d+)(:(\d+))?/;
function isValidTime(timeString) {
    // Time scanner will fallback on 0, which isn't valid unless we received 0
    return (timeScanner(timeString) !== 0 || timeString === '0' || timeString === '0s');
}
exports.isValidTime = isValidTime;
function timeScanner(timeString) {
    if (timeString.length === 0) {
        return 0;
    }
    var colonFormatMatches = timeString.match(colonFormat);
    if (colonFormatMatches) {
        var match1 = colonFormatMatches[1], match2 = colonFormatMatches[2], hasSeconds = colonFormatMatches[3], match3 = colonFormatMatches[4];
        var hours = Number.parseInt(match1);
        var minutes = Number.parseInt(match2);
        var seconds_1 = Number.parseInt(hasSeconds ? match3 : '0');
        return hours * 3600 + minutes * 60 + seconds_1;
    }
    var seconds = 0;
    var isScanningNumber = false;
    var currentScanningNumber = '';
    var hasProcessedMinutes = false;
    var currentNumber = 0;
    var pos = 0;
    var jumpToPos = 0;
    var scanString = timeString.toUpperCase().replace(/\s/g, '');
    for (var _i = 0, _a = scanString.split(''); _i < _a.length; _i++) {
        var character = _a[_i];
        if (pos < jumpToPos) {
            pos += 1;
            continue;
        }
        if (numbers.includes(character)) {
            isScanningNumber = true;
            currentScanningNumber += character;
        }
        else if (isScanningNumber) {
            currentNumber = Number.parseFloat(currentScanningNumber);
            currentScanningNumber = '';
            isScanningNumber = false;
        }
        else {
            // If we aren't scanning a number AND we aren't about to scan one
            // => we are scanning random characters after the unit (invalid)
            return 0;
        }
        var remainingString = scanString.substr(pos);
        var hasSeconds = hasUnit(remainingString, secondsUnit);
        jumpToPos = posAfterScan(pos, jumpToPos, remainingString, secondsUnit);
        if (hasSeconds && currentNumber > 0) {
            seconds += currentNumber;
        }
        var hasMinutes = hasUnit(remainingString, minutesUnit);
        jumpToPos = posAfterScan(pos, jumpToPos, remainingString, minutesUnit);
        if (hasMinutes && currentNumber > 0) {
            seconds += currentNumber * 60;
            hasProcessedMinutes = true;
        }
        var hasHours = hasUnit(remainingString, hoursUnit);
        jumpToPos = posAfterScan(pos, jumpToPos, remainingString, hoursUnit);
        if (hasHours && currentNumber > 0) {
            seconds += currentNumber * 60 * 60;
        }
        pos += 1;
    }
    var remainingTime = Number.parseInt(currentScanningNumber);
    if (seconds === 0 && Number.isNaN(remainingTime)) {
        return 0;
    }
    if (remainingTime > 0) {
        // Consider these are minutes, unless we already processed these
        // Then, consider these are seconds
        seconds += hasProcessedMinutes ? remainingTime : remainingTime * 60;
    }
    return seconds;
}
exports.timeScanner = timeScanner;
function hasUnit(remainingString, units) {
    var hasUnit = false;
    for (var _i = 0, units_1 = units; _i < units_1.length; _i++) {
        var unit = units_1[_i];
        if (remainingString.startsWith(unit)) {
            hasUnit = true;
        }
    }
    return hasUnit;
}
function posAfterScan(initialPos, currentPos, remainingString, units) {
    var posAfterScan = currentPos;
    for (var _i = 0, units_2 = units; _i < units_2.length; _i++) {
        var unit = units_2[_i];
        if (remainingString.startsWith(unit)) {
            var nextJump = initialPos + unit.length;
            if (posAfterScan < nextJump) {
                posAfterScan = nextJump;
            }
        }
    }
    return posAfterScan;
}
