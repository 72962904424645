"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainMachineEventSchema = void 0;
var zod_1 = require("zod");
exports.mainMachineEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Open Settings'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Start Session'),
        workingSince: zod_1.z.number().optional(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Start Buddy Session'),
        sessionId: zod_1.z.string(),
    }),
]);
