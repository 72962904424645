"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateSocialMediaShareUrl = void 0;
var generateSocialMediaShareUrl = function (socialPlatform, url) {
    switch (socialPlatform) {
        case 'twitter':
            return "https://twitter.com/intent/tweet?text=".concat(encodeURIComponent(url));
        case 'linkedin':
            return "https://www.linkedin.com/sharing/share-offsite/?url=".concat(encodeURI(url));
        case 'facebook':
            return "https://www.facebook.com/sharer/sharer.php?u=".concat(encodeURIComponent(url));
        default:
            console.error('generateSocialMediaShareUrl called with invalid social media site.');
            return '';
    }
};
exports.generateSocialMediaShareUrl = generateSocialMediaShareUrl;
