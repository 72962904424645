"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.slug = void 0;
var slug = function (value) {
    return value
        .toLowerCase()
        .replace(/[^a-z0-9- ]+/g, '')
        .replace(/ +/g, '-');
};
exports.slug = slug;
