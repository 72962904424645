"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOS = void 0;
var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
function getOS() {
    var userAgent = window.navigator.userAgent;
    var platform = window.navigator.platform;
    if (macosPlatforms.includes(platform)) {
        return 'macOS';
    }
    if (iosPlatforms.includes(platform)) {
        return 'iOS';
    }
    if (windowsPlatforms.includes(platform)) {
        return 'Windows';
    }
    if (/Android/.test(userAgent)) {
        return 'Android';
    }
    if (/Linux/.test(platform)) {
        return 'Linux';
    }
}
exports.getOS = getOS;
