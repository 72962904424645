"use strict";
// This is where you add and remove coach messages.
//
// The only exception is that the *distraction* message definitions
// live in coachMachine.tsx.
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.noahCoachMessages = void 0;
var COMPLETED = [
    { name: "You did it.", audioUrl: 'Play_004' },
    { name: "Great job!", audioUrl: 'Play_005' },
    { name: "Nice work!", audioUrl: 'Play_006' },
    { name: "Perfect!", audioUrl: 'Play_007' },
    { name: "Well done!", audioUrl: 'Play_008' },
    {
        name: "Let yourself feel a sense of accomplishment.",
        audioUrl: 'Play_017',
    },
    { name: "Congratulations!", audioUrl: 'Play_021' },
    {
        name: "Give yourself some gratitude for the work you did.",
        audioUrl: 'Breath_012',
    },
    {
        name: "Noah here! I'm proud of you for getting your work done. Keep it up!",
        audioUrl: 'Encouragement_010',
    },
    {
        name: "May The Flow be with you\u2026 always.",
        audioUrl: 'Encouragement_016',
    },
    { name: "There you go!", audioUrl: 'Encouragement_024' },
    { name: "I\u2019m so proud of you.", audioUrl: 'Encouragement_025' },
    {
        audioUrl: 'Play_039',
        name: 'Doesn’t it feel great getting your work done faster?',
    },
    {
        audioUrl: 'Play_040',
        name: 'Isn’t it rewarding seeing how much you get done while in Flow?',
    },
    { audioUrl: 'Play_041', name: 'Your focus is paying off.' },
];
var INCOMING_MEETING = [
    {
        name: "You have a meeting coming up.",
        audioUrl: 'MeetingNotify_001',
    },
    {
        name: "We need to wrap up this current Task. You have a meeting soon.",
        audioUrl: 'MeetingNotify_003',
    },
    {
        name: "Let's wrap up this Task so you have time to prep for your next meeting.",
        audioUrl: 'MeetingNotify_004',
    },
    {
        audioUrl: 'MeetingNotify_005',
        name: 'Guess who has a meeting coming up?',
    },
    {
        audioUrl: 'MeetingNotify_006',
        name: "Don't keep your next meeting waiting...better start wrapping up. ",
    },
    {
        audioUrl: 'MeetingNotify_007',
        name: "Now's the time if you want to grab some water and a snack before your next meeting.",
    },
];
exports.noahCoachMessages = {
    BREAK: [{ name: "Take a break.", audioUrl: 'Play_009' }],
    BREAK_FOLLOW_UP: [
        {
            name: "Give yourself some gratitude for the work you did.",
            audioUrl: 'Breath_012',
        },
        {
            name: "Take a moment to focus your eyes on the distance away from your screen.",
            audioUrl: 'Breath_002',
        },
        {
            name: "Take a few deep breaths and focus your mind. Then keep Flowing.",
            audioUrl: 'Break_027',
        },
        {
            name: "Drink some water and give your eyes a break before refocusing on your next Task.",
            audioUrl: 'Break_028',
        },
        { name: "Give your eyes a rest.", audioUrl: 'Breath_003' },
        { name: "Focus your eyes on something far away.", audioUrl: 'Breath_004' },
        { name: "Look away from your screen.", audioUrl: 'Breath_005' },
        {
            audioUrl: 'Breath_026',
            name: 'This break is a great opportunity to enjoy a breathing exercise.',
        },
        {
            audioUrl: 'Breath_027',
            name: "Now is a great time to take a few deep breaths.  Start the breathing exercise and let's get centered together.",
        },
    ],
    COMPLETED: COMPLETED,
    COMPLETED_AHEAD_OF_SCHEDULE: __spreadArray(__spreadArray([], COMPLETED, true), [
        {
            name: "Give yourself a pat on the back for finishing ahead of schedule!",
            audioUrl: 'Play_014',
        },
        { name: "You finished ahead of schedule!", audioUrl: 'Play_024' },
    ], false),
    COMPLETED_CODING_SESSION: [
        { name: "I hope your coding session was fruitful.", audioUrl: 'Play_034' },
        {
            audioUrl: 'EOS_021',
            name: "Congrats on pushing out those PR's",
        },
        {
            audioUrl: 'EOS_022',
            name: 'Look at you cranking out that code!  Well done.',
        },
    ],
    COMPLETED_DESIGN_SESSION: [
        { name: "Congrats on your latest design session.", audioUrl: 'Play_033' },
    ],
    COMPLETED_STUDY_SESSION: [
        {
            audioUrl: 'EOS_012',
            name: 'Congratulations on a wonderful study session.',
        },
        {
            audioUrl: 'EOS_013',
            name: 'Congrats on knocking out your studies.',
        },
        {
            audioUrl: 'EOS_014',
            name: 'Wow, look how much smarter you are now!',
        },
        {
            audioUrl: 'EOS_015',
            name: 'Ahhh...reminds me of when I was a young student.  Great Job on your studies.',
        },
    ],
    COMPLETED_WRITING_SESSION: [
        {
            audioUrl: 'EOS_016',
            name: 'Congratulations on another great writing session.',
        },
        {
            audioUrl: 'EOS_017',
            name: 'Wow, how many words was that?  Great job!',
        },
        {
            audioUrl: 'EOS_018',
            name: "Look at how well it's coming together!  Congrats on that writing session.",
        },
    ],
    COMPLETED_EMAIL_SESSION: [
        {
            audioUrl: 'EOS_019',
            name: 'Congrats on knocking out those emails.',
        },
        {
            audioUrl: 'EOS_020',
            name: 'Well done on clearing out your inbox!',
        },
    ],
    CONTINUE_FLOW: [
        {
            name: "Welcome back!",
            audioUrl: 'Break_010',
            removePreviousMessages: true,
        },
        {
            name: "Welcome back to your Task.",
            audioUrl: 'Break_011',
            removePreviousMessages: true,
        },
        {
            name: "Welcome back to Flow.",
            audioUrl: 'Break_012',
            removePreviousMessages: true,
        },
    ],
    URL_SHARED: [
        {
            name: "I copied a link into your clipboard so you can share it with your friends!",
            removePreviousMessages: true,
            audioUrl: 'Intro_022',
        },
    ],
    BUDDY_SESSION_URL_SHARED: [
        {
            name: "I copied a link into your clipboard so you can invite your friends to this Session!",
            removePreviousMessages: true,
        },
    ],
    ENCOURAGEMENT: [
        { name: 'Your focus is paying off.', audioUrl: 'Play_041' },
        { name: 'Keep Flowing. You can do it.', audioUrl: 'DesktopDistract_012' },
        {
            name: "You've already come so far. Keep up your focus.",
            audioUrl: 'DesktopDistract_014',
        },
        {
            name: "You're doing great. Keep going.",
            audioUrl: 'DesktopDistract_016',
        },
        {
            name: "You're doing so great. Don't stop now.",
            audioUrl: 'DesktopDistract_037',
        },
        {
            name: "You've already come so far. Keep up your focus.",
            audioUrl: 'DesktopDistract_039',
        },
        { name: 'Keep it up!', audioUrl: 'Encouragement_023' },
        { name: "I'm so proud of you!", audioUrl: 'Encouragement_025' },
        {
            name: 'Try not. Do or do not. There is no try.',
            audioUrl: 'PhoneDistract_009',
        },
        { name: 'Stay Intentional.', audioUrl: 'PhoneDistract_017' },
    ],
    END_OF_BREAK: [
        {
            name: "Your break is almost over. Let's get ready to Flow.",
            audioUrl: 'Break_018',
        },
        {
            name: "If you haven't already made your way back to your workspace, get ready to Flow again.",
            audioUrl: 'Break_020',
        },
    ],
    END_OF_FLOW: [
        {
            name: 'Your break is coming up.  Begin to come out of Flow.',
            audioUrl: 'Break_017',
        },
    ],
    END_OF_SESSION: [
        {
            name: 'Great Session!',
            audioUrl: 'EOS_001',
        },
        {
            name: 'Congratulations on another great Session.',
            audioUrl: 'EOS_002',
        },
        {
            name: "Great Session, now let's check out your Productivity Score!",
            audioUrl: 'EOS_003',
        },
        {
            name: "Well done! Doesn't it feel great to get your work done.",
            audioUrl: 'EOS_004',
        },
        {
            name: 'Look at you, getting all your work done.',
            audioUrl: 'EOS_005',
        },
        {
            name: "Great job, Give yourself a pat on the back for all the work you've completed.",
            audioUrl: 'EOS_006',
        },
        {
            name: 'Well done, now feel that sense of accomplishment.',
            audioUrl: 'EOS_007',
        },
        {
            name: "Great job! Doesn't it feel good to get some work done?",
            audioUrl: 'EOS_008',
        },
        {
            name: "Another session completed! I'm proud of you!",
            audioUrl: 'EOS_009',
        },
        {
            name: 'The Force is strong in this one.',
            audioUrl: 'EOS_010',
        },
        {
            name: 'Incredible, another session in the bag!',
            audioUrl: 'EOS_011',
        },
    ],
    FIRST_BREAK: [
        {
            name: "Breaks are a great way to take your mind off your work for a moment. Now, enjoy your break!",
            audioUrl: 'Break_022',
        },
    ],
    FIRST_FLOW: [
        {
            name: "Hi I'm Noah.  I'll be your virtual coach to help you get into your Flow State and keep you there.  Sometimes I'll chime in to remind you about your current Task or cheer you on when you complete a Task.",
            audioUrl: 'Intro_009',
            removePreviousMessages: true,
        },
    ],
    FLOW: [
        {
            name: "Ready to dive in?",
            audioUrl: 'Intro_002',
            removePreviousMessages: true,
        },
        {
            name: "Let\u2019s start.",
            audioUrl: 'Intro_003',
            removePreviousMessages: true,
        },
        {
            name: "Let\u2019s Flow.",
            audioUrl: 'Intro_004',
            removePreviousMessages: true,
        },
        {
            name: "Let\u2019s begin.",
            audioUrl: 'Intro_005',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_013',
            name: 'Ready?  Let’s go.',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_014',
            name: 'Ready to dive in?  Let’s get Flowing.',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_015',
            name: 'Are you ready for a great Sukha Session?  Let’s go.',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_016',
            name: 'Are you ready to Flow?  Let’s get to it.',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_017',
            name: 'Let’s make this a great session.  Together.',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_018',
            name: 'Let’s have a great Flow session together.',
            removePreviousMessages: true,
        },
        {
            audioUrl: 'Intro_019',
            name: 'You ready?  Let’s get our Flow on.',
            removePreviousMessages: true,
        },
    ],
    HALFWAY: [
        {
            name: 'You’re now halfway through the allocated time.',
            audioUrl: 'Task_001',
        },
        {
            name: 'You’re halfway through.',
            audioUrl: 'Task_002',
        },
        {
            name: 'That’s halfway.',
            audioUrl: 'Task_003',
        },
        {
            name: "You're halfway through this Task.",
            audioUrl: 'Task_075',
        },
    ],
    INCOMING_MEETING: INCOMING_MEETING,
    INCOMING_MEETING_IN_1: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in one minute.",
            audioUrl: 'MeetingNotify_002_1',
        },
    ], false),
    INCOMING_MEETING_IN_2: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in two minutes.",
            audioUrl: 'MeetingNotify_002_2',
        },
    ], false),
    INCOMING_MEETING_IN_3: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in three minutes.",
            audioUrl: 'MeetingNotify_002_3',
        },
    ], false),
    INCOMING_MEETING_IN_4: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in four minutes.",
            audioUrl: 'MeetingNotify_002_4',
        },
    ], false),
    INCOMING_MEETING_IN_5: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in five minutes.",
            audioUrl: 'MeetingNotify_002_5',
        },
    ], false),
    INCOMING_MEETING_IN_10: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in ten minutes.",
            audioUrl: 'MeetingNotify_002_10',
        },
    ], false),
    INCOMING_MEETING_IN_15: __spreadArray(__spreadArray([], INCOMING_MEETING, true), [
        {
            name: "You have a meeting coming up in fifteen minutes.",
            audioUrl: 'MeetingNotify_002_15',
        },
    ], false),
    MARK_AS_DISTRACTION: [
        {
            name: 'Ok, I’ll keep bugging you about this.',
            removePreviousMessages: true,
            audioUrl: 'DesktopDistract_041',
        },
    ],
    MARK_AS_PRODUCTIVE: [
        {
            name: 'Got it.  I’ll stop bugging you until the end of this Task.',
            removePreviousMessages: true,
            audioUrl: 'DesktopDistract_043',
        },
    ],
    OVER_SCHEDULED_TIME: [
        {
            name: "Time's up",
            audioUrl: 'Task_015',
        },
        {
            name: "You\u2019re now over the scheduled time.",
            audioUrl: 'Task_016',
        },
        {
            name: "Over scheduled time.",
            audioUrl: 'Task_017',
        },
    ],
    PRODUCTIVITY_SCORE: [
        {
            name: "Now, let's check out your Productivity Score",
            audioUrl: 'PRS_001',
        },
    ],
    PRODUCTIVITY_SCORE_LOW: [
        {
            name: "Learning to focus can be a challenge, I'm here to help. Click your Score to learn more.",
            audioUrl: 'PRS_002',
        },
        {
            name: 'Practice makes perfect. Click your Score to learn more.',
            audioUrl: 'PRS_003',
        },
        {
            name: 'That was good, and with a little help, we can do great.  Click your Score to learn more. ',
            audioUrl: 'PRS_004',
        },
        {
            name: 'There are many ways to boost your productivity, I can help.  Click your Score to learn more.',
            audioUrl: 'PRS_005',
        },
    ],
    PRODUCTIVITY_SCORE_MEDIUM: [
        {
            name: "Now that's a respectable Score.  Keep up the great work.",
            audioUrl: 'PRS_006',
        },
        {
            name: "You're on your way to Mastery.  I'm glad that we're here together.",
            audioUrl: 'PRS_007',
        },
        {
            name: 'Nice score.  You should be proud of your progress.',
            audioUrl: 'PRS_008',
        },
        { name: "You're getting there - I'm proud of you.", audioUrl: 'PRS_009' },
    ],
    PRODUCTIVITY_SCORE_HIGH: [
        {
            name: 'Would you look at that Productivity Score. Amazing!',
            audioUrl: 'PRS_010',
        },
        {
            name: 'This score is cause for Celebrating!  Congratulations!',
            audioUrl: 'PRS_011',
        },
        {
            name: "Your master is inspiring to others!  I couldn't be more proud of you.",
            audioUrl: 'PRS_012',
        },
        {
            name: 'Wow.  It looks like the student has become the master.',
            audioUrl: 'PRS_013',
        },
    ],
    PRODUCTIVITY_SCORE_APP_TRACKING: [
        {
            name: 'Looks like you might have been a bit distracted.  I can help that, just turn on App Tracking in your settings.',
            audioUrl: 'PRS_014',
        },
        {
            name: "I can tell distractions are affecting your score.  Turn on App Tracking and I'll nudge you when you get distracted.",
            audioUrl: 'PRS_015',
        },
        {
            name: "Looks like certain apps may be pulling you out of your Flow State.  If you turn on App Tracking, I'll gently keep you on track.",
            audioUrl: 'PRS_016',
        },
    ],
    PRODUCTIVITY_SCORE_DND: [
        {
            name: 'I bet you can improve your Score if you turn on Do Not Disturb in Settings.  It will allow you to focus without distractions.',
            audioUrl: 'PRS_017',
        },
        {
            name: 'Do Not Disturb mode will block your distractions and help you finish your work more quickly.  Would like to try that?',
            audioUrl: 'PRS_018',
        },
    ],
    REMAINING_1: [
        {
            name: "There is now one minute remaining.",
            audioUrl: 'Task_013',
        },
        {
            name: "Just one more minute.",
            audioUrl: 'Task_014',
        },
    ],
    REMAINING_2: [
        {
            name: "There are now two minutes remaining.",
            audioUrl: 'Task_010',
        },
        {
            name: "Two minutes left.",
            audioUrl: 'Task_011',
        },
        {
            name: "Just two minutes.",
            audioUrl: 'Task_012',
        },
    ],
    REMAINING_5: [
        {
            name: "Five minutes left.",
            audioUrl: 'Task_008',
        },
        {
            name: "Five minutes.",
            audioUrl: 'Task_009',
        },
    ],
    REMAINING_10: [
        {
            name: "Ten minutes remaining",
            audioUrl: 'Task_005',
        },
        {
            name: 'There are now ten minutes of allocated time remaining.',
            audioUrl: 'Task_004',
        },
        {
            name: "Just ten minutes to go.",
            audioUrl: 'Task_006',
        },
    ],
    DESKTOP_DISTRACTION: [
        {
            name: 'I sense a disturbance in The Force.',
            audioUrl: 'DesktopDistract_001',
        },
        {
            name: "I'm guessing you got distracted...",
            audioUrl: 'DesktopDistract_002',
        },
        {
            name: 'Are you still on Task?  Stay focused.',
            audioUrl: 'DesktopDistract_003',
        },
        {
            name: "You're doing great.  Keep going.",
            audioUrl: 'DesktopDistract_016',
        },
        {
            name: 'Is your mind wandering?  Re-center yourself.',
            audioUrl: 'DesktopDistract_004',
        },
        { name: 'Find your Flow again.', audioUrl: 'DesktopDistract_005' },
        {
            name: "Are you still in Flow?  Don't get distracted.",
            audioUrl: 'DesktopDistract_006',
        },
        {
            name: 'Stay on track.  Get it done.',
            audioUrl: 'DesktopDistract_008',
        },
        {
            name: 'Are you focused?  Stay present.',
            audioUrl: 'DesktopDistract_009',
        },
        {
            name: "Keep Flowing.  You're almost there.",
            audioUrl: 'DesktopDistract_010',
        },
        {
            name: 'Keep your head in the game.',
            audioUrl: 'DesktopDistract_011',
        },
        {
            name: 'Keep Flowing.  You can do it.',
            audioUrl: 'DesktopDistract_012',
        },
        {
            name: "You're stronger than your distractions.  Don't give in.",
            audioUrl: 'DesktopDistract_013',
        },
        {
            name: 'Stay mindful...focused...and in Flow',
            audioUrl: 'DesktopDistract_014',
        },
        {
            name: 'Shift your focus back to your work.',
            audioUrl: 'DesktopDistract_015',
        },
        {
            name: "Don't let distractions claim your attention.",
            audioUrl: 'DesktopDistract_017',
        },
        { name: 'Find your Flow again.', audioUrl: 'DesktopDistract_019' },
        {
            name: "I've got a feeling we're not in Flow anymore.",
            audioUrl: 'DesktopDistract_022',
        },
        { name: 'Come back to your Task.', audioUrl: 'DesktopDistract_027' },
        {
            name: 'Are you getting distracted?',
            audioUrl: 'DesktopDistract_028',
        },
        { name: 'Let’s get back to work.', audioUrl: 'DesktopDistract_029' },
        {
            name: 'This app doesn’t seem productive.',
            audioUrl: 'DesktopDistract_030',
        },
        {
            name: "Not so fast, my friend.  You've got a few more minutes to focus.",
            audioUrl: 'DesktopDistract_036',
        },
        {
            name: "You're doing so great.  Don't stop now.  (alt: \"You're doing great.  Don't stop now.\")",
            audioUrl: 'DesktopDistract_037',
        },
        {
            name: "Where do you think YOU'RE going?   (alt: \"Where do you think you're GOING?\")",
            audioUrl: 'DesktopDistract_038',
        },
        {
            name: "You've already come so far.  Keep up your focus.",
            audioUrl: 'DesktopDistract_039',
        },
        {
            name: "Commit to your Flow.  You've got this.",
            audioUrl: 'DesktopDistract_040',
        },
        { name: 'Are you still focused?', audioUrl: 'Task_048' },
        {
            name: 'Are you still on Task? We think your focus may have shifted.',
            audioUrl: 'Task_049',
        },
        {
            name: 'Are you still on Task? Your focus may have shifted.',
            audioUrl: 'Task_050',
        },
    ],
    PHONE_DISTRACTION: [
        {
            name: 'Are you sure you want to touch that phone?',
            audioUrl: 'PhoneDistract_001',
        },
        {
            name: 'Do you really want to check you texts right now?',
            audioUrl: 'PhoneDistract_003',
        },
        {
            name: 'Why let a cellphone destroy your Flow?',
            audioUrl: 'PhoneDistract_004',
        },
        {
            name: "Don't Text and Flow!",
            audioUrl: 'PhoneDistract_005',
        },
        {
            name: "What if you didn't check your phone right now?",
            audioUrl: 'PhoneDistract_006',
        },
        {
            name: "Friends don't let friends text and Flow.",
            audioUrl: 'PhoneDistract_007',
        },
        {
            audioUrl: 'PhoneDistract_009',
            name: 'Try not. Do or do not. There is no try.',
        },
        {
            audioUrl: 'PhoneDistract_010',
            name: 'Hey you.  It’s your computer.  Where do you think you’re going?',
        },
        {
            audioUrl: 'PhoneDistract_011',
            name: 'I hope that phone is part of your Task.',
        },
        {
            audioUrl: 'PhoneDistract_012',
            name: 'Uh uh uh… Are you sure you want to break your Flow?  You’re doing so well!',
        },
        {
            audioUrl: 'PhoneDistract_013',
            name: "Wouldn't it be better to just let your phone charge?  Let's get back to work!",
        },
        {
            audioUrl: 'PhoneDistract_016',
            name: "Do you need help searching for something?  Just kidding, let's get back on Task.",
        },
        {
            audioUrl: 'PhoneDistract_017',
            name: 'Stay... intentional.',
        },
        {
            audioUrl: 'PhoneDistract_019',
            name: 'Stop letting your phone dictate your day.',
        },
        {
            audioUrl: 'PhoneDistract_020',
            name: 'Ye better be a good pirate and leave that phone off the hook.  Get back to work Matey!',
        },
        {
            audioUrl: 'PhoneDistract_021',
            name: 'Hey, your Tasks called, they want your attention back.',
        },
    ],
    IDLE_MESSAGES: [
        { name: 'Still working? Let Sukha know.', audioUrl: 'Break_006' },
        {
            name: 'We noticed you probably stepped away.',
            audioUrl: 'Break_009',
        },
        {
            name: 'It looks like you stepped away from your computer.',
            audioUrl: 'Break_016',
        },
    ],
};
