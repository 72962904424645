"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var manageFriends_1 = require("../helpers/manageFriends");
var react_1 = require("react");
var analytics_1 = require("@centered/analytics");
var FirebaseContext_1 = require("../FirebaseContext");
function useFollowing() {
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    var unfollow = (0, react_1.useCallback)(function (id) {
        if (!currentUser)
            return Promise.resolve();
        (0, analytics_1.trackEvent)('UnfollowedUser', { userId: id });
        return (0, manageFriends_1.unfollowFriend)(currentUser, id);
    }, [currentUser]);
    var follow = (0, react_1.useCallback)(function (connection) {
        if (!currentUser)
            return Promise.resolve();
        (0, analytics_1.trackEvent)('FollowedUser', { userId: connection.id });
        return (0, manageFriends_1.followFriend)(currentUser, connection);
    }, [currentUser]);
    return {
        unfollow: unfollow,
        follow: follow,
    };
}
exports.default = useFollowing;
