"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchPomodoroCycles = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var luxon_1 = require("luxon");
function useFetchPomodoroCycles(userId, yearNumber, weekNumber) {
    var _a = (0, react_1.useState)({}), pomodoroCycles = _a[0], setPomodoroCycles = _a[1];
    var _b = (0, react_1.useState)(true), loading = _b[0], setLoading = _b[1];
    (0, react_1.useEffect)(function () {
        if (!userId)
            return;
        setLoading(true);
        var id = luxon_1.DateTime.fromObject({
            weekYear: yearNumber,
            weekNumber: weekNumber,
        }).toFormat("yyyy-'W'WW");
        var path = "users/".concat(userId, "/pomodoro-cycles/").concat(id);
        config_1.db.doc(path)
            .get()
            .then(function (snapshot) {
            if (snapshot.exists) {
                setPomodoroCycles(snapshot.data());
            }
            else {
                setPomodoroCycles({});
            }
            setLoading(false);
        })
            .catch(function (err) {
            console.error('Error fetching pomodoroCycles', err);
        });
    }, [yearNumber, weekNumber, userId]);
    return {
        loading: loading,
        pomodoroCycles: pomodoroCycles,
    };
}
exports.useFetchPomodoroCycles = useFetchPomodoroCycles;
