"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTaskEstimate = void 0;
var timeToString_1 = require("./timeToString");
function calculateTaskEstimate(task, isFlowingOnThisTask) {
    var _a;
    // Only consider the flowed time work for this task.
    var timeSinceWorkStarted = isFlowingOnThisTask && task.lastWorkStartedAt
        ? (Date.now() - task.lastWorkStartedAt.getTime()) / 1000
        : 0;
    var timeWorked = ((_a = task.timeWorked) !== null && _a !== void 0 ? _a : 0) + timeSinceWorkStarted;
    return {
        seconds: task.timeEstimated - timeWorked,
        showSeconds: Boolean(isFlowingOnThisTask && task.activeTaskSince),
        timeWorked: timeWorked,
        tooltip: generateTooltip(task, timeWorked),
    };
}
exports.calculateTaskEstimate = calculateTaskEstimate;
var generateTooltip = function (task, timeWorked) {
    if (typeof timeWorked === 'number') {
        if (timeWorked === 0) {
            return "You haven't started this Task yet";
        }
        else if (timeWorked >= task.timeEstimated) {
            return 'You are over your time estimate';
        }
        else {
            // Since we don't show the seconds, prefer to show "2m" instead of "1m"
            // when we have spent more than 1m already.
            // Otherwise, we'd show "8m" remaining time and "1m" spent on a "10m" task
            var ceiledTimeWorked = Math.ceil(timeWorked / 60) * 60;
            return "Time spent ".concat((0, timeToString_1.timeToShortString)(ceiledTimeWorked, {
                showSecondsWithMins: false,
            }));
        }
    }
};
