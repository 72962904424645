"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitDurationIntoNumberAndUnitComponent = void 0;
function splitDurationIntoNumberAndUnitComponent(durationString) {
    return durationString.split(' ').map(function (segments) {
        var components = segments
            .split(/(\d+)/g)
            .filter(function (component) { return component !== ''; });
        return {
            duration: Number(components[0]),
            unit: components[1],
        };
    });
}
exports.splitDurationIntoNumberAndUnitComponent = splitDurationIntoNumberAndUnitComponent;
