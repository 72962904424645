"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coachMessageEventSchema = void 0;
var zod_1 = require("zod");
exports.coachMessageEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Dismiss Coach Message'),
        messageId: zod_1.z.string(),
        stopAudio: zod_1.z.boolean().optional(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Mark As Distraction'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Mark As Productive'),
        bundleId: zod_1.z.string(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Await Dismiss'),
    }),
]);
