"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDateTime = exports.DateTime = exports.MINUTES_IN_A_DAY = void 0;
var luxon_1 = require("luxon");
var julianDate_1 = require("./julianDate");
exports.MINUTES_IN_A_DAY = 24 * 60;
var RELATIVE_UNITS = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
];
/**
 * We created our custom `DateTime` so we can control its API.
 *
 * It may use a 3rd-party library under the hood to make it work, that doesn't matter.
 * We started implementing it following [Luxon's API](https://moment.github.io/luxon) because we were familiar with Moment.js.
 */
var DateTime = /** @class */ (function () {
    function DateTime(_value) {
        this._value = _value;
    }
    DateTime.fromLuxon = function (value) {
        return new DateTime(value);
    };
    DateTime.now = function () {
        return DateTime.fromLuxon(luxon_1.DateTime.now());
    };
    /**
     * Create a local DateTime
     * @param [year] - The calendar year. If omitted (as in, call `local()` with no arguments), the current time will be used
     * @param [month=1] - The month, 1-indexed
     * @param [day=1] - The day of the month, 1-indexed
     * @param [hour=0] - The hour of the day, in 24-hour time
     * @param [minute=0] - The minute of the hour, meaning a number between 0 and 59
     * @param [second=0] - The second of the minute, meaning a number between 0 and 59
     * @param [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
     * @example
     * DateTime.local()                            //~> now
     * @example
     * DateTime.local(2017)                        //~> 2017-01-01T00:00:00
     * @example
     * DateTime.local(2017, 3)                     //~> 2017-03-01T00:00:00
     * @example
     * DateTime.local(2017, 3, 12)                 //~> 2017-03-12T00:00:00
     * @example
     * DateTime.local(2017, 3, 12, 5)              //~> 2017-03-12T05:00:00
     * @example
     * DateTime.local(2017, 3, 12, 5, 45)          //~> 2017-03-12T05:45:00
     * @example
     * DateTime.local(2017, 3, 12, 5, 45, 10)      //~> 2017-03-12T05:45:10
     * @example
     * DateTime.local(2017, 3, 12, 5, 45, 10, 765) //~> 2017-03-12T05:45:10.765
     */
    DateTime.local = function (year, month, day, hour, minute, second, millisecond) {
        return DateTime.fromLuxon(luxon_1.DateTime.local(year, month, day, hour, minute, second, millisecond));
    };
    DateTime.fromMillis = function (ms) {
        return DateTime.fromLuxon(luxon_1.DateTime.fromMillis(ms));
    };
    DateTime.fromDate = function (date) {
        return DateTime.fromLuxon(luxon_1.DateTime.fromJSDate(date));
    };
    Object.defineProperty(DateTime.prototype, "year", {
        /**
         * Get the year
         * @example
         * DateTime.local(2017, 5, 25).year //=> 2017
         */
        get: function () {
            return this._value.year;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateTime.prototype, "month", {
        /**
         * Get the month (1-12).
         * @example
         * DateTime.local(2017, 5, 25).month //=> 5
         */
        get: function () {
            return this._value.month;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateTime.prototype, "weekday", {
        /**
         * Get the day of the week.
         * 1 is Monday and 7 is Sunday
         * @see https://en.wikipedia.org/wiki/ISO_week_date
         * @example
         * DateTime.local(2014, 11, 31).weekday //=> 4
         */
        get: function () {
            return this._value.weekday;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateTime.prototype, "day", {
        /**
         * Get the day of the month (1-30ish).
         * @example
         * DateTime.local(2017, 5, 25).day //=> 25
         */
        get: function () {
            return this._value.day;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateTime.prototype, "hour", {
        /**
         * Get the hour of the day (0-23).
         * @example
         * DateTime.local(2017, 5, 25, 9).hour //=> 9
         */
        get: function () {
            return this._value.hour;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateTime.prototype, "minute", {
        /**
         * Get the minute of the hour (0-59).
         * @example
         * DateTime.local(2017, 5, 25, 9, 30).minute //=> 30
         */
        get: function () {
            return this._value.minute;
        },
        enumerable: false,
        configurable: true
    });
    DateTime.prototype.set = function (value) {
        return new DateTime(this._value.set(value));
    };
    DateTime.prototype.setZone = function (zone) {
        return new DateTime(this._value.setZone(zone));
    };
    /**
     * "Set" this DateTime to the beginning of a unit of time.
     * @example
     * DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
     * @example
     * DateTime.local(2014, 3, 3).startOf('year').toISODate(); //=> '2014-01-01'
     * @example
     * DateTime.local(2014, 3, 3).startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
     * @example
     * DateTime.local(2014, 3, 3, 5, 30).startOf('day').toISOTime(); //=> '00:00.000-05:00'
     * @example
     * DateTime.local(2014, 3, 3, 5, 30).startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
     */
    DateTime.prototype.startOf = function (unit) {
        return new DateTime(this._value.startOf(unit));
    };
    /**
     * "Set" this DateTime to the end (meaning the last millisecond) of a unit of time
     * @example
     * DateTime.local(2014, 3, 3).endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
     * @example
     * DateTime.local(2014, 3, 3).endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
     * @example
     * DateTime.local(2014, 3, 3).endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
     * @example
     * DateTime.local(2014, 3, 3, 5, 30).endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
     * @example
     * DateTime.local(2014, 3, 3, 5, 30).endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
     */
    DateTime.prototype.endOf = function (unit) {
        return new DateTime(this._value.endOf(unit));
    };
    /**
     * Equality check
     * Two DateTimes are equal if they represent the same millisecond, have the same zone and location, and are both valid.
     * To compare just the millisecond values, use `+dt1 === +dt2`.
     */
    DateTime.prototype.equals = function (other) {
        return this._value.equals(other._value);
    };
    /**
     * Returns true if given DateTime is the same day than this one.
     */
    DateTime.prototype.isSameDayThan = function (dateB) {
        return Boolean(this.year &&
            dateB.year &&
            this.month === dateB.month &&
            this.startOf('day').equals(dateB.startOf('day')));
    };
    DateTime.prototype.isToday = function () {
        return this.isSameDayThan(DateTime.now().setZone(this._value.zoneName));
    };
    DateTime.prototype.isTomorrow = function () {
        return Math.floor(this._value.endOf('day').diffNow('days').days) === 1;
    };
    DateTime.prototype.isPastDay = function () {
        return this._value.startOf('day').diffNow('days').days < -1;
    };
    DateTime.prototype.isFutureDay = function () {
        // Use endOf('day') since we compare with now!
        return this._value.endOf('day').diffNow('days').days >= 1;
    };
    DateTime.prototype.isWeekend = function () {
        return this.weekday >= 6;
    };
    /**
     * Return the difference between two DateTimes as a Duration.
     * @param other - the DateTime to compare this one to
     * @param [unit=['milliseconds']] - the unit or array of units (such as 'hours' or 'days') to include in the duration.
     * @example
     * let i1 = DateTime.fromISO('1982-05-25T09:45'),
     *     i2 = DateTime.fromISO('1983-10-14T10:30');
     * i2.diff(i1).toObject() //=> { milliseconds: 43807500000 }
     * i2.diff(i1, 'hours').toObject() //=> { hours: 12168.75 }
     * i2.diff(i1, ['months', 'days']).toObject() //=> { months: 16, days: 19.03125 }
     * i2.diff(i1, ['months', 'days', 'hours']).toObject() //=> { months: 16, days: 19, hours: 0.75 }
     */
    DateTime.prototype.diff = function (other, unit) {
        return this._value.diff(other._value, unit);
    };
    /**
     * Number of days that has spent since `other` DateTime.
     */
    DateTime.prototype.daysSince = function (other) {
        return this.startOf('day').diff(other.startOf('day'), 'day').days;
    };
    /**
     * Number of minutes that has spent since `other` DateTime.
     */
    DateTime.prototype.minutesSince = function (other) {
        return this.diff(other, 'minutes').minutes;
    };
    /**
     * Returns `true` if this DateTime is (strictly) before the given one.
     */
    DateTime.prototype.isBefore = function (other) {
        return this._value.diff(other._value, 'milliseconds').milliseconds < 0;
    };
    /**
     * Returns `true` if this DateTime is (strictly) after the given one.
     */
    DateTime.prototype.isAfter = function (other) {
        return this._value.diff(other._value, 'milliseconds').milliseconds > 0;
    };
    DateTime.prototype.toJulianDate = function () {
        return (0, julianDate_1.toJulianDate)(this._value.toMillis());
    };
    /**
     * Returns `true` if this DateTime is between given `start` and `end`.
     */
    DateTime.prototype.isBetween = function (start, end) {
        return this.isAfter(start) && this.isBefore(end);
    };
    /**
     * Add a period of time to this DateTime and return the resulting DateTime
     *
     * Adding hours, minutes, seconds, or milliseconds increases the timestamp by the right number of milliseconds.
     * Adding days, months, or years shifts the calendar, accounting for DSTs and leap years along the way.
     * Thus, `dt.plus({ hours: 24 })` may result in a different time than `dt.plus({ days: 1 })` if there's a DST shift in between.
     * @example
     * DateTime.now().plus(123) //~> in 123 milliseconds
     * @example
     * DateTime.now().plus({ minutes: 15 }) //~> in 15 minutes
     * @example
     * DateTime.now().plus({ days: 1 }) //~> this time tomorrow
     * @example
     * DateTime.now().plus({ days: -1 }) //~> this time yesterday
     * @example
     * DateTime.now().plus({ hours: 3, minutes: 13 }) //~> in 3 hr, 13 min
     * @example
     * DateTime.now().plus(Duration.fromObject({ hours: 3, minutes: 13 })) //~> in 3 hr, 13 min
     */
    DateTime.prototype.plus = function (duration) {
        return new DateTime(this._value.plus(duration));
    };
    /**
     * Subtract a period of time to this DateTime and return the resulting DateTime
     * See {@link plus}
     */
    DateTime.prototype.minus = function (duration) {
        return new DateTime(this._value.minus(duration));
    };
    /**
     * Based on a number of `minutes`, check how much there will be left for the day, in N `days`.
     * It will cap the result at the maximum minutes a day can have.
     * @example
     * const date = DateTime.fromISO('2021-01-01T23:50');
     * date.remainingMinutesAfterNDays(33, 1); // ~> 23
     * @example
     * const date = DateTime.fromISO('2021-01-01T23:50');
     * date.remainingMinutesAfterNDays(33, 2); // ~> 0
     * @example
     * const date = DateTime.fromISO('2021-01-01T00:40');
     * const TWO_DAYS_IN_MINS = 2 * 24 * 60 // ~> 1440 * 2 = 2880
     * date.remainingMinutesAfterNDays(TWO_DAYS_IN_MINS, 0); // ~> 1400
     * date.remainingMinutesAfterNDays(TWO_DAYS_IN_MINS, 1); // ~> 1440
     * date.remainingMinutesAfterNDays(TWO_DAYS_IN_MINS, 2); // ~> 40
     */
    DateTime.prototype.remainingMinutesAfterNDays = function (minutes, days) {
        if (days < 0)
            return minutes;
        if (days === 0)
            return Math.min(this.minutesUntilEndOfDay, minutes);
        var result = minutes - this.minutesUntilEndOfDay - (days - 1) * exports.MINUTES_IN_A_DAY;
        return Math.min(exports.MINUTES_IN_A_DAY, Math.max(0, result));
    };
    Object.defineProperty(DateTime.prototype, "minutesUntilEndOfDay", {
        get: function () {
            return Math.max(0, exports.MINUTES_IN_A_DAY - this.minutesSinceDayStarted);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateTime.prototype, "minutesSinceDayStarted", {
        get: function () {
            return this.minute + this.hour * 60;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Returns a string representation of this DateTime formatted according to the specified format string.
     *
     * @example
     * DateTime.now().toFormat('month-year') //=> 'October 2021'
     * @example
     * DateTime.now().toFormat('day-string') //=> 'Monday'
     * @example
     * DateTime.now().toFormat('day-short') //=> 'Mon'
     * @example
     * DateTime.now().toFormat('day-letter') //=> 'M'
     * @example
     * DateTime.now().toFormat('day-of-month') //=> '8'
     * @example
     * DateTime.now().toFormat('month-day') //=> 'Oct 8'
     * @example
     * DateTime.now().toFormat('am-pm-hour') //=> '8:15am'
     * @example
     * DateTime.now().toFormat('hour') //=> '8:15'
     * @example
     * DateTime.now().toFormat('short-date') //=> 'Thu Apr 14'
     */
    DateTime.prototype.toFormat = function (format) {
        // See https://moment.github.io/luxon/#/formatting?id=table-of-tokens
        switch (format) {
            case 'month-year':
                return this._value.toFormat('MMMM yyyy');
            case 'day-string':
                return this._value.toFormat('cccc');
            case 'day-short':
                return this._value.toFormat('ccc');
            case 'day-letter':
                return this._value.toFormat('ccccc');
            case 'day-of-month':
                return this._value.toFormat('d');
            case 'month-day':
                return this._value.toFormat('MMM d');
            case 'short-date':
                return this._value.toFormat('ccc LLL d');
            case 'calendar-date':
                return this._value.toFormat('cccc, LLLL d, yyyy');
            case 'am-pm-hour':
                return this.minute === 0
                    ? this._value.toFormat('ha').toLowerCase()
                    : this._value.toFormat('h:mma').toLowerCase();
            case 'hour':
                return this._value.toFormat('h:mm').toLowerCase();
            case 'year-month-day':
                return this._value.toFormat('yyyy-MM-dd').toLowerCase();
        }
    };
    /**
     * Returns the epoch milliseconds of this DateTime.
     */
    DateTime.prototype.toMillis = function () {
        return this._value.toMillis();
    };
    /**
     * Returns an ISO 8601-compliant string representation of this DateTime
     * @example
     * DateTime.utc(1982, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
     * @example
     * DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
     */
    DateTime.prototype.toISO = function () {
        return this._value.toISO();
    };
    DateTime.prototype.toString = function () {
        return this._value.toString();
    };
    DateTime.prototype.toLuxon = function () {
        return this._value;
    };
    DateTime.prototype.toJSDate = function () {
        return this._value.toJSDate();
    };
    /**
     * Returns a string representation of a this time relative to now, such as "in two days". It will round to the closest unit.
     *
     * @example
     * DateTime.now().plus({ days: 1 }).toRelative() //=> "in 1 day"
     * @example
     * DateTime.now().plus({ days: 1, hours: 23 }).toRelative() //=> "in 2 days"
     * @example
     * DateTime.now().minus({ days: 2 }).toRelative() //=> "2 days ago"
     */
    DateTime.prototype.toRelative = function () {
        var _a;
        var diff = (_a = this._value.diffNow()).shiftTo.apply(_a, RELATIVE_UNITS);
        var unit = RELATIVE_UNITS.find(function (unit) { return diff.get(unit) !== 0; }) || 'second';
        // Round the value so it looks more natural
        var value = Math.trunc(diff.as(unit));
        if (unit === 'day' && diff.get('hour') >= 12) {
            value++;
        }
        if (unit === 'hour' && diff.get('minute') >= 30) {
            value++;
        }
        if (unit === 'minute' && diff.get('second') >= 30) {
            value++;
        }
        var relative = new Intl.RelativeTimeFormat('en', {
            numeric: 'auto',
        }).format(value, unit);
        return unit === 'minute' || unit === 'second'
            ? relative
            : relative.replace(/in /, 'in ~');
    };
    return DateTime;
}());
exports.DateTime = DateTime;
var DateTimeStartingSunday = /** @class */ (function (_super) {
    __extends(DateTimeStartingSunday, _super);
    function DateTimeStartingSunday() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateTimeStartingSunday.prototype.set = function (value) {
        return new DateTimeStartingSunday(this._value.set(value));
    };
    DateTimeStartingSunday.prototype.setZone = function (zone) {
        return new DateTimeStartingSunday(this._value.setZone(zone));
    };
    DateTimeStartingSunday.prototype.startOf = function (unit) {
        if (unit !== 'week' && unit !== 'weeks') {
            return new DateTimeStartingSunday(this._value.startOf(unit));
        }
        var startOfWeek = this.toFormat('day-string') === 'Sunday'
            ? this._value
            : this._value.startOf(unit).minus({ day: 1 });
        return new DateTimeStartingSunday(startOfWeek);
    };
    DateTimeStartingSunday.prototype.plus = function (duration) {
        return new DateTimeStartingSunday(this._value.plus(duration));
    };
    DateTimeStartingSunday.prototype.minus = function (duration) {
        return new DateTimeStartingSunday(this._value.minus(duration));
    };
    return DateTimeStartingSunday;
}(DateTime));
function createDateTime(date, options) {
    var luxonDateTime = date === 'now'
        ? luxon_1.DateTime.now()
        : date instanceof Date
            ? luxon_1.DateTime.fromJSDate(date)
            : luxon_1.DateTime.fromObject(date);
    return (options === null || options === void 0 ? void 0 : options.startsOnSunday)
        ? new DateTimeStartingSunday(luxonDateTime)
        : new DateTime(luxonDateTime);
}
exports.createDateTime = createDateTime;
