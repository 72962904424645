"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.attendSuggestedEvent = exports.attendSession = exports.deleteCalendarEvent = exports.subscribeToCalendarEventsBetween = exports.sessionToEvent = void 0;
var config_1 = require("../config");
// If the prefix changes, we should update `manageScheduledSessions.ts` and `manageCalendarEvents.ts`
var CENTERED_EVENT_ID_PREFIX = 'centered-';
function sessionToEvent(session) {
    return {
        id: "".concat(CENTERED_EVENT_ID_PREFIX).concat(session.id),
        type: 'centered',
        ownerId: session.owner.id,
        groupId: session.group.id,
        owner: session.owner,
        group: session.group,
        scheduledSessionId: session.id,
        name: session.title,
        start: session.startAt.toMillis(),
        end: session.endAt.toMillis(),
    };
}
exports.sessionToEvent = sessionToEvent;
function subscribeToCalendarEventsBetween(start, end, onUpdate) {
    var _a;
    return config_1.db
        .collection("users/".concat((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid, "/visible-calendar-events"))
        .where('start', '>=', start.getTime())
        .where('start', '<=', end.getTime())
        .withConverter((0, config_1.converter)())
        .onSnapshot(function (snapshot) {
        onUpdate(snapshot.docs
            .map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); })
            .filter(function (event) { return typeof event.name === 'string'; }));
    }, function (e) {
        console.error('Error in subscribeToCalendarEventsBetween snapshot listener', e);
    });
}
exports.subscribeToCalendarEventsBetween = subscribeToCalendarEventsBetween;
function deleteCalendarEvent(eventId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config_1.auth.currentUser) {
                        throw new Error('User should be authenticated to delete a Sukha Session');
                    }
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(config_1.auth.currentUser.uid, "/visible-calendar-events/").concat(eventId))
                            .delete()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteCalendarEvent = deleteCalendarEvent;
function attendSession(session) {
    return __awaiter(this, void 0, void 0, function () {
        var eventId, eventDoc, event, newEvent_1, isSuggestion, newEvent;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config_1.auth.currentUser) {
                        throw new Error('User should be authenticated to delete a Sukha Session');
                    }
                    eventId = "suggested-".concat(session.id);
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(config_1.auth.currentUser.uid, "/visible-calendar-events/").concat(eventId))
                            .withConverter((0, config_1.converter)())
                            .get()];
                case 1:
                    eventDoc = _a.sent();
                    event = eventDoc.data();
                    if (!!event) return [3 /*break*/, 3];
                    newEvent_1 = sessionToEvent(session);
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(config_1.auth.currentUser.uid, "/visible-calendar-events/").concat(newEvent_1.id))
                            .set(newEvent_1)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
                case 3:
                    isSuggestion = event && 'type' in event && event.type === 'suggestion';
                    if (!isSuggestion) {
                        // If prefix changes, we should update backend/firebase sessionToEvent.ts
                        throw new Error("Event ".concat(eventId, " is not a suggested event. It is a ").concat(event && 'type' in event && event.type, " event."));
                    }
                    newEvent = toSessionEvent(event);
                    return [4 /*yield*/, deleteCalendarEvent(eventId)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(config_1.auth.currentUser.uid, "/visible-calendar-events/").concat(newEvent.id))
                            .set(newEvent)];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.attendSession = attendSession;
function attendSuggestedEvent(eventId) {
    return __awaiter(this, void 0, void 0, function () {
        var eventDoc, event, isSuggestion, newEvent;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config_1.auth.currentUser) {
                        throw new Error('User should be authenticated to delete a Sukha Session');
                    }
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(config_1.auth.currentUser.uid, "/visible-calendar-events/").concat(eventId))
                            .withConverter((0, config_1.converter)())
                            .get()];
                case 1:
                    eventDoc = _a.sent();
                    event = eventDoc.data();
                    isSuggestion = event && 'type' in event && event.type === 'suggestion';
                    if (!isSuggestion) {
                        // If prefix changes, we should update backend/firebase sessionToEvent.ts
                        throw new Error("Event ".concat(eventId, " is not a suggested event. It is a ").concat(event && 'type' in event && event.type, " event."));
                    }
                    newEvent = toSessionEvent(event);
                    return [4 /*yield*/, deleteCalendarEvent(eventId)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(config_1.auth.currentUser.uid, "/visible-calendar-events/").concat(newEvent.id))
                            .set(newEvent)];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.attendSuggestedEvent = attendSuggestedEvent;
function toSessionEvent(event) {
    return __assign(__assign({}, event), { 
        // If prefixes change, we should update backend/firebase sessionToEvent.ts
        id: event.id.replace('suggestion-', 'centered-'), type: 'centered' });
}
