"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPosterImageFromMuxStreamingUrl = void 0;
// Example streaming url: https://stream.mux.com/ayp8vp02OEkjIooz5Y00kmyHtO1W8vx00PyXdaXv35Waho.m3u8
// Documentation: https://docs.mux.com/guides/video/get-images-from-a-video
function getPosterImageFromMuxStreamingUrl(streamingUrl, time) {
    if (time === void 0) { time = 0; }
    if (!streamingUrl) {
        return null;
    }
    var muxStreamingId = streamingUrl
        .replace('https://stream.mux.com/', '')
        .replace('.m3u8', '');
    return "https://image.mux.com/".concat(muxStreamingId, "/thumbnail.jpg?time=").concat(time);
}
exports.getPosterImageFromMuxStreamingUrl = getPosterImageFromMuxStreamingUrl;
