"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addDurationToEvent = void 0;
var constants_1 = require("./constants");
function addDurationToEvent(event) {
    var duration = (Date.now() - event.eventDate.getTime()) / 1000;
    if (event.bundleId === 'app.centered.idle') {
        duration += constants_1.TRIGGER_IDLE_TIME_AFTER_SECONDS;
    }
    return __assign(__assign({}, event), { duration: duration });
}
exports.addDurationToEvent = addDurationToEvent;
