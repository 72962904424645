"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.musicMachineEventSchema = void 0;
var zod_1 = require("zod");
exports.musicMachineEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Play'),
        origin: zod_1.z.union([zod_1.z.literal('user'), zod_1.z.literal('flow')]),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Pause'),
        origin: zod_1.z.union([zod_1.z.literal('user'), zod_1.z.literal('break')]),
    }),
]);
