"use strict";
// NOTE: this code is duplicated in our backend functions because we can't get it there unless we publish the package
// ⚠️ Keep them in sync
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAndCacheIcon = void 0;
var luxon_1 = require("luxon");
var config_1 = require("../config");
var helpers_1 = require("@centered/helpers");
var invalidateCacheAfterDays = 5;
/**
 * Check if the given icon is already cached for the given bundle.
 * If so, return the icon URL that we cached.
 * If not, get the icon URL and cache it.
 *
 * If the cached icon is too old, we discard it to get the fresher icon.
 */
function getAndCacheIcon(allIcons, bundleIdOrUrl, icon) {
    return __awaiter(this, void 0, void 0, function () {
        var bundleIcons, cachedIcon, result, i, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    bundleIcons = allIcons
                        .filter(function (item) { var _a, _b; return ((_b = (_a = item.docs[0]) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.bundleIdOrUrl) === bundleIdOrUrl; })
                        .map(function (item) { return item.docs[0]; });
                    cachedIcon = findCachedIcon(bundleIcons);
                    if (cachedIcon) {
                        return [2 /*return*/, cachedIcon.iconUrl];
                    }
                    if (!icon) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 10, , 11]);
                    return [4 /*yield*/, fetchIconUrl(bundleIdOrUrl, icon)];
                case 2:
                    result = _a.sent();
                    if (!result) {
                        console.error("Can't fetch an icon URL from data", { icon: icon });
                        return [2 /*return*/];
                    }
                    if (!(bundleIcons.length > 0)) return [3 /*break*/, 7];
                    i = 1;
                    _a.label = 3;
                case 3:
                    if (!(i < bundleIcons.length)) return [3 /*break*/, 6];
                    return [4 /*yield*/, bundleIcons[i].ref.delete()];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    ++i;
                    return [3 /*break*/, 3];
                case 6:
                    // update the first doc
                    bundleIcons[0].ref.update({
                        iconUrl: result,
                        updatedAt: new Date(),
                    });
                    return [3 /*break*/, 9];
                case 7: return [4 /*yield*/, config_1.db
                        .collection('app-icons')
                        .withConverter((0, config_1.converter)())
                        .add({
                        bundleIdOrUrl: bundleIdOrUrl,
                        iconUrl: result,
                        updatedAt: new Date(),
                    })];
                case 8:
                    _a.sent();
                    _a.label = 9;
                case 9: return [3 /*break*/, 11];
                case 10:
                    e_1 = _a.sent();
                    console.error('error in getAndCacheIcon', e_1);
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/, result];
            }
        });
    });
}
exports.getAndCacheIcon = getAndCacheIcon;
function findCachedIcon(icons) {
    return icons
        .map(function (docSnapshot) {
        var item = docSnapshot.data();
        var updatedAt = item.updatedAt;
        return __assign(__assign({}, item), { id: docSnapshot.id, updatedAt: updatedAt.toDate() });
    })
        .filter(function isCacheStillValid(item) {
        return (luxon_1.DateTime.now().diff(luxon_1.DateTime.fromJSDate(item.updatedAt), 'days').days <=
            invalidateCacheAfterDays);
    })[0];
}
function fetchIconUrl(bundleIdOrUrl, icon) {
    return __awaiter(this, void 0, void 0, function () {
        var base64Icon, _a, imageRef, snapshot;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!icon.startsWith('data:image')) return [3 /*break*/, 1];
                    _a = icon;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, fetchFavIconAsBase64ImageFromUrl(icon)];
                case 2:
                    _a = _b.sent();
                    _b.label = 3;
                case 3:
                    base64Icon = _a;
                    imageRef = config_1.appIconStorage.ref().child("".concat(bundleIdOrUrl, ".png"));
                    return [4 /*yield*/, imageRef.putString(base64Icon, 'data_url')];
                case 4:
                    snapshot = _b.sent();
                    return [2 /*return*/, snapshot.ref.getDownloadURL()];
            }
        });
    });
}
function fetchFavIconAsBase64ImageFromUrl(url) {
    return __awaiter(this, void 0, void 0, function () {
        var res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("".concat(helpers_1.WEB_URL, "/api/fetchIconFromUrl?url=").concat(encodeURIComponent(url)))];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data.result];
            }
        });
    });
}
