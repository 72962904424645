"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsOnline = void 0;
var react_1 = require("react");
var online = true;
if (typeof window !== 'undefined') {
    online = navigator.onLine;
}
function useIsOnline() {
    var _a = (0, react_1.useState)(online), isOnline = _a[0], setIsOnline = _a[1];
    (0, react_1.useEffect)(function () {
        var updateOnlineStatus = function () {
            setIsOnline(typeof window !== 'undefined' ? navigator.onLine : true);
        };
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
        return function () {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);
    return isOnline;
}
exports.useIsOnline = useIsOnline;
