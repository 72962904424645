"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchFlowSessions = void 0;
var react_1 = require("react");
var __1 = require("..");
var config_1 = require("../config");
var ITEMS_TO_LOAD_AT_A_TIME = 50;
var cachedFlowSessions = [];
function useFetchFlowSessions(userId) {
    var _a = (0, react_1.useState)(cachedFlowSessions), sessions = _a[0], setSessions = _a[1];
    var _b = (0, react_1.useState)(true), loading = _b[0], setLoading = _b[1];
    var _c = (0, __1.useCurrentUser)(), currentUser = _c.currentUser, loadingCurrentUser = _c.loading;
    (0, react_1.useEffect)(function () {
        if (loadingCurrentUser === false && !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            cachedFlowSessions = [];
        }
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, loadingCurrentUser]);
    (0, react_1.useEffect)(function () {
        cachedFlowSessions = sessions;
    }, [sessions]);
    var _d = (0, react_1.useState)(false), allSessionsLoaded = _d[0], setAllSessionsLoaded = _d[1];
    var sessionQuery = (0, react_1.useRef)(config_1.db
        .collection("/users/".concat(userId, "/flowSessions"))
        .where('flowState.state', '==', 'Ended')
        .orderBy('completedAt', 'desc')
        .limit(ITEMS_TO_LOAD_AT_A_TIME)
        .withConverter((0, config_1.converter)()));
    var lastPostRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (!userId)
            return;
        var isMounted = true;
        setLoading(true);
        sessionQuery.current
            .get()
            .then(function (snapshot) {
            if (isMounted) {
                lastPostRef.current = snapshot.docs[snapshot.docs.length - 1];
                setSessions(snapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); }));
                setLoading(false);
                if (snapshot.docs.length < ITEMS_TO_LOAD_AT_A_TIME) {
                    setAllSessionsLoaded(true);
                }
            }
        })
            .catch(function (err) {
            console.error('Error fetching feed-timeline for user', err);
        });
        return function () {
            isMounted = false;
        };
    }, [userId]);
    function loadMorePosts() {
        var _a;
        if (!lastPostRef.current)
            return;
        (_a = sessionQuery.current) === null || _a === void 0 ? void 0 : _a.startAfter(lastPostRef.current).get().then(function (snapshot) {
            var lastPostCursor = snapshot.docs[snapshot.docs.length - 1];
            if (!lastPostCursor) {
                setAllSessionsLoaded(true);
            }
            else {
                lastPostRef.current = lastPostCursor;
                setSessions(function (curr) { return __spreadArray(__spreadArray([], curr, true), snapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); }), true); });
            }
        }).catch(function (e) { return console.error(e); });
    }
    return {
        loading: loading,
        loadMorePosts: loadMorePosts,
        sessions: sessions,
        allSessionsLoaded: allSessionsLoaded,
    };
}
exports.useFetchFlowSessions = useFetchFlowSessions;
