import Cookies from 'js-cookie'
import { cookieArguments } from './token'

export interface NewOriginationMetadata {
  readonly utmCampaign?: string | null
  readonly utmContent?: string | null
  readonly utmSource?: string | null
  readonly utmMedium?: string | null
  readonly utmTerm?: string | null
  readonly copyParam?: string | null
}

export function saveOriginationMetadata(
  originationMetadata: NewOriginationMetadata,
): void {
  const cookieOriginationMetadata = loadOriginationMetadata()

  const newOriginationMetadata: NewOriginationMetadata = {
    ...originationMetadata,
    ...cookieOriginationMetadata,
  }

  Cookies.set(
    'CenteredOriginationMetadata',
    JSON.stringify(newOriginationMetadata),
    cookieArguments,
  )
}

export function loadOriginationMetadata(): NewOriginationMetadata {
  const originationMetadata = Cookies.get('CenteredOriginationMetadata')
  let parsedCookie: NewOriginationMetadata = {}

  if (originationMetadata) {
    try {
      parsedCookie = JSON.parse(originationMetadata) as NewOriginationMetadata
    } catch (e) {
      console.error('Error - Origination Metadata', e)
    }
  }

  return parsedCookie || {}
}
