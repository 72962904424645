"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteTaskBucket = exports.updateBucketList = exports.createTaskBucket = exports.subscribeToSessionTaskBuckets = exports.subscribeToTaskBuckets = void 0;
var helpers_1 = require("@centered/helpers");
var lexorank_1 = require("lexorank");
var config_1 = require("../config");
function subscribeToTaskBuckets(userId, onSnapshot) {
    if (!userId) {
        throw new Error('Need userId to subscribe to task buckets');
    }
    return getCollection(userId).onSnapshot(function (snapshot) {
        onSnapshot(snapshot.docs
            .filter(function (doc) { return isCustomTaskBucket(doc.data()); })
            .map(function (doc) { return toCustomTaskBucket(doc.data()); }));
    }, function (e) {
        console.error('Error in subscribeToTaskBuckets', e);
    });
}
exports.subscribeToTaskBuckets = subscribeToTaskBuckets;
function subscribeToSessionTaskBuckets(userId, onSnapshot) {
    return getCollection(userId).onSnapshot(function (snapshot) {
        onSnapshot(snapshot.docs
            .map(function (doc) { return doc.data(); })
            .filter(function (bucket) { return isSessionTaskBucket(bucket); })
            .map(function (bucket) {
            return isBuddySessionTaskBucket(bucket)
                ? toBuddySessionTaskBucket(bucket)
                : toScheduledSessionTaskBucket(bucket);
        }));
    }, function (e) {
        console.error('Error in subscribeToSessionTaskBuckets', e);
    });
}
exports.subscribeToSessionTaskBuckets = subscribeToSessionTaskBuckets;
function createTaskBucket(bucket) {
    return __awaiter(this, void 0, void 0, function () {
        var bucketTitle, knownBuckets, knownRanksInDescOrder, latestKnownRank, rank, newDoc;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    bucketTitle = bucket.title.substr(0, helpers_1.TASK_BUCKET_TITLE_MAX_LENGTH);
                    if (bucketTitle.trim() === '') {
                        throw new Error("Bucket title can't be empty");
                    }
                    return [4 /*yield*/, fetchBuckets()];
                case 1:
                    knownBuckets = _a.sent();
                    knownRanksInDescOrder = knownBuckets
                        .map(function (_a) {
                        var rank = _a.rank;
                        return rank;
                    })
                        .filter(function (rank) { return Boolean(rank); })
                        .sort(function (rankA, rankB) { return -1 * rankA.compareTo(rankB); });
                    latestKnownRank = knownRanksInDescOrder[0];
                    rank = latestKnownRank ? latestKnownRank.genNext() : lexorank_1.LexoRank.middle();
                    newDoc = getCollection().doc();
                    return [4 /*yield*/, newDoc.set(__assign(__assign({}, bucket), { title: bucketTitle, rank: rank.toString(), id: newDoc.id }))];
                case 2:
                    _a.sent();
                    return [2 /*return*/, newDoc.id];
            }
        });
    });
}
exports.createTaskBucket = createTaskBucket;
function fetchBuckets() {
    return __awaiter(this, void 0, void 0, function () {
        var query;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCollection().get()];
                case 1:
                    query = _a.sent();
                    return [2 /*return*/, query.docs
                            .filter(function (doc) { return isCustomTaskBucket(doc.data()); })
                            .map(function (doc) { return toCustomTaskBucket(doc.data()); })];
            }
        });
    });
}
function updateBucketList(buckets) {
    return __awaiter(this, void 0, void 0, function () {
        var batch;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    batch = config_1.db.batch();
                    buckets.forEach(function (bucket) {
                        var update = __assign({ id: bucket.id, title: bucket.title, isVisible: bucket.isVisible }, (bucket.rank && { rank: bucket.rank.toString() }));
                        batch.set(getCollection().doc(bucket.id), update, { merge: true });
                    });
                    return [4 /*yield*/, batch.commit()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateBucketList = updateBucketList;
function deleteTaskBucket(bucketId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCollection().doc(bucketId).delete()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteTaskBucket = deleteTaskBucket;
function getCollection(userId) {
    var _a;
    if (userId === void 0) { userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid; }
    if (!userId) {
        throw new Error('Need userId to access the task buckets collection');
    }
    return config_1.db
        .collection("users/".concat(userId, "/task-buckets"))
        .withConverter((0, config_1.converter)());
}
function toCustomTaskBucket(bucket) {
    var _a, _b;
    return __assign({ type: 'custom', id: bucket.id, title: (_a = bucket.title) !== null && _a !== void 0 ? _a : bucket.id, isVisible: (_b = bucket.isVisible) !== null && _b !== void 0 ? _b : true }, (bucket.rank && { rank: lexorank_1.LexoRank.parse(bucket.rank) }));
}
function isCustomTaskBucket(bucket) {
    return typeof bucket.type === 'undefined' || bucket.type === 'custom';
}
function toScheduledSessionTaskBucket(bucket) {
    var _a, _b, _c, _d;
    return {
        type: 'scheduled-session',
        id: bucket.id,
        title: (_a = bucket.title) !== null && _a !== void 0 ? _a : bucket.id,
        scheduledSessionId: (_b = bucket.scheduledSessionId) !== null && _b !== void 0 ? _b : 'unknown-scheduled-session-id',
        startAt: new Date((_c = bucket.startAt) !== null && _c !== void 0 ? _c : 0),
        durationInMinutes: (_d = bucket.durationInMinutes) !== null && _d !== void 0 ? _d : 15,
    };
}
function toBuddySessionTaskBucket(bucket) {
    var _a, _b, _c, _d, _e;
    return {
        type: 'buddy-session',
        id: bucket.id,
        title: (_a = bucket.title) !== null && _a !== void 0 ? _a : bucket.id,
        buddySessionId: (_b = bucket.buddySessionId) !== null && _b !== void 0 ? _b : 'unknown-buddy-session-id',
        startAt: new Date((_c = bucket.startAt) !== null && _c !== void 0 ? _c : 0),
        durationInMinutes: (_d = bucket.durationInMinutes) !== null && _d !== void 0 ? _d : 15,
        matchId: (_e = bucket.matchId) !== null && _e !== void 0 ? _e : 'unknown-match-id',
        matchedUser: bucket.matchedUser,
    };
}
function isSessionTaskBucket(bucket) {
    return (isScheduledSessionTaskBucket(bucket) || isBuddySessionTaskBucket(bucket));
}
function isScheduledSessionTaskBucket(bucket) {
    return ((bucket === null || bucket === void 0 ? void 0 : bucket.type) === 'scheduled-session' &&
        typeof bucket.scheduledSessionId === 'string');
}
function isBuddySessionTaskBucket(bucket) {
    return ((bucket === null || bucket === void 0 ? void 0 : bucket.type) === 'buddy-session' &&
        typeof bucket.buddySessionId === 'string');
}
