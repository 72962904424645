"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.menuBarEventSchema = void 0;
var zod_1 = require("zod");
exports.menuBarEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Enable Menu Bar'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Disable Menu Bar'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Set Seconds'),
        seconds: zod_1.z.number(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Set State'),
        state: zod_1.z.union([zod_1.z.literal('break'), zod_1.z.literal('flow')]),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Set Session Timer'),
        sessionTimer: zod_1.z.union([zod_1.z.literal('simple'), zod_1.z.literal('break-timer')]),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Set Music Playing'),
        playing: zod_1.z.boolean(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Set Task'),
        task: zod_1.z
            .object({
            id: zod_1.z.string(),
            name: zod_1.z.string(),
        })
            .optional(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Disable Music'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Enable Music'),
    }),
]);
