"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isToday = void 0;
var isToday = function (someDate) {
    var today = new Date();
    return (someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear());
};
exports.isToday = isToday;
