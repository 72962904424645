"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentlyFlowing = void 0;
var react_1 = require("react");
var __1 = require("..");
var useCurrentlyFlowing = function (groupId) {
    var _a = (0, react_1.useState)([]), currentlyFlowing = _a[0], setCurrentlyFlowing = _a[1];
    (0, react_1.useEffect)(function () {
        if (!groupId)
            return;
        return __1.db
            .collection('users')
            .where('currentFlowhallId', '==', groupId)
            .where('sessionId', '!=', null)
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            setCurrentlyFlowing(snapshot.docs
                .map(function (doc) { return doc.data(); })
                .sort(function (a, b) { var _a, _b; return ((_a = b === null || b === void 0 ? void 0 : b.avatarUrl) !== null && _a !== void 0 ? _a : '').localeCompare((_b = a === null || a === void 0 ? void 0 : a.avatarUrl) !== null && _b !== void 0 ? _b : ''); }));
        }, function (err) {
            console.error('useCurrentlyFlowing failed', err);
        });
    }, [groupId]);
    return currentlyFlowing;
};
exports.useCurrentlyFlowing = useCurrentlyFlowing;
