"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainEventSchema = void 0;
var zod_1 = require("zod");
var breakTimerEvent_1 = require("./breakTimerEvent");
var coachMessageEvent_1 = require("./coachMessageEvent");
var coachMessageWindowEvent_1 = require("./coachMessageWindowEvent");
var mainMachineEvent_1 = require("./mainMachineEvent");
var menuBarEvent_1 = require("./menuBarEvent");
var musicMachineEvent_1 = require("./musicMachineEvent");
var sessionMachineEvent_1 = require("./sessionMachineEvent");
var taskMachineEvent_1 = require("./taskMachineEvent");
var windowSizeEvent_1 = require("./windowSizeEvent");
exports.mainEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Send to Break Timer'),
        breakTimerEvent: breakTimerEvent_1.breakTimerEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Coach Message Machine'),
        coachMessageMachineEvent: coachMessageEvent_1.coachMessageEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Coach Message Window'),
        coachMessageWindowEvent: coachMessageWindowEvent_1.coachMessageWindowEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Window Size Machine'),
        windowSizeEvent: windowSizeEvent_1.windowSizeEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Main Machine'),
        mainMachineEvent: mainMachineEvent_1.mainMachineEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Menu Bar'),
        menuBarEvent: menuBarEvent_1.menuBarEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Music Machine'),
        musicMachineEvent: musicMachineEvent_1.musicMachineEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Session Machine'),
        sessionMachineEvent: sessionMachineEvent_1.sessionMachineEventSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Send to Task Machine'),
        taskMachineEvent: taskMachineEvent_1.taskMachineEventSchema,
    }),
]);
