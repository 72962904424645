"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var __1 = require("..");
var useSubscribeCalendarFlowSessions = function (user, start, end) {
    var _a = (0, react_1.useState)([]), flowSessions = _a[0], setFlowSessions = _a[1];
    var _b = (0, react_1.useState)(false), loaded = _b[0], setLoaded = _b[1];
    (0, react_1.useEffect)(function () {
        if (!(user === null || user === void 0 ? void 0 : user.id) || user.id.length === 0)
            return;
        setLoaded(false);
        return __1.db
            .collection("/users/".concat(user.id, "/flowSessions"))
            .where('flowState.state', '==', 'Ended')
            .where('completedAt', '>=', start.toMillis())
            .where('completedAt', '<=', end.toMillis())
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            var sessions = snapshot.docs
                .map(function (doc) { return doc.data(); })
                .filter(function (data) { return data !== undefined; });
            setFlowSessions(sessions);
        });
    }, // Don't change every milliseconds, but every day is fine
    [user, start.day, end.day]);
    return {
        flowSessions: flowSessions,
        loaded: loaded,
    };
};
exports.default = useSubscribeCalendarFlowSessions;
