"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeDirection = exports.SegmentRole = exports.FlowhallSegmentAnchor = exports.FlowhallSegmentType = void 0;
var FlowhallSegmentType;
(function (FlowhallSegmentType) {
    FlowhallSegmentType["video"] = "video";
})(FlowhallSegmentType = exports.FlowhallSegmentType || (exports.FlowhallSegmentType = {}));
var FlowhallSegmentAnchor;
(function (FlowhallSegmentAnchor) {
    FlowhallSegmentAnchor["start"] = "start";
    FlowhallSegmentAnchor["end"] = "end";
})(FlowhallSegmentAnchor = exports.FlowhallSegmentAnchor || (exports.FlowhallSegmentAnchor = {}));
var SegmentRole;
(function (SegmentRole) {
    SegmentRole["INTRODUCTION"] = "intro";
    SegmentRole["OUTTRODUCTION"] = "outro";
    SegmentRole["TIME_CHECK"] = "time-check";
})(SegmentRole = exports.SegmentRole || (exports.SegmentRole = {}));
var TimeDirection;
(function (TimeDirection) {
    TimeDirection["FROM_START"] = "from-start";
    TimeDirection["MIDDLE"] = "middle";
    TimeDirection["FROM_END"] = "from-end";
})(TimeDirection = exports.TimeDirection || (exports.TimeDirection = {}));
