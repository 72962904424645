"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchSessionTasks = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var manageTasks_1 = require("../helpers/manageTasks");
var useFetchSessionTasks = function (userId) {
    var _a = (0, react_1.useState)([]), tasks = _a[0], setTasks = _a[1];
    var _b = (0, react_1.useState)(false), loaded = _b[0], setLoaded = _b[1];
    (0, react_1.useEffect)(function () {
        if (!userId || userId.length === 0)
            return;
        setLoaded(false);
        var isMounted = true;
        var unsubscribe = config_1.db
            .collection("users/".concat(userId, "/sessionTasks"))
            .where('deletedAt', '==', null)
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            if (isMounted) {
                setTasks(snapshot.docs.map(function (doc) { return (0, manageTasks_1.firebaseTaskDocToTask)(doc); }));
                setLoaded(true);
            }
        }, function (e) {
            console.error('Error in useFetchSessionTasks snapshot listener', e);
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [userId]);
    return {
        tasks: tasks,
        loaded: loaded,
    };
};
exports.useFetchSessionTasks = useFetchSessionTasks;
