"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackEvent = exports.trackPage = exports.AnalyticsPages = exports.identify = void 0;
var segment_1 = require("@analytics/segment");
var platform_1 = require("@centered/platform");
var analytics_1 = require("analytics");
var analytics = {
    identify: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log.apply(console, __spreadArray(["using dummy identify"], args, false));
    },
    page: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log.apply(console, __spreadArray(["using dummy page"], args, false));
    },
    track: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log.apply(console, __spreadArray(["using dummy track"], args, false));
    },
};
var analyticsInitialized = false;
var initAnalytics = function () {
    if (analyticsInitialized)
        return;
    analyticsInitialized = true;
    var SEGMENT_KEY = process.env.SEGMENT_KEY || process.env.NEXT_PUBLIC_SEGMENT_KEY;
    if (SEGMENT_KEY) {
        analytics = (0, analytics_1.default)({
            plugins: [
                (0, segment_1.default)({
                    writeKey: SEGMENT_KEY,
                    customScriptSrc: "https://analytics.centered.app/analytics.js/v1/".concat(SEGMENT_KEY, "/analytics.min.js"),
                }),
            ],
        });
    }
};
var identify = function (anonymousId, currentUser, baseProperties) {
    initAnalytics();
    var identifiedId = anonymousId;
    var properties;
    if (currentUser) {
        identifiedId = currentUser.id;
        properties = __assign(__assign({}, baseProperties), { name: currentUser.name, email: currentUser.email });
    }
    else {
        properties = __assign({}, baseProperties);
    }
    analytics.identify(identifiedId !== null && identifiedId !== void 0 ? identifiedId : "", properties, {
        Intercom: {
            action_color: "#D2116B",
        },
    });
};
exports.identify = identify;
// Add the page names to track here
var AnalyticsPages;
(function (AnalyticsPages) {
    AnalyticsPages["Onboarding"] = "Onboarding";
})(AnalyticsPages = exports.AnalyticsPages || (exports.AnalyticsPages = {}));
var trackPage = function (pageName, properties) {
    initAnalytics();
    var pageNameString = pageName;
    analytics.page(__assign({ name: pageName, source: platform_1.platform.type, url: platform_1.platform.type === "electron" ? "centered://".concat(pageNameString) : undefined, path: pageNameString }, properties));
};
exports.trackPage = trackPage;
function trackEvent(eventName, properties, enableIntercom) {
    initAnalytics();
    var args = __assign(__assign({}, properties), { source: platform_1.platform.type });
    // Intercom can only handle 120 events before throwing errors
    // This prevents events from flowing into Intercom because we don't use events there
    analytics.track(eventName, args, {
        integrations: {
            Intercom: enableIntercom !== null && enableIntercom !== void 0 ? enableIntercom : false,
        },
    });
}
exports.trackEvent = trackEvent;
