"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomTaskBuckets = void 0;
var react_1 = require("react");
var FirebaseContext_1 = require("../FirebaseContext");
var manageTaskBuckets_1 = require("../helpers/manageTaskBuckets");
function useCustomTaskBuckets() {
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    var _a = (0, react_1.useState)([]), buckets = _a[0], setBuckets = _a[1];
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        return (0, manageTaskBuckets_1.subscribeToTaskBuckets)(currentUser.id, setBuckets);
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return { buckets: buckets };
}
exports.useCustomTaskBuckets = useCustomTaskBuckets;
