"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchDistractions = exports.testDistraction = void 0;
var helpers_1 = require("@centered/helpers");
var react_1 = require("react");
var config_1 = require("../config");
function testDistraction(config, bundleIdOrUrl) {
    var isWebsite = bundleIdOrUrl.startsWith('http');
    if (!isWebsite) {
        return config.bundleIds.includes(bundleIdOrUrl);
    }
    return config.websites.some(function (website) {
        var isDistraction = Boolean(new RegExp(website).exec(bundleIdOrUrl));
        if (!isDistraction)
            return false;
        // If it matches, it _may_ be a distraction… unless it's an exception
        var isAnException = config.websiteExceptions.some(function (exception) {
            return Boolean(new RegExp(exception).exec(bundleIdOrUrl));
        });
        return !isAnException;
    });
}
exports.testDistraction = testDistraction;
function useFetchDistractions(currentUser) {
    var _a = (0, react_1.useState)([]), centeredDistractions = _a[0], setCenteredDistractions = _a[1];
    var _b = (0, react_1.useState)([]), personalDistractions = _b[0], setPersonalDistractions = _b[1];
    var _c = (0, react_1.useState)(false), personalDistractionsLoaded = _c[0], setPersonalDistractionsLoaded = _c[1];
    var hasPersonalDistractions = Boolean(personalDistractionsLoaded && (currentUser === null || currentUser === void 0 ? void 0 : currentUser.hasCustomDistractionList));
    var distractions = (0, react_1.useMemo)(function () {
        return personalDistractionsLoaded
            ? hasPersonalDistractions
                ? personalDistractions
                : centeredDistractions
            : [];
    }, [
        personalDistractionsLoaded,
        personalDistractions,
        centeredDistractions,
        hasPersonalDistractions,
    ]);
    (0, react_1.useEffect)(function () {
        if (!currentUser)
            return;
        return config_1.db
            .collection('distractions')
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            var list = snapshot.docs.map(function (d) { return (__assign(__assign({}, d.data()), { dbId: d.id })); });
            setCenteredDistractions((0, helpers_1.sortAlphabeticallyBy)(list, 'name'));
        }, function (err) {
            console.error('Error registering for distraction subscription', err);
        });
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        return config_1.db
            .collection("/users/".concat(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, "/custom-distractions"))
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            var list = snapshot.docs.map(function (d) { return (__assign(__assign({}, d.data()), { dbId: d.id })); });
            setPersonalDistractions((0, helpers_1.sortAlphabeticallyBy)(list, 'name'));
            setPersonalDistractionsLoaded(true);
        }, function (err) {
            console.error('Error registering for distraction subscription', err);
        });
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return {
        centeredDistractions: centeredDistractions,
        personalDistractions: personalDistractions,
        distractions: distractions,
        personalDistractionsLoaded: personalDistractionsLoaded,
        hasPersonalDistractions: hasPersonalDistractions,
    };
}
exports.useFetchDistractions = useFetchDistractions;
