"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubscribeUserWeekRewards = void 0;
var helpers_1 = require("@centered/helpers");
var firebase_1 = require("firebase");
var react_1 = require("react");
var config_1 = require("../config");
var FirebaseContext_1 = require("../FirebaseContext");
var lockedDay = function (date) { return ({ status: 'locked', date: date }); };
var useNow = function (refreshInterval) {
    if (refreshInterval === void 0) { refreshInterval = 1000; }
    var _a = (0, react_1.useState)((0, helpers_1.createDateTime)('now', {
        startsOnSunday: true,
    }).setZone('America/Los_Angeles')), now = _a[0], setNow = _a[1];
    (0, react_1.useEffect)(function () {
        var interval = setInterval(function () {
            setNow((0, helpers_1.createDateTime)('now', {
                startsOnSunday: true,
            }).setZone('America/Los_Angeles'));
        }, refreshInterval);
        return function () {
            clearInterval(interval);
        };
    }, [now, refreshInterval]);
    return now;
};
function useSubscribeUserWeekRewards() {
    var date = useNow(30000);
    var startOfWeek = date.startOf('day').startOf('week');
    var _a = (0, react_1.useState)([
        lockedDay(startOfWeek),
        lockedDay(startOfWeek.plus({ day: 1 })),
        lockedDay(startOfWeek.plus({ day: 2 })),
        lockedDay(startOfWeek.plus({ day: 3 })),
        lockedDay(startOfWeek.plus({ day: 4 })),
        lockedDay(startOfWeek.plus({ day: 5 })),
        lockedDay(startOfWeek.plus({ day: 6 })),
    ]), rewards = _a[0], setRewards = _a[1];
    var _b = (0, react_1.useState)(false), loaded = _b[0], setLoaded = _b[1];
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        setLoaded(false);
        return config_1.db
            .collection("private-users/".concat(currentUser.id, "/rewards"))
            .where('date', '>=', firebase_1.default.firestore.Timestamp.fromDate(startOfWeek.toJSDate()))
            .where('date', '<', firebase_1.default.firestore.Timestamp.fromDate(startOfWeek.plus({ week: 1 }).toJSDate()))
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            var fetchedRewards = snapshot.docs.map(function (doc) {
                return toDayReward(doc.data());
            });
            setRewards(rewards.map(function (reward) {
                var _a;
                // Future dates rewards can't be unlocked or claimed
                if (reward.date.isFutureDay())
                    return lockedDay(reward.date);
                return ((_a = fetchedRewards.find(function (_a) {
                    var date = _a.date;
                    return date.isSameDayThan(reward.date);
                })) !== null && _a !== void 0 ? _a : reward);
            }));
            setLoaded(true);
        }, function (e) {
            console.error('Error in useSubscribeUserWeekRewards snapshot listener', e);
        });
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, startOfWeek.day]);
    return {
        rewards: rewards,
        loaded: loaded,
    };
}
exports.useSubscribeUserWeekRewards = useSubscribeUserWeekRewards;
function toDayReward(reward) {
    return {
        status: reward.status,
        date: helpers_1.DateTime.fromMillis(reward.date.toMillis()).setZone('America/Los_Angeles'),
        reward: reward,
    };
}
