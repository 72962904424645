"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentBrowserSupport = void 0;
var currentBrowserSupport = function () {
    if (typeof navigator === 'undefined') {
        return {
            isAndroid: false,
            isCordova: false,
            isEdge: false,
            isFirefox: false,
            isChrome: false,
            isChromeIOS: false,
            isChromiumBased: false,
            isIE: false,
            isIOS: false,
            isOpera: false,
            isSafari: false,
            isTouchScreen: false,
            isWebComponentsSupported: false,
            isMac: false,
        };
    }
    return {
        isAndroid: /Android/.test(navigator.userAgent),
        // @ts-expect-error It would exist in Cordova
        isCordova: Boolean(window.cordova),
        isEdge: /Edge/.test(navigator.userAgent),
        isFirefox: /Firefox/.test(navigator.userAgent),
        isChrome: /Google Inc/.test(navigator.vendor),
        isChromeIOS: /CriOS/.test(navigator.userAgent),
        // @ts-ignore
        isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
        isIE: /Trident/.test(navigator.userAgent),
        isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
        isOpera: /OPR/.test(navigator.userAgent),
        isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
        isTouchScreen: 'ontouchstart' in window ||
            // @ts-expect-error It would exist on a touch screen
            (window.DocumentTouch && document instanceof DocumentTouch),
        isWebComponentsSupported: 'registerElement' in document &&
            'import' in document.createElement('link') &&
            'content' in document.createElement('template'),
        isMac: navigator.platform.startsWith('Mac'),
    };
};
exports.currentBrowserSupport = currentBrowserSupport;
