"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeScanner = exports.isValidTime = exports.splitDurationIntoNumberAndUnitComponent = exports.sortAlphabeticallyBy = exports.shuffleArray = exports.secondsToHHMMSS = exports.roundToClosestEven = exports.removeExtraDataFromVideoSourceName = exports.pickVideoToPlay = exports.pickFrom = exports.numberToOrdinal = exports.isValidEmail = exports.isToday = exports.setupIntercomSettings = exports.openIntercomMessenger = exports.hideIntercomMessengerIcon = exports.disableIntercom = exports.getPosterImageFromMuxStreamingUrl = exports.generateSocialMediaShareUrl = exports.formatDateToSayTodayIfToday = exports.firebaseTimestampOrSecondsToSeconds = exports.currentBrowserSupport = exports.clamp = exports.chunkArray = exports.capitalizeString = exports.bucketListItemByDay = exports.Animate = exports.addDurationToEvent = void 0;
var addDurationToEvent_1 = require("./addDurationToEvent");
Object.defineProperty(exports, "addDurationToEvent", { enumerable: true, get: function () { return addDurationToEvent_1.addDurationToEvent; } });
exports.Animate = __importStar(require("./animate"));
var bucketListItemByDay_1 = require("./bucketListItemByDay");
Object.defineProperty(exports, "bucketListItemByDay", { enumerable: true, get: function () { return bucketListItemByDay_1.bucketListItemByDay; } });
__exportStar(require("./calculateRank"), exports);
__exportStar(require("./calculateTaskEstimate"), exports);
var capitalizeString_1 = require("./capitalizeString");
Object.defineProperty(exports, "capitalizeString", { enumerable: true, get: function () { return capitalizeString_1.capitalizeString; } });
__exportStar(require("./checkIsBrowser"), exports);
__exportStar(require("./checkIsMobile"), exports);
var chunkArray_1 = require("./chunkArray");
Object.defineProperty(exports, "chunkArray", { enumerable: true, get: function () { return chunkArray_1.chunkArray; } });
var clamp_1 = require("./clamp");
Object.defineProperty(exports, "clamp", { enumerable: true, get: function () { return clamp_1.clamp; } });
__exportStar(require("./coach-message"), exports);
__exportStar(require("./constants"), exports);
__exportStar(require("./createChatId"), exports);
var currentBrowserSupport_1 = require("./currentBrowserSupport");
Object.defineProperty(exports, "currentBrowserSupport", { enumerable: true, get: function () { return currentBrowserSupport_1.currentBrowserSupport; } });
__exportStar(require("./dateRange"), exports);
__exportStar(require("./dateTime"), exports);
__exportStar(require("./findUpcomingEvent"), exports);
__exportStar(require("./score"), exports);
var firebaseTimestampOrSecondsToSeconds_1 = require("./firebaseTimestampOrSecondsToSeconds");
Object.defineProperty(exports, "firebaseTimestampOrSecondsToSeconds", { enumerable: true, get: function () { return firebaseTimestampOrSecondsToSeconds_1.firebaseTimestampOrSecondsToSeconds; } });
exports.formatDateToSayTodayIfToday = __importStar(require("./formatDateToSayTodayIfToday"));
__exportStar(require("./formatGroupSupportPrice"), exports);
var generateSocialMediaShareUrl_1 = require("./generateSocialMediaShareUrl");
Object.defineProperty(exports, "generateSocialMediaShareUrl", { enumerable: true, get: function () { return generateSocialMediaShareUrl_1.generateSocialMediaShareUrl; } });
__exportStar(require("./getBaseUrl"), exports);
__exportStar(require("./getInitialsFromName"), exports);
__exportStar(require("./getOs"), exports);
__exportStar(require("./functions"), exports);
var getPosterImageFromMuxStreamingUrl_1 = require("./getPosterImageFromMuxStreamingUrl");
Object.defineProperty(exports, "getPosterImageFromMuxStreamingUrl", { enumerable: true, get: function () { return getPosterImageFromMuxStreamingUrl_1.getPosterImageFromMuxStreamingUrl; } });
var intercom_1 = require("./intercom");
Object.defineProperty(exports, "disableIntercom", { enumerable: true, get: function () { return intercom_1.disableIntercom; } });
Object.defineProperty(exports, "hideIntercomMessengerIcon", { enumerable: true, get: function () { return intercom_1.hideIntercomMessengerIcon; } });
Object.defineProperty(exports, "openIntercomMessenger", { enumerable: true, get: function () { return intercom_1.openIntercomMessenger; } });
Object.defineProperty(exports, "setupIntercomSettings", { enumerable: true, get: function () { return intercom_1.setupIntercomSettings; } });
var isToday_1 = require("./isToday");
Object.defineProperty(exports, "isToday", { enumerable: true, get: function () { return isToday_1.isToday; } });
var isValidEmail_1 = require("./isValidEmail");
Object.defineProperty(exports, "isValidEmail", { enumerable: true, get: function () { return isValidEmail_1.isValidEmail; } });
__exportStar(require("./julianDate"), exports);
__exportStar(require("./math/Accuracy"), exports);
__exportStar(require("./math/Percent"), exports);
__exportStar(require("./math/Randomizer"), exports);
var numberToOrdinal_1 = require("./numberToOrdinal");
Object.defineProperty(exports, "numberToOrdinal", { enumerable: true, get: function () { return numberToOrdinal_1.numberToOrdinal; } });
var pickFrom_1 = require("./pickFrom");
Object.defineProperty(exports, "pickFrom", { enumerable: true, get: function () { return pickFrom_1.pickFrom; } });
var pickVideoToPlay_1 = require("./pickVideoToPlay");
Object.defineProperty(exports, "pickVideoToPlay", { enumerable: true, get: function () { return pickVideoToPlay_1.pickVideoToPlay; } });
__exportStar(require("./pluralize"), exports);
var removeExtraDataFromVideoLabel_1 = require("./removeExtraDataFromVideoLabel");
Object.defineProperty(exports, "removeExtraDataFromVideoSourceName", { enumerable: true, get: function () { return removeExtraDataFromVideoLabel_1.removeExtraDataFromVideoSourceName; } });
var roundToClosestEven_1 = require("./roundToClosestEven");
Object.defineProperty(exports, "roundToClosestEven", { enumerable: true, get: function () { return roundToClosestEven_1.roundToClosestEven; } });
var secondsToHHMMSS_1 = require("./secondsToHHMMSS");
Object.defineProperty(exports, "secondsToHHMMSS", { enumerable: true, get: function () { return secondsToHHMMSS_1.secondsToHHMMSS; } });
__exportStar(require("./sessionReportToDateAndAmountMap"), exports);
var shuffle_1 = require("./shuffle");
Object.defineProperty(exports, "shuffleArray", { enumerable: true, get: function () { return shuffle_1.shuffleArray; } });
__exportStar(require("./slug"), exports);
var sortAlphabeticallyBy_1 = require("./sortAlphabeticallyBy");
Object.defineProperty(exports, "sortAlphabeticallyBy", { enumerable: true, get: function () { return sortAlphabeticallyBy_1.sortAlphabeticallyBy; } });
var splitDurationIntoNumberAndUnitComponent_1 = require("./splitDurationIntoNumberAndUnitComponent");
Object.defineProperty(exports, "splitDurationIntoNumberAndUnitComponent", { enumerable: true, get: function () { return splitDurationIntoNumberAndUnitComponent_1.splitDurationIntoNumberAndUnitComponent; } });
__exportStar(require("./StackedRequests"), exports);
__exportStar(require("./textOnlyHasEmojis"), exports);
var timeScanner_1 = require("./timeScanner");
Object.defineProperty(exports, "isValidTime", { enumerable: true, get: function () { return timeScanner_1.isValidTime; } });
Object.defineProperty(exports, "timeScanner", { enumerable: true, get: function () { return timeScanner_1.timeScanner; } });
__exportStar(require("./timeToString"), exports);
__exportStar(require("./timezones"), exports);
__exportStar(require("./UnhandledCase"), exports);
__exportStar(require("./wait"), exports);
