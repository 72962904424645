"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ipcEventSchema = exports.IPC_EVENT_TYPE = exports.IPC_EVENT = void 0;
var zod_1 = require("zod");
var breakTimerEvent_1 = require("./breakTimerEvent");
var coachMessageEvent_1 = require("./coachMessageEvent");
var coachMessageWindowEvent_1 = require("./coachMessageWindowEvent");
var mainEvent_1 = require("./mainEvent");
var mainMachineEvent_1 = require("./mainMachineEvent");
var menuBarEvent_1 = require("./menuBarEvent");
var musicMachineEvent_1 = require("./musicMachineEvent");
var sessionMachineEvent_1 = require("./sessionMachineEvent");
var taskMachineEvent_1 = require("./taskMachineEvent");
var taskWindowEvent_1 = require("./taskWindowEvent");
exports.IPC_EVENT = 'centered:ipc';
exports.IPC_EVENT_TYPE = 'Send IPC Event';
exports.ipcEventSchema = zod_1.z.union([
    zod_1.z.object({
        to: zod_1.z.literal('Main Process'),
        destinationEvent: mainEvent_1.mainEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Break Timer'),
        destinationEvent: breakTimerEvent_1.breakTimerEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Coach Message Window'),
        destinationEvent: coachMessageWindowEvent_1.coachMessageWindowEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Coach Message Machine'),
        destinationEvent: coachMessageEvent_1.coachMessageEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Menu Bar'),
        destinationEvent: menuBarEvent_1.menuBarEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Music Machine'),
        destinationEvent: musicMachineEvent_1.musicMachineEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Session Machine'),
        destinationEvent: sessionMachineEvent_1.sessionMachineEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Task Machine'),
        destinationEvent: taskMachineEvent_1.taskMachineEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Task Window'),
        destinationEvent: taskWindowEvent_1.taskWindowEventSchema,
    }),
    zod_1.z.object({
        to: zod_1.z.literal('Main Machine'),
        destinationEvent: mainMachineEvent_1.mainMachineEventSchema,
    }),
]);
