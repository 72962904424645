"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var config_1 = require("../config");
function useFetchFriends(userId) {
    var _a = (0, react_1.useState)([]), followees = _a[0], setFollowees = _a[1];
    var _b = (0, react_1.useState)([]), followers = _b[0], setFollowers = _b[1];
    var _c = (0, react_1.useState)(true), loadingFollowees = _c[0], setLoadingFollowees = _c[1];
    var _d = (0, react_1.useState)(true), loadingFollowers = _d[0], setLoadingFollowers = _d[1];
    var loading = (0, react_1.useMemo)(function () {
        return loadingFollowees || loadingFollowers;
    }, [loadingFollowees, loadingFollowers]);
    (0, react_1.useEffect)(function () {
        var isMounted = true;
        if (!userId)
            return;
        setLoadingFollowees(true);
        var unsubscribe = config_1.db
            .collection('friends')
            .where('followee.id', '==', userId)
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            if (!isMounted)
                return;
            var _followers = [];
            snapshot.forEach(function (doc) {
                var data = doc.data();
                _followers.push(data.follower);
            });
            setFollowers(_followers.filter(function (f) { return !f.isAnonymous; }));
            setLoadingFollowees(false);
        }, function () {
            console.error('Error in friends followee snapshot listener');
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [userId]);
    (0, react_1.useEffect)(function () {
        var isMounted = true;
        if (!userId)
            return;
        setLoadingFollowers(true);
        var unsubscribe = config_1.db
            .collection('friends')
            .where('follower.id', '==', userId)
            .withConverter((0, config_1.converter)())
            .onSnapshot(function (snapshot) {
            if (!isMounted)
                return;
            var _followees = [];
            snapshot.forEach(function (doc) {
                var data = doc.data();
                if (data.followee) {
                    _followees.push(data.followee);
                }
            });
            setFollowees(_followees.filter(function (f) { return !f.isAnonymous; }));
            setLoadingFollowers(false);
        }, function () {
            console.error('Error in friends follower snapshot listener');
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [userId]);
    var _e = (0, react_1.useState)([]), centeredEmployees = _e[0], setCenteredEmployees = _e[1];
    (0, react_1.useEffect)(function () {
        var isMounted = true;
        if (!userId)
            return;
        config_1.db.collection('users')
            .where('centeredEmployee', '==', true)
            .withConverter((0, config_1.converter)())
            .get()
            .then(function (_a) {
            var docs = _a.docs;
            if (isMounted) {
                setCenteredEmployees(docs.map(function (d) { return d.data(); }));
            }
        })
            .catch(function (e) {
            console.error('Error in fetchCenteredEmployee', e);
        });
        return function () {
            isMounted = false;
        };
    }, [userId]);
    return {
        followers: followers,
        followees: followees,
        loading: loading,
        centeredEmployees: centeredEmployees,
    };
}
exports.default = useFetchFriends;
