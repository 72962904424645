"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var FirebaseContext_1 = require("../FirebaseContext");
var config_1 = require("../config");
function useEmail() {
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    var sendInvite = (0, react_1.useCallback)(function (email) {
        config_1.db.collection('invites').doc(email).set({
            email: email,
            name: currentUser === null || currentUser === void 0 ? void 0 : currentUser.name,
        });
    }, [config_1.db, currentUser === null || currentUser === void 0 ? void 0 : currentUser.name]);
    return {
        sendInvite: sendInvite,
    };
}
exports.default = useEmail;
