"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGroupLeaderUsers = exports.useGroupLeaders = exports.GroupLeaderProvider = exports.defaultContext = void 0;
var React = require("react");
var __1 = require("..");
var react_1 = require("react");
exports.defaultContext = {
    groupId: undefined,
    leaders: [],
    publicUsers: [],
};
var GroupLeaderContext = (0, react_1.createContext)(exports.defaultContext);
var GroupLeaderProvider = function (_a) {
    var children = _a.children, groupId = _a.groupId;
    var _b = (0, react_1.useState)([]), leaders = _b[0], setLeaders = _b[1];
    var _c = (0, react_1.useState)([]), publicUsers = _c[0], setPublicUsers = _c[1];
    (0, react_1.useEffect)(function () {
        if (!groupId) {
            setLeaders([]);
            return;
        }
        return __1.db
            .collection("/groups/".concat(groupId, "/group-admins"))
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            setLeaders(snapshot.docs.map(function (d) { return d.data(); }));
        }, function (error) {
            console.error('GroupLeaderProvider error: ', error);
        });
    }, [groupId]);
    (0, react_1.useEffect)(function () {
        if (!leaders || leaders.length === 0) {
            return;
        }
        return __1.db
            .collection("/users")
            .where('id', 'in', leaders.map(function (l) { return l.id; }).slice(0, 10))
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            var publicUsers = snapshot.docs
                .map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); })
                .sort(function (a, b) { return a.name.localeCompare(b.name); });
            setPublicUsers(publicUsers);
        }, function (error) {
            console.error('GroupLeaderProvider error: ', error);
        });
    }, [leaders]);
    return (React.createElement(GroupLeaderContext.Provider, { value: { leaders: leaders, publicUsers: publicUsers, groupId: groupId } }, children));
};
exports.GroupLeaderProvider = GroupLeaderProvider;
function useGroupLeaders() {
    var state = (0, react_1.useContext)(GroupLeaderContext);
    return (state !== null && state !== void 0 ? state : exports.defaultContext).leaders;
}
exports.useGroupLeaders = useGroupLeaders;
function useGroupLeaderUsers() {
    var state = (0, react_1.useContext)(GroupLeaderContext);
    return {
        users: (state !== null && state !== void 0 ? state : exports.defaultContext).publicUsers,
        groupId: state === null || state === void 0 ? void 0 : state.groupId,
    };
}
exports.useGroupLeaderUsers = useGroupLeaderUsers;
