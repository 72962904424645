"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useManageFeedUpdate = void 0;
var __1 = require("..");
var react_1 = require("react");
var config_1 = require("../config");
function useManageFeedUpdate(_a) {
    var _this = this;
    var feedUpdateId = _a.feedUpdateId, feedUserId = _a.feedUserId, defaultPreviewReactions = _a.defaultPreviewReactions, defaultReaction = _a.defaultReaction, defaultReactionCounts = _a.defaultReactionCounts, visibleInViewport = _a.visibleInViewport, groupId = _a.groupId;
    var currentUser = (0, __1.useCurrentUser)().currentUser;
    // We want to optimistically update these variables and not have to wait for the server
    // All these variables are so we can perform the update locally
    var _b = (0, react_1.useState)(defaultReaction), localReaction = _b[0], setLocalReaction = _b[1];
    var _c = (0, react_1.useState)(defaultReactionCounts), localReactionCount = _c[0], setLocalReactionCount = _c[1];
    var _d = (0, react_1.useState)(defaultPreviewReactions), localPreviewReactions = _d[0], setLocalPreviewReactions = _d[1];
    var reactToPostOnBackend = (0, react_1.useCallback)(function (type, updateId) {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        config_1.db.runTransaction(function (tx) { return __awaiter(_this, void 0, void 0, function () {
            var newReactionRef, updateDocRef, updateDoc, shouldInlineReaction, newReaction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newReactionRef = config_1.db
                            .collection("users/".concat(feedUserId, "/feed-updates/").concat(updateId, "/reactions"))
                            .doc(currentUser.id);
                        updateDocRef = config_1.db.doc("/users/".concat(currentUser.id, "/feed-timeline/").concat(updateId));
                        return [4 /*yield*/, tx.get(updateDocRef)];
                    case 1:
                        updateDoc = _a.sent();
                        shouldInlineReaction = updateDoc.exists;
                        if (!type) {
                            tx.delete(newReactionRef);
                            if (!groupId && shouldInlineReaction) {
                                tx.update(updateDocRef, {
                                    myReaction: null,
                                });
                            }
                        }
                        else {
                            newReaction = {
                                userId: currentUser.id,
                                name: currentUser.name,
                                avatarUrl: currentUser.avatarUrl,
                                reaction: type,
                                updateId: updateId,
                                createdAt: Date.now(),
                            };
                            tx.set(newReactionRef, newReaction);
                            if (!groupId && shouldInlineReaction) {
                                tx.update(updateDocRef, {
                                    myReaction: newReaction,
                                });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    }, [
        currentUser === null || currentUser === void 0 ? void 0 : currentUser.avatarUrl,
        currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
        currentUser === null || currentUser === void 0 ? void 0 : currentUser.name,
        feedUserId,
        groupId,
    ]);
    var handleToggleReaction = (0, react_1.useCallback)(function (reaction) {
        var _a, _b;
        if (!currentUser) {
            return;
        }
        var newLocalReaction = localReaction ? __assign({}, localReaction) : null;
        var newLocalReactionCount = __assign({}, localReactionCount);
        var newLocalPreviewReactions = Array.from(localPreviewReactions);
        var action;
        if (localReaction) {
            // Remove old reaction
            newLocalReactionCount[localReaction.reaction] =
                ((_a = newLocalReactionCount[localReaction.reaction]) !== null && _a !== void 0 ? _a : 1) - 1;
            var reactionIdx = newLocalPreviewReactions.findIndex(function (r) { return r.userId === currentUser.id; });
            if (reactionIdx > -1) {
                newLocalPreviewReactions.splice(reactionIdx, 1);
            }
            if (localReaction.reaction !== reaction) {
                action = 'react-to-post';
            }
            else {
                action = 'unreact-to-post';
                newLocalReaction = null;
            }
        }
        else {
            action = 'react-to-post';
        }
        if (action === 'react-to-post') {
            newLocalReactionCount[reaction] =
                ((_b = newLocalReactionCount[reaction]) !== null && _b !== void 0 ? _b : 0) + 1;
            newLocalReaction = {
                id: 'fake-id',
                userId: currentUser.id,
                name: currentUser.name,
                avatarUrl: currentUser.avatarUrl,
                reaction: reaction,
                updateId: feedUpdateId,
                createdAt: Date.now(),
            };
            if (newLocalPreviewReactions.length < 3) {
                newLocalPreviewReactions.push(newLocalReaction);
            }
        }
        else if (action === 'unreact-to-post') {
            newLocalReaction = null;
        }
        setLocalReactionCount(newLocalReactionCount);
        setLocalReaction(newLocalReaction);
        setLocalPreviewReactions(newLocalPreviewReactions);
        reactToPostOnBackend((localReaction === null || localReaction === void 0 ? void 0 : localReaction.reaction) === reaction ? null : reaction, feedUpdateId);
    }, [
        currentUser,
        feedUpdateId,
        localPreviewReactions,
        localReaction,
        localReactionCount,
        reactToPostOnBackend,
    ]);
    (0, react_1.useEffect)(function () {
        if (!visibleInViewport) {
            return;
        }
        // If we're showing the demo card don't try to pull it's reactions
        if (feedUpdateId === 'demo') {
            return;
        }
        var unsubscribe = config_1.db
            .collection("/users/".concat(feedUserId, "/feed-updates/").concat(feedUpdateId, "/reactions"))
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            var _a, _b;
            var counts = {};
            var reactions = [];
            for (var _i = 0, _c = snapshot.docs; _i < _c.length; _i++) {
                var doc = _c[_i];
                var data = doc.data();
                counts[data.reaction] = ((_a = counts[data.reaction]) !== null && _a !== void 0 ? _a : 0) + 1;
                reactions.push(data);
            }
            reactions.sort(function (a, z) { return (a.createdAt > z.createdAt ? 1 : -1); });
            setLocalPreviewReactions(reactions.slice(0, 3));
            setLocalReactionCount(counts);
            setLocalReaction((_b = reactions.find(function (r) { return r.userId === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id); })) !== null && _b !== void 0 ? _b : null);
        }, function (error) {
            console.error('useManageFeedUpdate error: ', error);
        });
        return function () {
            unsubscribe();
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, feedUpdateId, feedUserId, visibleInViewport]);
    return {
        reactToUpdate: handleToggleReaction,
        previewReactions: localPreviewReactions,
        reaction: localReaction,
        reactionsCount: localReactionCount,
    };
}
exports.useManageFeedUpdate = useManageFeedUpdate;
