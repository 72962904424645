"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultUserPremiumSettings = exports.LoaderLottieData = exports.FLOWHALLS_URL = exports.SIGN_IN_URL = exports.ERROR_EMAIL_EXIST_RESPONSE = exports.ERROR_EMAIL_EXIST = exports.ERROR_WRONG_NAME = exports.ERROR_WRONG_PASSWORD = exports.ERROR_WRONG_EMAIL = exports.ERROR_USER_EXIST = exports.ERROR_WRONG_EMAIL_OR_PASSWORD = exports.MONTH = exports.YEAR_SUB_COST = exports.MONTH_SUB_COST = exports.emailRegex = exports.BORDER_WIDTH = exports.DEFAULT_DRAWER_GAP_WIDTH = exports.DEFAULT_DRAWER_WIDTH = exports.START_SESSION_TIMER_TIME = exports.SCROLLBAR_WIDTH = exports.CALENDAR_HOUR_HEIGHT = exports.CALENDAR_SESSION_XS_WIDTH = exports.CALENDAR_RIGHT_PADDING = exports.CALENDAR_LEFT_OFFSET = exports.MINIMUM_TASK_WIDTH = exports.MINUTES_IN_HOUR = exports.SPOTIFY_SHUFFLE_DEFAULT = exports.TASK_BUCKET_TITLE_MAX_LENGTH = exports.TASK_NAME_MAX_LENGTH = exports.ANONYMOUS_USER_NAMES = exports.FIREBASE_CLOUD_FUNCTIONS_URL = exports.MAX_FREE_TASKS = exports.COACH_MESSAGES_CDN_URL = exports.TIME_IT_TAKES_TO_FLOW_IN_SECONDS = exports.MKT_SITE_BG_COLOR = exports.ONBOARDING_URL = exports.SUPPORT_URL = exports.TRIGGER_IDLE_TIME_AFTER_SECONDS = exports.WEB_URL = exports.REQUEST_NEW_GROUP_URL = exports.NO_AUDIO_ID = exports.NO_VIDEO_ID = exports.MAX_SECONDS_WORKING_OUTSIDE_CENTERED = exports.DEFAULT_TASK_TIME = exports.CHROME_EXTENSION_LINK = exports.INTRO_FLOWHALL_ID = exports.MAX_INTENTION_SIZE = void 0;
exports.MAX_INTENTION_SIZE = 50;
exports.INTRO_FLOWHALL_ID = 'sukha-tribe';
exports.CHROME_EXTENSION_LINK = 'https://chrome.google.com/webstore/detail/ignpidjpincpephfcclnaicfoahbbdig';
exports.DEFAULT_TASK_TIME = 25 * 60;
// Don't accept more than 1h of work outside Centered
exports.MAX_SECONDS_WORKING_OUTSIDE_CENTERED = 60 * 60;
exports.NO_VIDEO_ID = 'novideo';
exports.NO_AUDIO_ID = 'noaudio';
exports.REQUEST_NEW_GROUP_URL = 'https://calendly.com/steven-sukha/25min';
exports.WEB_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:1701'
    : process.env.FLAVOR
        ? "https://".concat(process.env.FLAVOR, ".thesukha.co")
        : "https://".concat(process.env.CENTERED_BASE_URL ||
            process.env.NEXT_PUBLIC_CENTERED_BASE_URL ||
            'thesukha.co');
exports.TRIGGER_IDLE_TIME_AFTER_SECONDS = 5 * 60;
exports.SUPPORT_URL = 'https://thesukha.canny.io/feature-requests';
exports.ONBOARDING_URL = 'https://calendly.com/steven-sukha/25min';
exports.MKT_SITE_BG_COLOR = '#0d0d0d';
// Pro tip: You can mess with this to debug the flowmeter. Just change it back.
exports.TIME_IT_TAKES_TO_FLOW_IN_SECONDS = 300; // 5 minutes
exports.COACH_MESSAGES_CDN_URL = 'https://d10gj7r7js3e4x.cloudfront.net';
exports.MAX_FREE_TASKS = 10;
exports.FIREBASE_CLOUD_FUNCTIONS_URL = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-centered-1580668301240.cloudfunctions.net'
    : 'https://us-central1-centered-staging.cloudfunctions.net';
exports.ANONYMOUS_USER_NAMES = [
    '🐶',
    '🐱',
    '🐭',
    '🐹',
    '🐰',
    '🦊',
    '🐻',
    '🐼',
    '🐻',
    '🐨',
    '🐯',
    '🦁',
    '🐮',
    '🐷',
    '🐽',
    '🐸',
    '🐵',
    '🙈',
    '🙉',
    '🙊',
    '🐒',
    '🐔',
    '🐧',
    '🐦',
    '🐤',
    '🐣',
    '🐥',
    '🦆',
    '🦅',
    '🦉',
    '🦇',
    '🐺',
    '🐗',
    '🐴',
    '🦄',
    '🐝',
    '🪱',
    '🐛',
    '🦋',
    '🐌',
    '🐞',
    '🐜',
    '🪰',
    '🪲',
    '🪳',
    '🦟',
    '🦗',
    '🕷',
    '🕸',
    '🦂',
    '🐢',
    '🐍',
    '🦎',
    '🦖',
    '🦕',
    '🐙',
    '🦑',
    '🦐',
    '🦞',
    '🦀',
    '🐡',
    '🐠',
    '🐟',
    '🐬',
    '🐳',
    '🐋',
    '🦈',
    '🐊',
    '🐅',
    '🐆',
    '🦓',
    '🦍',
    '🦧',
    '🦣',
    '🐘',
    '🦛',
    '🦏',
    '🐪',
    '🐫',
    '🦒',
    '🦘',
    '🦬',
    '🐃',
    '🐂',
    '🐄',
    '🐎',
    '🐖',
    '🐏',
    '🐑',
    '🦙',
    '🐐',
    '🦌',
    '🐕',
    '🐩',
    '🦮',
    '🐈',
    '🪶',
    '🐓',
    '🦃',
    '🦤',
    '🦚',
    '🦜',
    '🦢',
    '🦩',
    '🕊',
    '🐇',
    '🦝',
    '🦨',
    '🦡',
    '🦫',
    '🦦',
    '🦥',
    '🐁',
    '🐀',
    '🐿',
    '🦔',
];
exports.TASK_NAME_MAX_LENGTH = 1000;
exports.TASK_BUCKET_TITLE_MAX_LENGTH = 100;
/** We think shuffle is a better UX, so it's `true` by default */
exports.SPOTIFY_SHUFFLE_DEFAULT = true;
exports.MINUTES_IN_HOUR = 60;
exports.MINIMUM_TASK_WIDTH = 160;
exports.CALENDAR_LEFT_OFFSET = 47;
exports.CALENDAR_RIGHT_PADDING = 3;
exports.CALENDAR_SESSION_XS_WIDTH = 120;
exports.CALENDAR_HOUR_HEIGHT = 60;
exports.SCROLLBAR_WIDTH = 9;
exports.START_SESSION_TIMER_TIME = 30; // minutes
exports.DEFAULT_DRAWER_WIDTH = 270;
exports.DEFAULT_DRAWER_GAP_WIDTH = 10;
exports.BORDER_WIDTH = 5;
exports.emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
exports.MONTH_SUB_COST = 10;
exports.YEAR_SUB_COST = 80;
exports.MONTH = 12;
exports.ERROR_WRONG_EMAIL_OR_PASSWORD = 'Incorrect email or password.';
exports.ERROR_USER_EXIST = 'Could not create that user. Did you already register?';
exports.ERROR_WRONG_EMAIL = 'That email seems to have an error - could you double check it?';
exports.ERROR_WRONG_PASSWORD = 'For security reasons, could you use a password with at least 6 characters?';
exports.ERROR_WRONG_NAME = 'Could you use a name with at least 2 characters.';
exports.ERROR_EMAIL_EXIST = 'Could not create that user. Did you already use that email?';
exports.ERROR_EMAIL_EXIST_RESPONSE = "Error invoking remote method 'updateUser': Error: GraphQL error: duplicate key value violates unique constraint \"user_email_key\"";
exports.SIGN_IN_URL = "".concat(exports.WEB_URL, "/auth/connect-desktop");
exports.FLOWHALLS_URL = "".concat(exports.WEB_URL, "/flowhalls");
exports.LoaderLottieData = {
    v: '4.6.9',
    fr: 29.9700012207031,
    ip: 0,
    op: 34.0000013848484,
    w: 800,
    h: 600,
    nm: 'Loader',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Shape Layer 2',
            ks: {
                o: { a: 0, k: 100 },
                r: { a: 0, k: 0 },
                p: { a: 0, k: [400, 300, 0] },
                a: { a: 0, k: [0, 0, 0] },
                s: { a: 0, k: [100, 100, 100] },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [36.812, 0],
                                        [0, -19.33],
                                        [-19.33, 0],
                                        [-37.125, 0],
                                        [0, -19.33],
                                        [19.33, 0],
                                    ],
                                    o: [
                                        [-19.33, 0],
                                        [0, 19.33],
                                        [36.688, 0],
                                        [19.33, 0],
                                        [0, 19.33],
                                        [-37.25, 0],
                                    ],
                                    v: [
                                        [-44.844, -35],
                                        [-79.844, 0],
                                        [-44.844, 35],
                                        [44.844, -35],
                                        [79.844, 0],
                                        [44.844, 35],
                                    ],
                                    c: true,
                                },
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.105882, 0.105882, 0.105882, 1] },
                            o: { a: 0, k: 100 },
                            w: { a: 0, k: 12 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0_1_0p167_0p167'],
                                t: 5.834,
                                s: [0],
                                e: [20],
                            },
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0p833_0p833_0p167_0p167'],
                                t: 17.11,
                                s: [20],
                                e: [20],
                            },
                            {
                                i: { x: [0.611], y: [1] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0p611_1_0p167_0p167'],
                                t: 23.334,
                                s: [20],
                                e: [40],
                            },
                            { t: 35.0000014255792 },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0_1_0p167_0p167'],
                                t: 0,
                                s: [12],
                                e: [32],
                            },
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0p833_0p833_0p167_0p167'],
                                t: 11.666,
                                s: [32],
                                e: [32],
                            },
                            {
                                i: { x: [0.5], y: [1] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0p5_1_0p167_0p167'],
                                t: 17.11,
                                s: [32],
                                e: [53],
                            },
                            { t: 29.1662511879657 },
                        ],
                        ix: 2,
                    },
                    o: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0p667_1_0p167_0p167'],
                                t: 0,
                                s: [-22],
                                e: [82.925],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.167], y: [0.167] },
                                n: ['0p667_1_0p167_0p167'],
                                t: 17.11,
                                s: [82.925],
                                e: [193],
                            },
                            { t: 35.0000014255792 },
                        ],
                        ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                },
            ],
            ip: 0,
            op: 300.00001221925,
            st: 0,
            bm: 0,
            sr: 1,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Shape Layer 1',
            ks: {
                o: { a: 0, k: 25 },
                r: { a: 0, k: 0 },
                p: { a: 0, k: [400, 300, 0] },
                a: { a: 0, k: [0, 0, 0] },
                s: { a: 0, k: [100, 100, 100] },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [36.812, 0],
                                        [0, -19.33],
                                        [-19.33, 0],
                                        [-37.125, 0],
                                        [0, -19.33],
                                        [19.33, 0],
                                    ],
                                    o: [
                                        [-19.33, 0],
                                        [0, 19.33],
                                        [36.688, 0],
                                        [19.33, 0],
                                        [0, 19.33],
                                        [-37.25, 0],
                                    ],
                                    v: [
                                        [-44.844, -35],
                                        [-79.844, 0],
                                        [-44.844, 35],
                                        [44.844, -35],
                                        [79.844, 0],
                                        [44.844, 35],
                                    ],
                                    c: true,
                                },
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.105882, 0.105882, 0.105882, 1] },
                            o: { a: 0, k: 100 },
                            w: { a: 0, k: 12 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                },
            ],
            ip: 0,
            op: 300.00001221925,
            st: 0,
            bm: 0,
            sr: 1,
        },
    ],
};
exports.defaultUserPremiumSettings = {
    defaultBreakTime: 5 * 60,
    defaultFlowDuration: 25 * 60,
    defaultIdleTimeDuration: 10 * 60,
    hideMainWindowOnFlow: true,
    privateStats: false,
    meetingWarningEnabled: true,
    secondsBeforeMeetingWarning: 5 * 60,
    onFlowStartScriptEnabled: false,
    onFlowEndScriptEnabled: false,
    notificationMinimumWorkTime: 10 * 60,
    useScheduledSessionNotification: true,
    useFriendWorkingNotification: true,
    timersCountDown: false,
    enableBlurredVideo: false,
    sessionTimer: 'break-timer',
    buddySessionVideoBackgroundEnabled: false,
    videoBackgroundEnabled: false,
    videoBackgroundUrls: [],
};
