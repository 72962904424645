"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.followFriend = exports.unfollowFriend = void 0;
var config_1 = require("../config");
var unfollowFriend = function (currentUser, followeeId) {
    return config_1.db
        .collection('friends')
        .where('follower.id', '==', currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)
        .where('followee.id', '==', followeeId)
        .get()
        .then(function (data) {
        data.docs.forEach(function (doc) {
            return doc.ref.delete().catch(function (e) {
                console.error('Error in deleting connecting in unfollowFriend', followeeId, e);
            });
        });
    })
        .catch(function (e) {
        console.error('Could not unfollow user', followeeId, e);
    });
};
exports.unfollowFriend = unfollowFriend;
var followFriend = function (currentUser, followee) { return __awaiter(void 0, void 0, void 0, function () {
    var doc;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (currentUser.id === followee.id) {
                    return [2 /*return*/];
                }
                if (!(followee === null || followee === void 0 ? void 0 : followee.id)) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, isAlreadyFollowing(currentUser.id, followee.id)];
            case 1:
                if (_b.sent()) {
                    console.log("User ".concat(currentUser.name, " already follows ").concat(followee.name));
                    return [2 /*return*/];
                }
                doc = {
                    createdAt: Date.now(),
                    follower: {
                        id: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
                        name: currentUser === null || currentUser === void 0 ? void 0 : currentUser.name,
                        avatarUrl: currentUser === null || currentUser === void 0 ? void 0 : currentUser.avatarUrl,
                        bio: currentUser === null || currentUser === void 0 ? void 0 : currentUser.bio,
                        sessionId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.sessionId,
                        flowState: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.flowState) === null || _a === void 0 ? void 0 : _a.state,
                        username: currentUser.username,
                        groupId: currentUser.settings.defaultFlowhallId,
                        isAnonymous: currentUser.isAnonymous,
                    },
                    followee: followee,
                };
                return [4 /*yield*/, config_1.db.collection('friends').add(doc)];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.followFriend = followFriend;
function isAlreadyFollowing(userId, followeeId) {
    return __awaiter(this, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, config_1.db
                            .collection('friends')
                            .where('follower.id', '==', userId)
                            .where('followee.id', '==', followeeId)
                            .get()];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, !result.empty];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/, false];
            }
        });
    });
}
