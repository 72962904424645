"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var manageUser_1 = require("../helpers/manageUser");
var config_1 = require("../config");
var FirebaseContext_1 = require("../FirebaseContext");
var FIREBASE_ENV = process.env.NEXT_PUBLIC_FIREBASE_ENV || process.env.FIREBASE_ENV || 'staging';
var usePushnotifications = function () {
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    (0, react_1.useEffect)(function () {
        var run = function () { return __awaiter(void 0, void 0, void 0, function () {
            var serviceWorkerRegistration, token, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!config_1.firebase.messaging.isSupported() || !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
                            return [2 /*return*/];
                        if (!('serviceWorker' in navigator && config_1.vapidKey)) return [3 /*break*/, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, navigator.serviceWorker.register("/firebase-messaging-sw-".concat(FIREBASE_ENV, ".js"))];
                    case 2:
                        serviceWorkerRegistration = _a.sent();
                        return [4 /*yield*/, config_1.firebase.messaging().getToken({
                                vapidKey: config_1.vapidKey,
                                serviceWorkerRegistration: serviceWorkerRegistration,
                            })];
                    case 3:
                        token = _a.sent();
                        return [4 /*yield*/, (0, manageUser_1.updatePushTokens)(token)];
                    case 4:
                        _a.sent();
                        config_1.firebase.messaging().onMessage(function (obj) { return __awaiter(void 0, void 0, void 0, function () {
                            var notification, registrations, activeRegistration, e_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        notification = obj.notification;
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, navigator.serviceWorker.getRegistrations()];
                                    case 2:
                                        registrations = _a.sent();
                                        activeRegistration = registrations[0];
                                        activeRegistration.showNotification(notification.title, {
                                            body: notification.body,
                                            data: {
                                                FCM_MSG: {
                                                    data: obj.data,
                                                },
                                            },
                                        });
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_2 = _a.sent();
                                        console.error('No serviceWorker registrations', e_2);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                        console.info('[firebase-messaging-sw] Service Worker registration successful');
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        if (e_1.code !== 'messaging/permission-blocked') {
                            console.error('[firebase-messaging-sw] Error updating the service worker', e_1);
                        }
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        console.log('[firebase-messaging-sw] no service workers supported.');
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        run();
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
};
exports.default = usePushnotifications;
