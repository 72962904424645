"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUser = void 0;
var __1 = require("..");
var react_1 = require("react");
function useUser(userId) {
    var _a = (0, react_1.useState)(undefined), userFromId = _a[0], setUserFromId = _a[1];
    var _b = (0, react_1.useState)(undefined), userFromUsername = _b[0], setUserFromUsername = _b[1];
    var _c = (0, react_1.useState)(true), loading = _c[0], setLoading = _c[1];
    (0, react_1.useEffect)(function () {
        if (!userId) {
            setUserFromId(undefined);
            setLoading(false);
            return;
        }
        setLoading(true);
        return __1.db
            .collection('users')
            .doc(userId)
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            setLoading(false);
            var data = snapshot.data();
            if (data) {
                setUserFromId(__assign(__assign({}, data), { id: snapshot.id }));
            }
            else {
                setUserFromId(undefined);
            }
        }, function (err) {
            console.error('Error in useUser snapshot', err);
        });
    }, [userId]);
    (0, react_1.useEffect)(function () {
        if (!userId)
            return;
        return __1.db
            .collection('users')
            .where('username', '==', userId)
            .withConverter((0, __1.converter)())
            .onSnapshot(function (snapshot) {
            var _a;
            var data = (_a = snapshot.docs[0]) === null || _a === void 0 ? void 0 : _a.data();
            if (data) {
                setUserFromUsername(__assign(__assign({}, data), { id: snapshot.docs[0].id }));
            }
            else {
                setUserFromUsername(undefined);
            }
        }, function (err) {
            console.error('Error in useUser snapshot', err);
        });
    }, [userId]);
    return {
        user: userFromId || userFromUsername,
        loading: loading,
    };
}
exports.useUser = useUser;
