"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.julianToJSDate = exports.epochTimeToJulianDate = exports.toJulianDate = void 0;
// This code is duplicated in backend/firebase/ because it can't import this package
var MINUTES_PER_HOUR = 60.0;
var SECONDS_IN_MINUTE = 60.0;
var MILLISECONDS = 1000;
var MILLISECONDS_IN_A_DAY = 24 * MINUTES_PER_HOUR * SECONDS_IN_MINUTE * MILLISECONDS;
var toJulianDate = function (time, timeZoneOffset) {
    if (timeZoneOffset === void 0) { timeZoneOffset = new Date().getTimezoneOffset(); }
    var utcTime = time - timeZoneOffset * SECONDS_IN_MINUTE * MILLISECONDS;
    return Math.floor(utcTime / MILLISECONDS_IN_A_DAY);
};
exports.toJulianDate = toJulianDate;
var epochTimeToJulianDate = function (epochTime) {
    return Math.floor(epochTime / MILLISECONDS_IN_A_DAY);
};
exports.epochTimeToJulianDate = epochTimeToJulianDate;
var julianToJSDate = function (jDate, timeZoneOffset) {
    if (timeZoneOffset === void 0) { timeZoneOffset = new Date().getTimezoneOffset(); }
    var utcTime = jDate * MILLISECONDS_IN_A_DAY +
        timeZoneOffset * SECONDS_IN_MINUTE * MILLISECONDS;
    return new Date(utcTime);
};
exports.julianToJSDate = julianToJSDate;
