"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialsFromName = void 0;
function getInitialsFromName(name) {
    if (!name) {
        console.warn('getInitialsFromName: name is required');
        return '';
    }
    if (name.length <= 3)
        return name;
    return name
        .split(' ')
        .map(function (word) { return word[0]; })
        .join('')
        .replace(/[^a-zA-Z0-9]+/g, '');
}
exports.getInitialsFromName = getInitialsFromName;
