import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { saveOriginationMetadata } from '../lib/originationMetadata'

export default function useSaveOriginationMetadata(): void {
  const router = useRouter()

  useEffect(() => {
    saveOriginationMetadata({
      copyParam: router.query.copy as string,
      utmCampaign: router.query.utm_campaign as string,
      utmContent: router.query.utm_content as string,
      utmMedium: router.query.utm_medium as string,
      utmSource: router.query.utm_source as string,
      utmTerm: router.query.utm_term as string,
    })
  }, [router.query])
}
