"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var __1 = require("..");
var config_1 = require("../config");
function useFetchDistractionList() {
    var _a = (0, react_1.useState)({
        urls: [],
        bundleIds: [],
    }), distractionList = _a[0], setDistractionList = _a[1];
    var currentUser = (0, __1.useCurrentUser)().currentUser;
    (0, react_1.useEffect)(function () {
        var isMounted = true;
        config_1.db.collection('distraction-list')
            .where(config_1.firebase.firestore.FieldPath.documentId(), 'in', ['common', currentUser === null || currentUser === void 0 ? void 0 : currentUser.id].filter(function (t) { return t; }))
            .withConverter((0, config_1.converter)())
            .get()
            .then(function (snapshot) {
            var urls = [];
            var bundleIds = [];
            snapshot.docs.forEach(function (doc) {
                var data = doc.data();
                urls = urls.concat(data.urls || []);
                bundleIds = bundleIds.concat(data.bundleIds || []);
            });
            if (isMounted) {
                setDistractionList({
                    urls: urls,
                    bundleIds: bundleIds,
                });
            }
        })
            .catch(function (e) { return console.error(e); });
        return function () {
            isMounted = false;
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return distractionList;
}
exports.default = useFetchDistractionList;
