"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackedRequests = void 0;
var wait_1 = require("./wait");
/**
 * Stack async requests to ensure they are executed in order.
 * This is useful if you want to fire-and-forget requests, but still
 * expect them to execute in order.
 */
var StackedRequests = /** @class */ (function () {
    function StackedRequests(onError, options) {
        if (options === void 0) { options = {}; }
        var _a;
        this.onError = onError;
        this.requests = [];
        this.msBetweenRequests = (_a = options.msBetweenRequests) !== null && _a !== void 0 ? _a : 0;
    }
    StackedRequests.prototype.add = function (request) {
        var shouldFireDirectly = this.requests.length === 0;
        this.requests.push(request);
        if (shouldFireDirectly) {
            this.fireNextRequest();
        }
    };
    StackedRequests.prototype.fireNextRequest = function () {
        var _this = this;
        var request = this.requests[0];
        if (!request)
            return;
        request()
            .then(function () { return _this.requests.shift(); })
            .then(function () { return (0, wait_1.wait)(_this.msBetweenRequests); })
            .then(function () { return _this.fireNextRequest(); })
            .catch(function (error) { return _this.onError(error.message); });
    };
    return StackedRequests;
}());
exports.StackedRequests = StackedRequests;
