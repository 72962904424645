"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseUrl = void 0;
var getBaseUrl = function (url) {
    try {
        var baseUrl = new URL(url).origin;
        return !baseUrl || baseUrl === 'null' ? url : baseUrl;
    }
    catch (_a) {
        console.error('Error parsing url:', url);
        return url;
    }
};
exports.getBaseUrl = getBaseUrl;
