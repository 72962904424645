"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDaysOfWeekForLocale = exports.dayOfWeekAbbreviations = exports.daysOfWeek = void 0;
exports.daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
exports.dayOfWeekAbbreviations = {
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
};
var getDaysOfWeekForLocale = function (localeOrString) {
    // @ts-ignore
    var locale = new Intl.Locale(localeOrString); // weekInfo is not present in Node
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/weekInfo#browser_compatibility
    if (!('weekInfo' in locale))
        return exports.daysOfWeek;
    var firstDay = locale.weekInfo.firstDay;
    if (firstDay === 7 /* Sunday */)
        return exports.daysOfWeek;
    var result = [];
    for (var index = firstDay; result.length < exports.daysOfWeek.length; index++) {
        result.push(exports.daysOfWeek[index % exports.daysOfWeek.length]);
    }
    return result;
};
exports.getDaysOfWeekForLocale = getDaysOfWeekForLocale;
