"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeExtraDataFromVideoSourceName = void 0;
// Device names sometimes are formatted as such: Facetime HD camera (101:54)
// Where the (101:54) is useless information for the user
// This function helps remove the extra data so the UI is cleaner
function removeExtraDataFromVideoSourceName(currentLabel) {
    var splitLabel = currentLabel.split(' ');
    if (splitLabel.length > 1 &&
        splitLabel[splitLabel.length - 1].startsWith('(')) {
        splitLabel.pop();
    }
    return splitLabel.join(' ');
}
exports.removeExtraDataFromVideoSourceName = removeExtraDataFromVideoSourceName;
