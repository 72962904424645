"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.secondsToHHMMSS = void 0;
var secondsToHHMMSS = function (seconds) {
    if (seconds === undefined || seconds === 0) {
        return '00:00';
    }
    var date = new Date(seconds * 1000);
    if (!date) {
        return '';
    }
    try {
        var _s = date.toISOString().substr(11, 8);
        if (_s.substr(0, 3) === '00:') {
            return _s.substr(3, _s.length);
        }
        return _s;
    }
    catch (e) {
        return '';
    }
};
exports.secondsToHHMMSS = secondsToHHMMSS;
