import Head from 'next/head';
import { trackPage } from '@centered/analytics'
import '@centered/app/app-shared-styles.css'
import { ComponentsProvider } from '@centered/components/dist/hooks/useComponentsContext'
import '@centered/components/shared-component-styles.css'
import { FirebaseProvider } from '@centered/firebase'
import { defaultPlatform, initializePlatform } from '@centered/platform'
import '@draft-js-plugins/emoji/lib/plugin.css'
import '@draft-js-plugins/linkify/lib/plugin.css'
import '@draft-js-plugins/mention/lib/plugin.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { Analytics } from '@vercel/analytics/react'
import 'allotment/dist/style.css'
import 'draft-js/dist/Draft.css'
import type { AppProps } from 'next/app'
import Router from 'next/router'
import { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import TrackerContextProvider from '../components/TrackerContext'
import RewardsImage from '../components/app/assets/rewards.jpg'
import SharedLink from '../components/shared/SharedLink'
import useSaveOriginationMetadata from '../hooks/useSaveOriginationMetadata'
import '../styles/globals.css'
import '../styles/player.css'
import '../styles/tailwind.css'

initializePlatform({ ...defaultPlatform, type: 'web' })

config.autoAddCss = false

const componentsContext = {
  Link: SharedLink,
  rewardsImageLink: RewardsImage.src,
  explainCenteredVideoLink: 'assets/how-centered-works.mp4',
}

function ContentWrapper({ Component, pageProps }: AppProps): JSX.Element {
  useSaveOriginationMetadata()

  return (
    <div>
    <Head>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-JRWV95L1Y4"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-JRWV95L1Y4');
          `,
        }}
      />
    </Head>
    
    <TrackerContextProvider>
      <Component {...pageProps} />
      </TrackerContextProvider>
    </div>
  )
}

function MyApp(props: AppProps): JSX.Element {
  useEffect(() => {
    function handleRouteChange(path: string) {
      trackPage(path)
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    const listener = () => {
      trackPage(window.location.pathname)
    }

    window.addEventListener('load', listener)

    return () => {
      window.removeEventListener('load', listener)
    }
  }, [])

  return (
    <ComponentsProvider context={componentsContext}>
      <FirebaseProvider>
        <ContentWrapper {...props} />
        <Analytics />
      </FirebaseProvider>
    </ComponentsProvider>
  )
}

export default MyApp
