"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToClosestMultiple = exports.generateStats = exports.useFetchTaskEstimateStats = void 0;
var react_1 = require("react");
var luxon_1 = require("luxon");
var config_1 = require("../config");
var __1 = require("..");
function useFetchTaskEstimateStats(_a) {
    var week = _a.week, year = _a.year, userId = _a.userId;
    var _b = (0, react_1.useState)(null), taskEstimateStats = _b[0], setTaskEstimateStats = _b[1];
    var _c = (0, react_1.useState)(true), loading = _c[0], setLoading = _c[1];
    (0, react_1.useEffect)(function () {
        var startTimeRef = luxon_1.DateTime.fromObject({
            weekYear: year,
            weekNumber: week,
        }).startOf('week');
        var startTime = startTimeRef.toJSDate();
        var endTime = startTimeRef.endOf('week').toJSDate();
        config_1.db.collection("users/".concat(userId, "/task-estimate-stats"))
            .where('completedAt', '>=', startTime)
            .where('completedAt', '<', endTime)
            .withConverter((0, __1.converter)())
            .get()
            .then(function (snapshot) {
            var data = snapshot.docs.map(function (doc) { return doc.data(); });
            setTaskEstimateStats(generateStats(data));
            setLoading(false);
        })
            .catch(function (err) {
            console.error('Error fetching current week task estimate stats', err);
        });
    }, [week, year, userId]);
    var _d = (0, react_1.useState)(null), lastWeekTaskEstimateStats = _d[0], setLastWeekTaskEstimateStats = _d[1];
    var _e = (0, react_1.useState)(true), lastWeekDataLoading = _e[0], setLastWeekDataLoading = _e[1];
    (0, react_1.useEffect)(function () {
        var startTimeRef = luxon_1.DateTime.fromObject({
            weekYear: year,
            weekNumber: week,
        }).minus({ week: 1 });
        var lastWeekStartTime = startTimeRef.startOf('week').toJSDate();
        var lastWeekEndTime = startTimeRef.endOf('week').toJSDate();
        config_1.db.collection("users/".concat(userId, "/task-estimate-stats"))
            .where('completedAt', '>=', lastWeekStartTime)
            .where('completedAt', '<', lastWeekEndTime)
            .withConverter((0, __1.converter)())
            .get()
            .then(function (snapshot) {
            var data = snapshot.docs.map(function (doc) { return doc.data(); });
            setLastWeekTaskEstimateStats(generateStats(data));
            setLastWeekDataLoading(false);
        })
            .catch(function (err) {
            console.error('Error fetching current last week task estimate stats', err);
        });
    }, [week, year, userId]);
    return {
        loading: loading || lastWeekDataLoading,
        taskEstimateStats: taskEstimateStats,
        lastWeekTaskEstimateStats: lastWeekTaskEstimateStats,
    };
}
exports.useFetchTaskEstimateStats = useFetchTaskEstimateStats;
function generateStats(tasks) {
    var _a, _b, _c, _d;
    var groups = {};
    for (var _i = 0, tasks_1 = tasks; _i < tasks_1.length; _i++) {
        var task = tasks_1[_i];
        var taskBelongsToGroup = roundToClosestMultiple(task.timeEstimated / 60, 30);
        if (!(taskBelongsToGroup in groups)) {
            groups[taskBelongsToGroup] = {
                numberOfTasksCompleted: 0,
                tasksAccuratelyEstimated: 0,
                tasksOverEstimated: 0,
                tasksUnderEstimated: 0,
                tasksCompletedWithoutFlowing: 0,
            };
        }
        var group = groups[taskBelongsToGroup];
        group.numberOfTasksCompleted += 1;
        if (((_a = task.timeWorked) !== null && _a !== void 0 ? _a : 0) === 0) {
            group.tasksCompletedWithoutFlowing += 1;
        }
        else if (hasTaskBeenAccuratelyEstimated(task.timeEstimated, (_b = task.timeWorked) !== null && _b !== void 0 ? _b : 0)) {
            group.tasksAccuratelyEstimated += 1;
        }
        else if (((_c = task.timeWorked) !== null && _c !== void 0 ? _c : 0) > task.timeEstimated) {
            group.tasksOverEstimated += 1;
        }
        else if (((_d = task.timeWorked) !== null && _d !== void 0 ? _d : 0) < task.timeEstimated) {
            group.tasksUnderEstimated += 1;
        }
    }
    return groups;
}
exports.generateStats = generateStats;
// We round to the closest multiple of 30 minutes.
function roundToClosestMultiple(number, goal) {
    return Math.ceil(number / goal) * goal;
}
exports.roundToClosestMultiple = roundToClosestMultiple;
// We say a task has been accurately estimated if it the worked time is within
// 10% of the estimated time.
function hasTaskBeenAccuratelyEstimated(timeEstimated, timeWorked) {
    return timeWorked <= timeEstimated * 1.1 && timeWorked >= timeEstimated * 0.9;
}
