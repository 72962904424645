"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchAllReactions = void 0;
var react_1 = require("react");
var __1 = require("..");
var config_1 = require("../config");
function useFetchAllReactions(feedUpdateId, feedUserId) {
    var _a = (0, react_1.useState)([]), reactions = _a[0], setReactions = _a[1];
    var _b = (0, react_1.useState)(false), loading = _b[0], setLoading = _b[1];
    (0, react_1.useEffect)(function () {
        config_1.db.collection("users/".concat(feedUserId, "/feed-updates/").concat(feedUpdateId, "/reactions"))
            .withConverter((0, __1.converter)())
            .get()
            .then(function (snapshot) {
            setReactions(snapshot.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); }));
        })
            .catch(function (e) { return console.error(e); })
            .finally(function () {
            setLoading(false);
        });
    }, [feedUpdateId, feedUserId]);
    return {
        feedReactions: reactions,
        loading: loading,
    };
}
exports.useFetchAllReactions = useFetchAllReactions;
