"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchTopApplications = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var __1 = require("..");
var luxon_1 = require("luxon");
function useFetchTopApplications(userId, yearNumber, weekNumber) {
    var _a = (0, react_1.useState)([]), apps = _a[0], setApps = _a[1];
    var _b = (0, react_1.useState)(true), loading = _b[0], setLoading = _b[1];
    var _c = (0, react_1.useState)([]), lastWeekApps = _c[0], setLastWeekApps = _c[1];
    var _d = (0, react_1.useState)(true), lastWeekLoading = _d[0], setLastWeekLoading = _d[1];
    (0, react_1.useEffect)(function () {
        if (!userId) {
            setApps([]);
            setLastWeekApps([]);
            setLoading(false);
            setLastWeekLoading(false);
            return;
        }
        setLoading(true);
        var currentViewingWeek = luxon_1.DateTime.fromObject({
            weekYear: yearNumber,
            weekNumber: weekNumber,
        });
        config_1.db.doc("users/".concat(userId, "/top-app-stats/").concat(currentViewingWeek.toFormat("yyyy-'W'WW")))
            .withConverter((0, __1.converter)())
            .get()
            .then(function (snapshot) {
            var data = snapshot.data();
            if (data) {
                setApps(mergeApps(data));
            }
            else {
                setApps([]);
            }
            setLoading(false);
        })
            .catch(function (err) {
            console.error(err);
        });
        setLastWeekLoading(true);
        var lastWeekDocumentId = currentViewingWeek
            .minus({ weeks: 1 })
            .toFormat("yyyy-'W'WW");
        config_1.db.doc("users/".concat(userId, "/top-app-stats/").concat(lastWeekDocumentId))
            .withConverter((0, __1.converter)())
            .get()
            .then(function (snapshot) {
            var data = snapshot.data();
            if (data) {
                setLastWeekApps(mergeApps(data));
            }
            else {
                setLastWeekApps([]);
            }
            setLastWeekLoading(false);
        })
            .catch(function (err) {
            console.error(err);
        });
    }, [yearNumber, weekNumber, userId]);
    return {
        loading: loading || lastWeekLoading,
        topApps: apps,
        lastWeekTopApps: lastWeekApps,
    };
}
exports.useFetchTopApplications = useFetchTopApplications;
function mergeApps(appsForWeek) {
    var appsByBundleIdOrUrl = {};
    for (var _i = 0, _a = Object.values(appsForWeek.applications); _i < _a.length; _i++) {
        var appsInSession = _a[_i];
        for (var _b = 0, appsInSession_1 = appsInSession; _b < appsInSession_1.length; _b++) {
            var app = appsInSession_1[_b];
            if (appsByBundleIdOrUrl[app.bundleIdOrUrl]) {
                appsByBundleIdOrUrl[app.bundleIdOrUrl].timeWorked += app.timeWorked;
            }
            else {
                appsByBundleIdOrUrl[app.bundleIdOrUrl] = app;
            }
        }
    }
    return Object.values(appsByBundleIdOrUrl);
}
