"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchFeedUpdate = void 0;
var react_1 = require("react");
var __1 = require("..");
var config_1 = require("../config");
var FirebaseContext_1 = require("../FirebaseContext");
function useFetchFeedUpdate(updateId, updateBelongsToUserId) {
    var _a = (0, react_1.useState)(true), loading = _a[0], setLoading = _a[1];
    var _b = (0, react_1.useState)(null), feedUpdate = _b[0], setFeedUpdate = _b[1];
    var currentUser = (0, FirebaseContext_1.useCurrentUser)().currentUser;
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        setLoading(true);
        setFeedUpdate(null);
        var path = updateBelongsToUserId
            ? "/users/".concat(updateBelongsToUserId, "/feed-updates/").concat(updateId)
            : "/users/".concat(currentUser.id, "/feed-timeline/").concat(updateId);
        config_1.db.doc(path)
            .withConverter((0, config_1.converter)())
            .get()
            .then(function (snapshot) {
            var snapshotData = snapshot.data();
            if (snapshotData) {
                setFeedUpdate(__assign({ id: snapshot.id }, snapshot.data()));
            }
            else {
                setFeedUpdate(null);
            }
        })
            .catch(function (e) { return console.error(e); })
            .finally(function () {
            setLoading(false);
        });
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, updateId, updateBelongsToUserId]);
    var _c = (0, __1.useUser)(feedUpdate === null || feedUpdate === void 0 ? void 0 : feedUpdate.userId), user = _c.user, userLoading = _c.loading;
    var processedFeedItem = (0, react_1.useMemo)(function () {
        if (!user || !feedUpdate) {
            return null;
        }
        var item = __assign(__assign({}, feedUpdate), { owner: {
                id: user.id,
                name: user.name,
                avatarUrl: user.avatarUrl,
            } });
        return item;
    }, [user, feedUpdate]);
    return {
        loading: loading || userLoading,
        feedUpdate: processedFeedItem,
    };
}
exports.useFetchFeedUpdate = useFetchFeedUpdate;
