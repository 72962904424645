"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeScore = void 0;
var dateTime_1 = require("../dateTime");
var Accuracy_1 = require("../math/Accuracy");
var Percent_1 = require("../math/Percent");
var sessionReportToDateAndAmountMap_1 = require("../sessionReportToDateAndAmountMap");
var Score_1 = require("./Score");
var MAX_MINUTES_WORKED = 120;
var MAX_DAILY_STREAK_DAYS = 5;
function computeScore(_a) {
    var secondsWorked = _a.secondsWorked, _b = _a.startedAt, startedAt = _b === void 0 ? (0, dateTime_1.createDateTime)('now') : _b, _c = _a.distractionBlocking, distractionBlocking = _c === void 0 ? {} : _c, _d = _a.healthyHabits, healthyHabits = _d === void 0 ? {} : _d, _e = _a.taskManagement, taskManagement = _e === void 0 ? {} : _e, randomizer = _a.randomizer, session = _a.session, multiplier = _a.multiplier;
    var minutesWorked = secondsWorked / 60;
    // #region === POINTS ===
    // Session Duration Points
    var score = Score_1.Score.MIN + pointsForMinutesWorked(minutesWorked);
    if (minutesWorked >= sessionReportToDateAndAmountMap_1.MIN_MINUTES_WORKED_FOR_DAY_STREAK) {
        score += 10;
    }
    // Distraction Blocking Points
    var appTracking = distractionBlocking.appTracking, browserTracking = distractionBlocking.browserTracking, slackDnD = distractionBlocking.slackDnD, discordDnD = distractionBlocking.discordDnD, focusMode = distractionBlocking.focusMode, mobileDetection = distractionBlocking.mobileDetection, _f = distractionBlocking.trackedApps, trackedApps = _f === void 0 ? [] : _f;
    var secondsDistracted = trackedApps
        .map(function (app) { return (app.isDistraction ? app.timeWorked : 0); })
        .reduce(function (a, b) { return a + b; }, 0);
    var secondsProductive = secondsWorked - secondsDistracted;
    var minutesInProductiveApps = Math.floor(secondsProductive / 60);
    if (appTracking)
        score += 10;
    if (slackDnD)
        score += 10;
    if (discordDnD)
        score += 10;
    if (focusMode)
        score += 15;
    if (mobileDetection)
        score += 20;
    if (appTracking && minutesInProductiveApps > 0) {
        score += Math.min((minutesInProductiveApps / minutesWorked) * 100, 100);
    }
    // Healthy Habits Points
    var scheduledReminders = healthyHabits.scheduledReminders, tookBreaks = healthyHabits.tookBreaks, _g = healthyHabits.dailyStreakCount, dailyStreakCount = _g === void 0 ? 0 : _g, _h = healthyHabits.secondsWorkedHistory, secondsWorkedHistory = _h === void 0 ? {
        today: 0,
        todayMinus1: 0,
        todayMinus2: 0,
        todayMinus3: 0,
        todayMinus4: 0,
        todayMinus5: 0,
        todayMinus6: 0,
        todayMinus7: 0,
    } : _h;
    if (scheduledReminders)
        score += 20;
    if (tookBreaks)
        score += 15;
    score += Math.min(dailyStreakCount * 5, MAX_DAILY_STREAK_DAYS * 5);
    // Task Management Points
    var _j = taskManagement.tasksCount, tasksCount = _j === void 0 ? 0 : _j, monoTasking = taskManagement.monoTasking, startedWithBiggestTask = taskManagement.startedWithBiggestTask, taskEstimates = taskManagement.taskEstimates, _k = taskManagement.completedTasks, completedTasks = _k === void 0 ? [] : _k;
    var usedTasks = tasksCount > 0;
    var hasManyTasks = tasksCount > 1;
    var estimationAccuracy = computeEstimationAccuracy(completedTasks);
    if (usedTasks) {
        if (hasManyTasks)
            score += 15;
        if (monoTasking)
            score += 15;
        if (startedWithBiggestTask)
            score += 10;
        if (taskEstimates)
            score += 15;
        if (taskEstimates) {
            var accuracyPercent = estimationAccuracy.percent;
            if (accuracyPercent.value >= 90) {
                score += 20;
            }
            else if (accuracyPercent.value >= 80) {
                score += 10;
            }
        }
    }
    // #endregion
    // #region === BREAKDOWN ===
    // Session Duration Breakdown
    var timeWorkedPercent = Percent_1.Percent.fromDecimal(minutesWorked / MAX_MINUTES_WORKED);
    var flowStatePercent = Percent_1.Percent.fromBoolean(minutesWorked >= sessionReportToDateAndAmountMap_1.MIN_MINUTES_WORKED_FOR_DAY_STREAK);
    // Distraction Blocking Breakdown
    var appTrackingPercent = !appTracking
        ? new Percent_1.Percent(0)
        : minutesWorked <= 0
            ? new Percent_1.Percent(5)
            : Percent_1.Percent.fromDecimal(secondsProductive / secondsWorked).withMinimum(new Percent_1.Percent(5));
    var slackDnDPercent = Percent_1.Percent.fromBoolean(slackDnD);
    var discordDnDPercent = Percent_1.Percent.fromBoolean(discordDnD);
    var focusModePercent = Percent_1.Percent.fromBoolean(focusMode);
    var mobileDetectionPercent = Percent_1.Percent.fromBoolean(mobileDetection);
    // Healthy Habits Breakdown
    var scheduledRemindersPercent = Percent_1.Percent.fromBoolean(scheduledReminders);
    var streaksPercent = Percent_1.Percent.fromDecimal(Math.min(dailyStreakCount / MAX_DAILY_STREAK_DAYS, 1));
    var takeBreaksPercent = Percent_1.Percent.fromBoolean(tookBreaks);
    // Task Management Breakdown
    var organizeTasksPercent = Percent_1.Percent.fromBoolean(hasManyTasks);
    var monoTaskPercent = usedTasks
        ? Percent_1.Percent.fromBoolean(monoTasking)
        : new Percent_1.Percent(0);
    var eatTheFrogPercent = usedTasks
        ? Percent_1.Percent.fromBoolean(startedWithBiggestTask)
        : new Percent_1.Percent(0);
    var timeBoxPercent = usedTasks
        ? Percent_1.Percent.fromBoolean(taskEstimates)
        : new Percent_1.Percent(0);
    var breakdown = {
        sessionDuration: {
            percent: Percent_1.Percent.averageOf([timeWorkedPercent, flowStatePercent]),
            startedAt: startedAt,
            seconds: secondsWorked,
            minFlowStateThreshold: { percent: flowStatePercent },
            timeWorked: { percent: timeWorkedPercent },
        },
        distractionBlocking: {
            percent: Percent_1.Percent.averageOf([
                appTrackingPercent,
                slackDnDPercent,
                discordDnDPercent,
                focusModePercent,
                mobileDetectionPercent,
            ]),
            appTracking: {
                percent: appTrackingPercent,
                enabled: Boolean(appTracking),
                enabledForBrowsers: Boolean(browserTracking),
                productiveSeconds: secondsProductive,
                distractedSeconds: secondsDistracted,
                trackedApps: trackedApps,
            },
            slackDnD: { percent: slackDnDPercent },
            discordDnD: { percent: discordDnDPercent },
            focusMode: { percent: focusModePercent },
            mobileDetection: { percent: mobileDetectionPercent },
        },
        healthyHabits: {
            percent: Percent_1.Percent.averageOf([
                scheduledRemindersPercent,
                streaksPercent,
                takeBreaksPercent,
            ]),
            scheduledWorkSessions: { percent: scheduledRemindersPercent },
            streaks: {
                percent: streaksPercent,
                dailyStreakCount: dailyStreakCount,
                secondsWorkedHistory: secondsWorkedHistory,
            },
            takeBreaks: { percent: takeBreaksPercent },
        },
        taskManagement: {
            percent: Percent_1.Percent.averageOf([
                organizeTasksPercent,
                monoTaskPercent,
                eatTheFrogPercent,
                timeBoxPercent,
                estimationAccuracy.percent,
            ]),
            organizeTasks: { percent: organizeTasksPercent },
            monoTask: { percent: monoTaskPercent },
            eatTheFrog: { percent: eatTheFrogPercent },
            timeBox: { percent: timeBoxPercent },
            estimationAccuracy: estimationAccuracy,
        },
    };
    // #endregion
    // #region === ACCURACY ===
    // Consider the boolean params to impact the accuracy
    // It doesn't need to be perfect. It's a CTA for users to enable settings.
    var accuracyParams = [
        appTracking,
        slackDnD,
        discordDnD,
        focusMode,
        mobileDetection,
        scheduledReminders,
        tookBreaks,
        monoTasking,
        startedWithBiggestTask,
        taskEstimates,
    ];
    var enabledParams = accuracyParams.filter(Boolean);
    var MIN_ACCURACY = 0.32;
    var accuracy = MIN_ACCURACY +
        (enabledParams.length / accuracyParams.length) * (1 - MIN_ACCURACY);
    // #endregion
    return new Score_1.Score(score, Percent_1.Percent.fromDecimal(accuracy), breakdown, multiplier, randomizer, undefined, session);
}
exports.computeScore = computeScore;
function computeEstimationAccuracy(completedTasks) {
    if (completedTasks.length === 0)
        return Accuracy_1.Accuracy.createInnaccurate();
    var taskAccuracies = completedTasks.map(function (_a) {
        var estimatedSeconds = _a.estimatedSeconds, actualSeconds = _a.actualSeconds;
        return new Accuracy_1.Accuracy(estimatedSeconds, actualSeconds);
    });
    return Accuracy_1.Accuracy.averageOf(taskAccuracies);
}
function pointsForMinutesWorked(minutesWorked) {
    // Step function, based on https://docs.google.com/spreadsheets/d/1nf7GR_4UuGcEkzbVdsjJ-t5-SyYJc8aX5L8DxRZURnM/edit#gid=845240744
    if (minutesWorked <= 15)
        return minutesWorked;
    if (minutesWorked <= 30)
        return 15 + (minutesWorked - 15) * 1.5;
    if (minutesWorked <= 45)
        return 37.5 + (minutesWorked - 30) * 2;
    if (minutesWorked <= 60)
        return 67.5 + (minutesWorked - 45) * 3;
    if (minutesWorked <= 75)
        return 112.5 + (minutesWorked - 60) * 4;
    if (minutesWorked <= 90)
        return 172.5 + (minutesWorked - 75) * 5;
    if (minutesWorked <= 105)
        return 247.5 + (minutesWorked - 90) * 1.5;
    if (minutesWorked <= MAX_MINUTES_WORKED) {
        return Math.min(270 + (minutesWorked - 105) * 1.34, 290);
    }
    return 290;
}
