"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Score = void 0;
var dateTime_1 = require("../dateTime");
var Accuracy_1 = require("../math/Accuracy");
var Percent_1 = require("../math/Percent");
var Randomizer_1 = require("../math/Randomizer");
var sessionReportToDateAndAmountMap_1 = require("../sessionReportToDateAndAmountMap");
var Score = /** @class */ (function () {
    function Score(value, accuracy, breakdown, multiplier, randomizer, insightName, session) {
        if (accuracy === void 0) { accuracy = new Percent_1.Percent(100); }
        if (randomizer === void 0) { randomizer = new Randomizer_1.MathRandomizer(); }
        this.accuracy = accuracy;
        this.breakdown = breakdown;
        this.multiplier = multiplier;
        this.randomizer = randomizer;
        this._value = Math.round(value);
        this.session = session;
        if (value > Score.MAX) {
            console.error("Score value must be <= ".concat(Score.MAX, ". Received ").concat(value));
            this._value = Score.MAX;
        }
        if (value < Score.MIN) {
            console.error("Score value must be >= ".concat(Score.MIN, ". Received ").concat(value));
            this._value = Score.MIN;
        }
        this.insightName =
            insightName === 'no-insight'
                ? undefined
                : insightName !== null && insightName !== void 0 ? insightName : this.computeInsightName({ canResumeSession: true });
    }
    Score.fromFirebase = function (session) {
        var _a, _b;
        if (!session.score) {
            throw new Error("Session doesn't have a score");
        }
        var _c = session.score, value = _c.value, accuracy = _c.accuracy, breakdown = _c.breakdown, insight = _c.insight, multiplier = _c.multiplier;
        var sessionDuration = breakdown.sessionDuration, distractionBlocking = breakdown.distractionBlocking, healthyHabits = breakdown.healthyHabits, taskManagement = breakdown.taskManagement;
        return new Score(value, new Percent_1.Percent(accuracy), {
            sessionDuration: __assign(__assign({}, sessionDuration), { percent: new Percent_1.Percent(sessionDuration.percent), startedAt: (0, dateTime_1.createDateTime)(sessionDuration.startedAt instanceof Date
                    ? sessionDuration.startedAt
                    : sessionDuration.startedAt.toDate()), minFlowStateThreshold: {
                    percent: new Percent_1.Percent(sessionDuration.minFlowStateThreshold.percent),
                }, timeWorked: {
                    percent: new Percent_1.Percent(sessionDuration.timeWorked.percent),
                } }),
            distractionBlocking: {
                percent: new Percent_1.Percent(distractionBlocking.percent),
                appTracking: __assign(__assign({}, distractionBlocking.appTracking), { enabledForBrowsers: Boolean(distractionBlocking.appTracking.enabledForBrowsers), percent: new Percent_1.Percent(distractionBlocking.appTracking.percent) }),
                slackDnD: {
                    percent: new Percent_1.Percent(distractionBlocking.slackDnD.percent),
                },
                discordDnD: {
                    percent: new Percent_1.Percent(distractionBlocking.discordDnD.percent),
                },
                focusMode: {
                    percent: new Percent_1.Percent(distractionBlocking.focusMode.percent),
                },
                mobileDetection: {
                    percent: new Percent_1.Percent((_b = (_a = distractionBlocking.mobileDetection) === null || _a === void 0 ? void 0 : _a.percent) !== null && _b !== void 0 ? _b : 0),
                },
            },
            healthyHabits: {
                percent: new Percent_1.Percent(healthyHabits.percent),
                scheduledWorkSessions: {
                    percent: new Percent_1.Percent(healthyHabits.scheduledWorkSessions.percent),
                },
                streaks: __assign(__assign({}, healthyHabits.streaks), { percent: new Percent_1.Percent(healthyHabits.streaks.percent) }),
                takeBreaks: {
                    percent: new Percent_1.Percent(healthyHabits.takeBreaks.percent),
                },
            },
            taskManagement: {
                percent: new Percent_1.Percent(taskManagement.percent),
                organizeTasks: {
                    percent: new Percent_1.Percent(taskManagement.organizeTasks.percent),
                },
                monoTask: { percent: new Percent_1.Percent(taskManagement.monoTask.percent) },
                eatTheFrog: {
                    percent: new Percent_1.Percent(taskManagement.eatTheFrog.percent),
                },
                timeBox: { percent: new Percent_1.Percent(taskManagement.timeBox.percent) },
                estimationAccuracy: new Accuracy_1.Accuracy(100, 100 + taskManagement.estimationAccuracy.relativeDiff),
            },
        }, multiplier, new Randomizer_1.MathRandomizer(), insight
            ? typeof insight === 'string'
                ? insight
                : insight.name
            : 'no-insight', session);
    };
    Object.defineProperty(Score.prototype, "value", {
        get: function () {
            return this._value * this.multiplierValue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Score.prototype, "multiplierValue", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.multiplier) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Score.prototype, "isBoosted", {
        get: function () {
            return this.multiplierValue > 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Score.prototype, "insight", {
        get: function () {
            if (!this.insightName)
                return;
            return toScoreInsight(this.insightName, this);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Score.prototype, "label", {
        get: function () {
            if (this.isBoosted)
                return "Score Boosted x".concat(this.multiplierValue);
            if (this.value >= 750)
                return 'Peak Mastery';
            if (this.value >= 700)
                return 'Approaching Peak';
            if (this.value >= 600)
                return 'Incredible';
            if (this.value >= 500)
                return 'Getting There';
            if (this.value >= 400)
                return "Let's Focus";
            return 'A Good Start';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Score.prototype, "color", {
        get: function () {
            if (this.isBoosted)
                return '#FFCA10';
            if (this.value >= 700)
                return '#00A389';
            if (this.value >= 500)
                return '#0496FF';
            if (this.value >= 300)
                return '#E24C1C';
            return '#EC3B5B';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Score.prototype, "remainingMinutesToWorkForStreak", {
        get: function () {
            var minutesWorked = Math.floor(this.breakdown.sessionDuration.seconds / 60);
            return sessionReportToDateAndAmountMap_1.MIN_MINUTES_WORKED_FOR_DAY_STREAK - minutesWorked;
        },
        enumerable: false,
        configurable: true
    });
    Score.prototype.computeInsightName = function (_a) {
        var canResumeSession = _a.canResumeSession;
        if (canResumeSession && this.remainingMinutesToWorkForStreak > 0) {
            return 'Should Flow More';
        }
        var distractionBlocking = this.breakdown.distractionBlocking;
        if (!distractionBlocking.appTracking.enabled) {
            return this.randomizer.pick([
                'Benefits of App Awareness',
                'Level up your Coach',
            ]);
        }
        if (this.randomizer.createPercent().value <= 10)
            return;
        if (this.dataDrivenInsights.length === 0) {
            return this.randomizer.pick(Score.GENERAL_INSIGHTS);
        }
        return this.randomizer.pick(this.randomizer.createPercent().value <= 35
            ? Score.GENERAL_INSIGHTS
            : this.dataDrivenInsights);
    };
    Object.defineProperty(Score.prototype, "dataDrivenInsights", {
        get: function () {
            var result = [];
            var _a = this.breakdown, distractionBlocking = _a.distractionBlocking, healthyHabits = _a.healthyHabits, taskManagement = _a.taskManagement;
            if (!distractionBlocking.appTracking.enabledForBrowsers) {
                // Create 3 tips like this, so it has more chances to be picked.
                result = result.concat(Array(3).fill('Should enable Website Awareness'));
            }
            if (distractionBlocking.appTracking.distractedSeconds > 0) {
                result.push('Focus Mode');
            }
            if (distractionBlocking.slackDnD.percent.value === 0) {
                result.push('Should enable Slack DnD');
            }
            if (distractionBlocking.discordDnD.percent.value === 0) {
                result.push('Should enable Discord DnD');
            }
            if (distractionBlocking.mobileDetection.percent.value === 0) {
                result.push('Get your life back');
                result.push('Reclaim your attention');
                result.push('Tame your phone');
            }
            if (healthyHabits.scheduledWorkSessions.percent.value === 0) {
                result.push('Deep Work');
                result.push('Flow States');
                result.push('Successful Long-Term Goals');
            }
            if (healthyHabits.streaks.dailyStreakCount === 0) {
                result.push('Good Habits');
            }
            if (healthyHabits.takeBreaks.percent.value === 0) {
                result.push('Health & Productivity');
                result.push('Hydratation, your friend');
                result.push('Aromatherapy');
                result.push('Breathe');
            }
            if (taskManagement.organizeTasks.percent.value === 0) {
                result.push('Success List');
                result.push('5 Easy Hacks');
                result.push('Daily Routines');
            }
            if (taskManagement.monoTask.percent.value === 0) {
                result.push('Mono-Tasking');
                result.push('Bad Habits');
                result.push('Mono-Task to Success');
                result.push('Should Mono-Task');
            }
            if (taskManagement.eatTheFrog.percent.value === 0) {
                result.push('Eat the Frog');
                result.push('Productivity Tune-Up');
            }
            if (taskManagement.timeBox.percent.value === 0) {
                result.push('Timeboxing');
            }
            if (taskManagement.estimationAccuracy.relativeDiff.value > 20) {
                result.push('Should estimate times better');
                result.push('Pareto Principle');
            }
            return result;
        },
        enumerable: false,
        configurable: true
    });
    Score.prototype.serialize = function () {
        var _a, _b;
        var _c = this, value = _c.value, accuracy = _c.accuracy, breakdown = _c.breakdown, insight = _c.insight;
        var sessionDuration = breakdown.sessionDuration, distractionBlocking = breakdown.distractionBlocking, healthyHabits = breakdown.healthyHabits, taskManagement = breakdown.taskManagement;
        return __assign({ value: value / ((_b = (_a = this.multiplier) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 1), accuracy: accuracy.value, multiplier: this.multiplier, breakdown: {
                sessionDuration: {
                    percent: sessionDuration.percent.value,
                    seconds: sessionDuration.seconds,
                    startedAt: new Date(sessionDuration.startedAt.toMillis()),
                    minFlowStateThreshold: {
                        percent: sessionDuration.minFlowStateThreshold.percent.value,
                    },
                    timeWorked: { percent: sessionDuration.timeWorked.percent.value },
                },
                distractionBlocking: {
                    percent: distractionBlocking.percent.value,
                    appTracking: {
                        percent: distractionBlocking.appTracking.percent.value,
                        enabled: distractionBlocking.appTracking.enabled,
                        enabledForBrowsers: distractionBlocking.appTracking.enabledForBrowsers,
                        productiveSeconds: distractionBlocking.appTracking.productiveSeconds,
                        distractedSeconds: distractionBlocking.appTracking.distractedSeconds,
                        trackedApps: distractionBlocking.appTracking.trackedApps.map(function (app) {
                            var _a;
                            return __assign(__assign({}, app), { icon: ((_a = app.icon) === null || _a === void 0 ? void 0 : _a.startsWith('data:')) ? undefined : app.icon });
                        }),
                    },
                    slackDnD: { percent: distractionBlocking.slackDnD.percent.value },
                    discordDnD: { percent: distractionBlocking.discordDnD.percent.value },
                    focusMode: { percent: distractionBlocking.focusMode.percent.value },
                },
                healthyHabits: {
                    percent: healthyHabits.percent.value,
                    scheduledWorkSessions: {
                        percent: healthyHabits.scheduledWorkSessions.percent.value,
                    },
                    streaks: {
                        percent: healthyHabits.streaks.percent.value,
                        secondsWorkedHistory: healthyHabits.streaks.secondsWorkedHistory,
                        dailyStreakCount: healthyHabits.streaks.dailyStreakCount,
                    },
                    takeBreaks: { percent: healthyHabits.takeBreaks.percent.value },
                },
                taskManagement: {
                    percent: taskManagement.percent.value,
                    organizeTasks: {
                        percent: taskManagement.organizeTasks.percent.value,
                    },
                    monoTask: { percent: taskManagement.monoTask.percent.value },
                    eatTheFrog: { percent: taskManagement.eatTheFrog.percent.value },
                    timeBox: { percent: taskManagement.timeBox.percent.value },
                    estimationAccuracy: {
                        percent: taskManagement.estimationAccuracy.percent.value,
                        relativeDiff: taskManagement.estimationAccuracy.relativeDiff.value,
                    },
                },
            } }, (insight && {
            insight: insight.name === 'Should Flow More'
                ? this.computeInsightName({ canResumeSession: false })
                : insight.name,
        }));
    };
    Score.MAX = 800;
    Score.MIN = 200;
    Score.GENERAL_INSIGHTS = [
        'Should keep a time diary',
        'Should take a power nap',
        'Should use the 80/20 rule',
        'Should clear to neutral',
    ];
    return Score;
}());
exports.Score = Score;
function toScoreInsight(name, score) {
    var allInsights = [
        {
            name: 'Should keep a time diary',
            text: 'Keep a time diary & learn when you focus best.',
        },
        {
            name: 'Should take a power nap',
            text: 'Power naps can restore your focus and mental clarity.',
        },
        {
            name: 'Should use the 80/20 rule',
            text: 'Leverage the Pareto Principle to achieve effortless output.',
        },
        {
            name: 'Should clear to neutral',
            text: 'A tidy workspace helps you get in The Zone.',
        },
        {
            name: 'Benefits of App Awareness',
            text: 'Keeping a record of your work habits will help you improve',
            tip: 'Benefits of App Awareness',
        },
        {
            name: 'Level up your Coach',
            text: 'Want your Coach to help you with distractions?',
            tip: 'Level up your Coach',
        },
        {
            name: 'Should enable Website Awareness',
            text: 'Distractions hinder your ability to focus. Try and stay on task.',
            tip: 'Browser Distractions',
        },
        {
            name: 'Get your life back',
            text: 'Social Media is not your friend',
            tip: 'Get your life back',
        },
        {
            name: 'Reclaim your attention',
            text: 'Watch out for the Attention Merchants',
            tip: 'Reclaim your attention',
        },
        {
            name: 'Tame your phone',
            text: 'Infinite Distraction',
            tip: 'Tame your phone',
        },
        {
            name: 'Focus Mode',
            text: 'Try blocking those distracting notifications',
            tip: 'Focus Mode',
        },
        {
            name: 'Should enable Slack DnD',
            text: 'Would you work better without the Slack chatter?',
            tip: 'Slack Do-not-Disturb',
        },
        {
            name: 'Should enable Discord DnD',
            text: 'Have you tried silencing Discord while you work?',
            tip: 'Discord Do-not-Disturb',
        },
        {
            name: 'Deep Work',
            text: 'Tips from Cal Newport',
            tip: 'Deep Work',
        },
        {
            name: 'Flow States',
            text: 'Are you blocking time to find your Flow State?',
            tip: 'Flow States',
        },
        {
            name: 'Successful Long-Term Goals',
            text: 'Use rewards to make future sessions fun	Successful Long-Term Goals',
            tip: 'Successful Long-Term Goals',
        },
        {
            name: 'Good Habits',
            text: 'Daily Streaks come from good habits - are you ready?',
            tip: 'Good Habits',
        },
        {
            name: 'Health & Productivity',
            text: 'Are you taking healthy breaks?',
            tip: 'Health & Productivity',
        },
        {
            name: 'Hydratation, your friend',
            text: 'Are you drinking enough water?',
            tip: 'Hydratation, your friend',
        },
        {
            name: 'Aromatherapy',
            text: 'Have you tried aromatherapy for focus?',
            tip: 'Aromatherapy',
        },
        {
            name: 'Breathe',
            text: 'Try a breathing exercise to reduce stress',
            tip: 'Breathe',
        },
        {
            name: 'Success List',
            text: 'Make your Task list your Success list',
            tip: 'Success List',
        },
        {
            name: '5 Easy Hacks',
            text: 'Organize your day and your task list for success	5 Easy Hacks',
            tip: '5 Easy Hacks',
        },
        {
            name: 'Daily Routines',
            text: 'Try organizing your day the night before',
            tip: 'Daily Routines',
        },
        {
            name: 'Mono-Tasking',
            text: 'Focus on one Task to completion',
            tip: 'Mono-Tasking',
        },
        {
            name: 'Bad Habits',
            text: 'Multi-Tasking is a myth; try Mono-Tasking',
            tip: 'Bad Habits',
        },
        {
            name: 'Mono-Task to Success',
            text: 'Stop burning cognitive energy on multi-tasking',
            tip: 'Mono-Task to Success',
        },
        {
            name: 'Should Mono-Task',
            text: 'Pick one Task and see it through to completion. You’ll thank yourself for it.',
            tip: 'Mono-Tasking',
        },
        {
            name: 'Eat the Frog',
            text: 'Start your days with your Most Important Task',
            tip: 'Eat the Frog',
        },
        {
            name: 'Productivity Tune-Up',
            text: 'Try knocking out your hardest Task first',
            tip: 'Productivity Tune-Up',
        },
        {
            name: 'Timeboxing',
            text: 'Try timeboxing to up your game',
            tip: 'Timeboxing',
        },
        {
            name: 'Should estimate times better',
            text: 'Accurate time estimates will help you organize your work sessions more effectively.',
            tip: 'Task Estimates',
        },
        {
            name: 'Pareto Principle',
            text: 'Better estimates may help you finish your Tasks on time',
            tip: 'Pareto Principle',
        },
    ];
    if (score.remainingMinutesToWorkForStreak > 0) {
        var remainingMinutes = new RemainingMinutes(score.remainingMinutesToWorkForStreak);
        allInsights.push({
            name: 'Should Flow More',
            text: "Work for ".concat(remainingMinutes.toString(), " to reach your Flow State Threshold Bonus."),
            highlight: highlight(templateObject_1 || (templateObject_1 = __makeTemplateObject(["Work for ", " to reach your Flow State Threshold Bonus."], ["Work for ", " to reach your Flow State Threshold Bonus."])), remainingMinutes.toString()),
        });
    }
    return allInsights.find(function (insight) { return insight.name === name; });
}
var RemainingMinutes = /** @class */ (function () {
    function RemainingMinutes(value) {
        this.value = value;
        if (value < 1) {
            console.error("Remaining minutes must be >= 1. Received: ".concat(value));
            this.value = 1;
        }
    }
    RemainingMinutes.prototype.toString = function () {
        return "".concat(this.value, " ").concat(this.label);
    };
    Object.defineProperty(RemainingMinutes.prototype, "label", {
        get: function () {
            return this.value > 1 ? 'more minutes' : 'more minute';
        },
        enumerable: false,
        configurable: true
    });
    return RemainingMinutes;
}());
function highlight(_a, value) {
    var before = _a[0], after = _a[1];
    return { before: before, after: after, value: value };
}
var templateObject_1;
