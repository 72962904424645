"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coachMessageWindowEventSchema = exports.coachMessageSchema = void 0;
var zod_1 = require("zod");
exports.coachMessageSchema = zod_1.z.object({
    id: zod_1.z.string().optional(),
    name: zod_1.z.string(),
    text: zod_1.z.string().optional(),
    audioUrl: zod_1.z.string().optional(),
    hideAfterMs: zod_1.z.number().optional(),
    removePreviousMessages: zod_1.z.boolean().optional(),
    distraction: zod_1.z
        .object({
        name: zod_1.z.string(),
        bundleId: zod_1.z.string(),
    })
        .optional(),
});
exports.coachMessageWindowEventSchema = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('Display Coach Message'),
        message: exports.coachMessageSchema,
    }),
    zod_1.z.object({
        type: zod_1.z.literal('Hide Coach Message'),
        messageId: zod_1.z.string(),
    }),
]);
