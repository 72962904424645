"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var manageCalendarEvents_1 = require("../helpers/manageCalendarEvents");
var useSubscribeCalendarEvents = function (user, start, end) {
    var _a = (0, react_1.useState)([]), calendarEvents = _a[0], setCalendarEvents = _a[1];
    var _b = (0, react_1.useState)(false), loaded = _b[0], setLoaded = _b[1];
    (0, react_1.useEffect)(function () {
        if (!(user === null || user === void 0 ? void 0 : user.id) || user.id.length === 0)
            return;
        setLoaded(false);
        return (0, manageCalendarEvents_1.subscribeToCalendarEventsBetween)(start.toJSDate(), end.toJSDate(), function (events) {
            setCalendarEvents(events);
            setLoaded(true);
        });
    }, // Don't change every milliseconds, but every day is fine
    [user, start.day, end.day]);
    return {
        calendarEvents: calendarEvents,
        loaded: loaded,
    };
};
exports.default = useSubscribeCalendarEvents;
