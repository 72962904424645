"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseProvider = exports.useCurrentUser = void 0;
var analytics_1 = require("@centered/analytics");
var React = require("react");
var react_1 = require("react");
var currentUserMachine_1 = require("./machines/currentUserMachine");
var defaultContext = {};
var FirebaseContext = (0, react_1.createContext)(defaultContext);
var useCurrentUser = function () {
    var currentUser = (0, react_1.useContext)(FirebaseContext).currentUser;
    return (currentUser || {
        loading: false,
        signOut: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/];
        }); }); },
    });
};
exports.useCurrentUser = useCurrentUser;
var FirebaseProvider = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var children = _a.children;
    var currentUserFn = (0, currentUserMachine_1.useInternalCurrentUser)();
    var value = (0, react_1.useMemo)(function () {
        return {
            currentUser: currentUserFn,
        };
    }, [currentUserFn]);
    (0, react_1.useEffect)(function () {
        if (!currentUserFn.currentUser) {
            return;
        }
        (0, analytics_1.identify)(currentUserFn.currentUser.id, {
            id: currentUserFn.currentUser.id,
            name: currentUserFn.currentUser.name,
            email: currentUserFn.currentUser.email,
        }, {
            minutesFlowed: currentUserFn.currentUser.minutesFlowed,
            completedIntentions: currentUserFn.currentUser.completedIntentions,
            freeTrialStatus: currentUserFn.currentUser.freeTrialStatus,
            isPremium: Boolean(currentUserFn.currentUser.isPremium),
            isAnonymous: Boolean(currentUserFn.currentUser.isAnonymous),
        });
    }, [
        (_b = currentUserFn.currentUser) === null || _b === void 0 ? void 0 : _b.id,
        (_c = currentUserFn.currentUser) === null || _c === void 0 ? void 0 : _c.minutesFlowed,
        (_d = currentUserFn.currentUser) === null || _d === void 0 ? void 0 : _d.name,
        (_e = currentUserFn.currentUser) === null || _e === void 0 ? void 0 : _e.email,
        (_f = currentUserFn.currentUser) === null || _f === void 0 ? void 0 : _f.completedIntentions,
        (_g = currentUserFn.currentUser) === null || _g === void 0 ? void 0 : _g.isPremium,
        (_h = currentUserFn.currentUser) === null || _h === void 0 ? void 0 : _h.isAnonymous,
    ]);
    return (React.createElement(FirebaseContext.Provider, { value: value },
        React.createElement(React.Fragment, null, children)));
};
exports.FirebaseProvider = FirebaseProvider;
