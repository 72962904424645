"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLeaderboard = exports.LeaderboardProvider = exports.defaultEnvironment = void 0;
var React = require("react");
var react_1 = require("react");
var __1 = require("..");
var luxon_1 = require("luxon");
exports.defaultEnvironment = {
    topUsers: [],
};
var LeaderBoardContext = (0, react_1.createContext)(exports.defaultEnvironment);
var LeaderboardProvider = function (_a) {
    var groupId = _a.groupId, children = _a.children;
    var currentUser = (0, __1.useCurrentUser)().currentUser;
    var _b = (0, react_1.useState)([]), topUsers = _b[0], setTopUsers = _b[1];
    var _c = (0, react_1.useState)(luxon_1.DateTime.now().setZone('America/Los_Angeles').toFormat('yyyy-LL-dd')), dailyDocId = _c[0], setDailyDocId = _c[1];
    (0, react_1.useEffect)(function () {
        var interval = setInterval(function () {
            setDailyDocId(luxon_1.DateTime.now().setZone('America/Los_Angeles').toFormat('yyyy-LL-dd'));
        }, 60000);
        return function () {
            clearInterval(interval);
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))
            return;
        setTopUsers([]);
        var id = dailyDocId;
        var isMounted = true;
        var unsubscribe = __1.db
            .collection(groupId ? "groups/".concat(groupId, "/leaderboards") : 'leaderboards')
            .doc(id)
            .collection('users')
            .orderBy('minutesFlowed', 'desc')
            .withConverter((0, __1.converter)())
            .limit(10)
            .onSnapshot(function (snapshot) {
            if (!isMounted)
                return;
            setTopUsers(snapshot.docs
                .map(function (d) {
                return __assign(__assign({}, d.data()), { id: d.id });
            })
                .sort(function (a, b) {
                return b.minutesFlowed - a.minutesFlowed;
            }));
        }, function (error) {
            console.error('leaderboard subscription error: ', error);
        });
        return function () {
            isMounted = false;
            unsubscribe();
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.id, dailyDocId, groupId]);
    return (React.createElement(LeaderBoardContext.Provider, { value: { topUsers: topUsers } }, children));
};
exports.LeaderboardProvider = LeaderboardProvider;
function useLeaderboard() {
    var state = (0, react_1.useContext)(LeaderBoardContext);
    return state !== null && state !== void 0 ? state : exports.defaultEnvironment;
}
exports.useLeaderboard = useLeaderboard;
