"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findUpcomingEvent = void 0;
function findUpcomingEvent(scheduledCalendarEvents, now, upcomingTimeoutInMin) {
    return (scheduledCalendarEvents
        // Prioritize events coming soon
        .sort(function (a, b) { return a.start - b.start; })
        // Events that have already started should be put after
        .sort(function (a, b) { return (now >= a.start ? 1 : now >= b.start ? -1 : 0); })
        // Pick events that have not ended and are upcoming
        .find(function (event) {
        return event.end > now &&
            event.start < now + upcomingTimeoutInMin * 60 * 1000;
    }));
}
exports.findUpcomingEvent = findUpcomingEvent;
