"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSupportGroupPrice = void 0;
function formatSupportGroupPrice(pricePerMonth) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: pricePerMonth.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(pricePerMonth.amountInCents / 100);
}
exports.formatSupportGroupPrice = formatSupportGroupPrice;
