"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeRanksToMatchOrder = exports.getNewRankAt = exports.sortByRank = exports.sortByCompletedTime = exports.findRankWhenReorder = exports.findRankWhenAddingItem = exports.getTopRank = exports.getBottomRank = void 0;
var lexorank_1 = require("lexorank");
function getBottomRank(tasks) {
    var _a, _b;
    var sortedTasks = __spreadArray([], tasks, true).sort(sortByRank);
    var lastTaskRank = (_b = (_a = sortedTasks[sortedTasks.length - 1]) === null || _a === void 0 ? void 0 : _a.rank) !== null && _b !== void 0 ? _b : lexorank_1.LexoRank.middle().toString();
    return lexorank_1.LexoRank.parse(lastTaskRank).genNext().toString();
}
exports.getBottomRank = getBottomRank;
function getTopRank(tasks) {
    var _a, _b;
    var sortedTasks = __spreadArray([], tasks, true).sort(sortByRank);
    var firstTaskRank = (_b = (_a = sortedTasks[0]) === null || _a === void 0 ? void 0 : _a.rank) !== null && _b !== void 0 ? _b : lexorank_1.LexoRank.middle().toString();
    return lexorank_1.LexoRank.parse(firstTaskRank).genPrev().toString();
}
exports.getTopRank = getTopRank;
function findRankWhenAddingItem(destinationIndex, currentRanks) {
    if (currentRanks.length === 0) {
        return lexorank_1.LexoRank.middle().toString();
    }
    if (destinationIndex === 0) {
        var rank = lexorank_1.LexoRank.parse(currentRanks[0]);
        return rank.genPrev().toString();
    }
    if (destinationIndex >= currentRanks.length) {
        var rank = lexorank_1.LexoRank.parse(currentRanks[currentRanks.length - 1]);
        return rank.genNext().toString();
    }
    var rankBefore = lexorank_1.LexoRank.parse(currentRanks[destinationIndex - 1]);
    var rankAfter = lexorank_1.LexoRank.parse(currentRanks[destinationIndex]);
    if (rankBefore.compareTo(rankAfter) === 0) {
        return rankBefore.toString();
    }
    return rankAfter.between(rankBefore).toString();
}
exports.findRankWhenAddingItem = findRankWhenAddingItem;
function findRankWhenReorder(startIndex, endIndex, currentRanks) {
    var toPosition = lexorank_1.LexoRank.parse(currentRanks[endIndex]);
    if (endIndex === 0 || endIndex === currentRanks.length - 1) {
        return startIndex > endIndex
            ? toPosition.genPrev().toString()
            : toPosition.genNext().toString();
    }
    var boundedRank = startIndex > endIndex
        ? currentRanks[endIndex - 1]
        : currentRanks[endIndex + 1];
    var boundedPosition = lexorank_1.LexoRank.parse(boundedRank);
    return toPosition.between(boundedPosition).toString();
}
exports.findRankWhenReorder = findRankWhenReorder;
function sortByCompletedTime(taskA, taskB) {
    var aCompletedAt = taskA === null || taskA === void 0 ? void 0 : taskA.completedAt;
    var zCompletedAt = taskB === null || taskB === void 0 ? void 0 : taskB.completedAt;
    if (!aCompletedAt && !zCompletedAt)
        return 0;
    if (!aCompletedAt)
        return 1;
    if (!zCompletedAt)
        return -1;
    return zCompletedAt.getTime() - aCompletedAt.getTime();
}
exports.sortByCompletedTime = sortByCompletedTime;
function sortByRank(taskA, taskB) {
    var _a, _b;
    var aRank = (_a = taskA === null || taskA === void 0 ? void 0 : taskA.rank) !== null && _a !== void 0 ? _a : '';
    var zRank = (_b = taskB === null || taskB === void 0 ? void 0 : taskB.rank) !== null && _b !== void 0 ? _b : '';
    return aRank.localeCompare(zRank);
}
exports.sortByRank = sortByRank;
function getNewRankAt(index, ranks) {
    if (ranks.length === 0) {
        return lexorank_1.LexoRank.middle();
    }
    if (index === 0) {
        return ranks[0].genPrev();
    }
    if (index < ranks.length) {
        return ranks[index - 1].between(ranks[index]);
    }
    return ranks[ranks.length - 1].genNext();
}
exports.getNewRankAt = getNewRankAt;
function computeRanksToMatchOrder(items) {
    var ranks = items
        .map(function (item) { return item.rank; })
        .filter(function (rank) { return rank !== undefined; });
    var hasMissingRanks = ranks.length !== items.length;
    if (!hasMissingRanks && areSortedInOrder(ranks)) {
        return items;
    }
    // Recompute all ranks to preserve the given order
    var result = [];
    // Start from the middle so we can add ranks before and after
    var nextRank = lexorank_1.LexoRank.middle();
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        result.push(__assign(__assign({}, item), { rank: nextRank }));
        nextRank = nextRank.genNext();
    }
    return result;
}
exports.computeRanksToMatchOrder = computeRanksToMatchOrder;
function areSortedInOrder(ranks) {
    var sortedRanks = __spreadArray([], ranks, true).sort(function (a, b) { return a.compareTo(b); });
    return sortedRanks.every(function (rank, index) { return rank.toString() === ranks[index].toString(); });
}
