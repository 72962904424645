"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToClosestEven = void 0;
function roundToClosestEven(value) {
    if (isEven(value))
        return value;
    var roundedValue = Math.round(value);
    if (isEven(roundedValue))
        return roundedValue;
    return roundedValue + 1;
}
exports.roundToClosestEven = roundToClosestEven;
function isEven(value) {
    return value % 2 === 0;
}
