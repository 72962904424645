"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchFeedTimeline = void 0;
var __1 = require("..");
var config_1 = require("../config");
var react_1 = require("react");
var manageFeed_1 = require("../helpers/manageFeed");
var useFetchFriends_1 = require("./useFetchFriends");
var useUser_1 = require("./useUser");
function useFetchFeedTimeline(postsToShow, userId, groupId) {
    if (postsToShow === void 0) { postsToShow = 'ALL'; }
    var user = (0, useUser_1.useUser)(userId).user;
    var _a = (0, useFetchFriends_1.default)(userId), followees = _a.followees, friendListLoading = _a.loading;
    var _b = (0, react_1.useState)([]), feedItems = _b[0], setFeedItems = _b[1];
    var _c = (0, react_1.useState)(true), feedLoading = _c[0], setFeedLoading = _c[1];
    var feedTimelineQuery = (0, react_1.useRef)(null);
    var lastPostRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (!userId && !groupId)
            return;
        var isMounted = true;
        setFeedLoading(true);
        var collectionPath = groupId
            ? "/groups/".concat(groupId, "/feed-timeline")
            : "/users/".concat(userId, "/feed-timeline");
        feedTimelineQuery.current = config_1.db
            .collection(collectionPath)
            .orderBy('createdAt', 'desc')
            .withConverter((0, __1.converter)())
            .limit(10);
        if (postsToShow === 'ONLY_USER_ID' && userId) {
            feedTimelineQuery.current = feedTimelineQuery.current.where('userId', '==', userId);
        }
        feedTimelineQuery.current
            .get()
            .then(function (snapshot) {
            if (isMounted) {
                lastPostRef.current = snapshot.docs[snapshot.docs.length - 1];
                setFeedItems(snapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); }));
                setFeedLoading(false);
            }
        })
            .catch(function (err) {
            console.error('Error fetching feed-timeline for user', err);
        });
        return function () {
            isMounted = false;
        };
    }, [groupId, userId, postsToShow]);
    var _d = (0, react_1.useState)(false), allPostsLoaded = _d[0], setAllPostsLoaded = _d[1];
    function loadMorePosts() {
        var _a;
        if (!lastPostRef.current)
            return;
        (_a = feedTimelineQuery.current) === null || _a === void 0 ? void 0 : _a.startAfter(lastPostRef.current).get().then(function (snapshot) {
            var lastPostCursor = snapshot.docs[snapshot.docs.length - 1];
            if (!lastPostCursor) {
                setAllPostsLoaded(true);
            }
            else {
                lastPostRef.current = lastPostCursor;
                setFeedItems(function (curr) { return __spreadArray(__spreadArray([], curr, true), snapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); }), true); });
            }
        }).catch(function (e) { return console.error(e); });
    }
    var currentUser = (0, __1.useCurrentUser)().currentUser;
    var processedFeedItems = (0, react_1.useMemo)(function () {
        var mapUserIdToProfile = {};
        for (var _i = 0, followees_1 = followees; _i < followees_1.length; _i++) {
            var person = followees_1[_i];
            mapUserIdToProfile[person.id] = {
                id: person.id,
                name: person.name,
                avatarUrl: person.avatarUrl,
            };
        }
        if (user) {
            mapUserIdToProfile[user.id] = {
                id: user.id,
                name: user.name,
                avatarUrl: user.avatarUrl,
            };
        }
        var feedItemsWithOwnerInfo = feedItems
            .map(function (item) {
            var updateWithUserInfo = __assign(__assign({}, item), { owner: mapUserIdToProfile[item.userId] });
            return updateWithUserInfo;
        })
            .filter(function (item) {
            if (postsToShow === 'ONLY_FRIENDS') {
                return item.userId !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
            }
            return true;
        });
        return feedItemsWithOwnerInfo;
    }, [
        feedItems,
        followees,
        user === null || user === void 0 ? void 0 : user.id,
        user === null || user === void 0 ? void 0 : user.name,
        user === null || user === void 0 ? void 0 : user.avatarUrl,
        currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
        postsToShow,
    ]);
    var deletePost = (0, react_1.useCallback)(function (postId) {
        var postIdx = feedItems.findIndex(function (item) { return item.id === postId; });
        var updatedFeedItems = Array.from(feedItems);
        if (postIdx > -1 && feedItems[postIdx].userId === (user === null || user === void 0 ? void 0 : user.id)) {
            updatedFeedItems.splice(postIdx, 1);
            setFeedItems(updatedFeedItems);
            (0, manageFeed_1.deleteFeedUpdate)(postId);
        }
    }, [user === null || user === void 0 ? void 0 : user.id, feedItems]);
    return {
        loading: feedLoading || (userId !== undefined && friendListLoading),
        feedItems: processedFeedItems,
        deletePost: deletePost,
        loadMorePosts: loadMorePosts,
        allPostsLoaded: allPostsLoaded,
    };
}
exports.useFetchFeedTimeline = useFetchFeedTimeline;
