"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortAlphabeticallyBy = void 0;
function sortAlphabeticallyBy(list, key) {
    var newList = __spreadArray([], list, true);
    newList.sort(function (a, b) {
        if (a[key].toLowerCase() < b[key].toLowerCase())
            return -1;
        if (a[key].toLowerCase() > b[key].toLowerCase())
            return 1;
        return 0;
    });
    return newList;
}
exports.sortAlphabeticallyBy = sortAlphabeticallyBy;
