"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchContentVideos = void 0;
var react_1 = require("react");
var __1 = require("..");
function useFetchContentVideos() {
    var _a = (0, react_1.useState)([]), videos = _a[0], setVideos = _a[1];
    var _b = (0, react_1.useState)(true), loading = _b[0], setLoading = _b[1];
    (0, react_1.useEffect)(function () {
        __1.db.collection('content-videos')
            .withConverter((0, __1.converter)())
            .get()
            .then(function (snapshot) {
            setLoading(false);
            setVideos(snapshot.docs
                .map(function (doc) { return doc.data(); })
                .sort(function (a, b) {
                var _a, _b;
                return ((_a = a.order) !== null && _a !== void 0 ? _a : Number.POSITIVE_INFINITY) -
                    ((_b = b.order) !== null && _b !== void 0 ? _b : Number.POSITIVE_INFINITY);
            })
                .map(function (video, idx) { return (__assign(__assign({}, video), { isPremium: idx > 1 })); }));
        });
    }, []);
    return {
        loading: loading,
        videos: videos,
    };
}
exports.useFetchContentVideos = useFetchContentVideos;
