"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appCategoryBundleIds = void 0;
exports.appCategoryBundleIds = {
    coding: [
        'com.microsoft.VSCode',
        'com.googlecode.iterm2',
        'com.apple.Terminal',
        'https://stackoverflow.com',
        'https://github.com',
    ],
    designing: ['com.figma.Desktop', 'https://www.figma.com'],
    writing: [
        'com.apple.TextEdit',
        'https://www.notion.so',
        'com.microsoft.Word',
        'https://docs.google.com',
        'notion.id',
        'com.lukilabs.lukiapp',
    ],
    email: [
        'com.apple.mail',
        'com.superhuman.electron',
        'https://mail.google.com',
        'https://outlook.live.com',
        'https://outlook.office.com',
        'https://superhuman.com',
        'https://mail.yahoo.com',
        'https://mail.aol.com',
        'https://mail.protonmail.com',
    ],
    studying: [
        'https://www.pearson.com',
        'https://www.khanacademy.org',
        'https://www.coursera.org',
        'https://www.udemy.com',
        'https://www.udacity.com',
        'https://www.edx.org',
        'https://www.lynda.com',
        'https://www.skillshare.com',
    ],
};
