"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.firebaseConfig = exports.vapidKey = exports.braindumpStorage = exports.coachLinesStorage = exports.chatImagesStorage = exports.groupVideoStorage = exports.groupStorage = exports.appIconStorage = exports.avatarStorage = exports.converter = exports.auth = exports.db = exports.firebase = exports.FIREBASE_CLOUD_FUNCTIONS_URL = exports.isProduction = exports.FIREBASE_ENV = void 0;
require("@firebase/app");
require("@firebase/auth");
require("@firebase/database");
require("@firebase/firestore");
require("@firebase/functions");
require("@firebase/messaging");
require("@firebase/storage");
var app_1 = require("firebase/app");
exports.firebase = app_1.default;
exports.FIREBASE_ENV = process.env.NEXT_PUBLIC_FIREBASE_ENV || process.env.FIREBASE_ENV || 'staging';
var isProduction = function () { return exports.FIREBASE_ENV === 'production'; };
exports.isProduction = isProduction;
exports.FIREBASE_CLOUD_FUNCTIONS_URL = (0, exports.isProduction)()
    ? 'https://us-central1-centered-1580668301240.cloudfunctions.net'
    : 'https://us-central1-centered-staging.cloudfunctions.net';
var firebaseConfig = (0, exports.isProduction)()
    ? {
        apiKey: 'AIzaSyBe-MgboBH3WOU2sGOUxBVgLWC2XnKW3CM',
        authDomain: 'centered-1580668301240.firebaseapp.com',
        databaseURL: 'https://centered-1580668301240-default-rtdb.firebaseio.com',
        projectId: 'centered-1580668301240',
        storageBucket: 'centered-1580668301240.appspot.com',
        messagingSenderId: '773885676540',
        appId: '1:773885676540:web:8537430891ccfd6fa6c079',
        measurementId: 'G-NMKEJ3F1HE',
    }
    : {
        apiKey: 'AIzaSyBT_X1sQJZKgEs61NjeXWy1NskA1ZmJ6-4',
        authDomain: 'centered-staging.firebaseapp.com',
        databaseURL: 'https://centered-staging-default-rtdb.firebaseio.com',
        projectId: 'centered-staging',
        storageBucket: 'centered-staging.appspot.com',
        messagingSenderId: '254388605337',
        appId: '1:254388605337:web:49f4ec52ed4dd386a60308',
    };
exports.firebaseConfig = firebaseConfig;
var vapidKey = (0, exports.isProduction)()
    ? 'BMRgIsJFeAigZDoKrzICmP0Q7j_zWdiK_VMTiUf2-xJvAg2Pz190fxjJ-t9GCV03YadDL780sYRGstWSRG0AACM'
    : 'BLTZJfar68REJ2X35GmuyRPTqvDNLRgamvRRoXMYH4gDZwKxfMkyZlAvTVcTfkhqdU2dgliyi8idWYjh-NAnbJk';
exports.vapidKey = vapidKey;
var isInitializeCalled = false;
if (!app_1.default.apps.length) {
    app_1.default.initializeApp(firebaseConfig);
    isInitializeCalled = true;
}
var db = app_1.default.firestore();
exports.db = db;
var auth = app_1.default.auth();
exports.auth = auth;
var avatarStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)()
    ? 'gs://centered-user-profile-pictures'
    : 'gs://centered-staging-user-profile-pictures');
exports.avatarStorage = avatarStorage;
var appIconStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)()
    ? 'gs://centered-app-icons'
    : 'gs://centered-staging-app-icons');
exports.appIconStorage = appIconStorage;
var groupStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)() ? 'gs://centered-groups' : 'gs://centered-staging-groups');
exports.groupStorage = groupStorage;
var groupVideoStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)()
    ? 'gs://centered-group-videos'
    : 'gs://centered-staging-group-videos');
exports.groupVideoStorage = groupVideoStorage;
var chatImagesStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)()
    ? 'gs://centered-chat-images'
    : 'gs://centered-staging-chat-images');
exports.chatImagesStorage = chatImagesStorage;
var coachLinesStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)()
    ? 'gs://centered-coach-lines'
    : 'gs://centered-staging-coach-lines');
exports.coachLinesStorage = coachLinesStorage;
var braindumpStorage = app_1.default
    .app()
    .storage((0, exports.isProduction)()
    ? 'gs://centered-braindump'
    : 'gs://centered-staging-braindump');
exports.braindumpStorage = braindumpStorage;
// Adds nice typescript support for interactions with firebase.
// Usage: `db.collections('x').withConverter(converter<TYPE>())`
// You can then call: .where, .get, etc like normal and get correct types returned
var converter = function () { return ({
    toFirestore: function (data) { return data; },
    fromFirestore: function (snap) {
        return snap.data();
    },
}); };
exports.converter = converter;
if (isInitializeCalled) {
    var inChromeExtension = 
    // @ts-ignore
    typeof window !== 'undefined' && Boolean((_b = (_a = window === null || window === void 0 ? void 0 : window.chrome) === null || _a === void 0 ? void 0 : _a.runtime) === null || _b === void 0 ? void 0 : _b.id);
    if (process.env.NODE_ENV !== 'production') {
        console.log('[Firebase init] inChromeExtension:', inChromeExtension);
    }
    db.settings({
        ignoreUndefinedProperties: true,
        merge: true,
        cacheSizeBytes: app_1.default.firestore.CACHE_SIZE_UNLIMITED,
        experimentalAutoDetectLongPolling: inChromeExtension,
    });
}
